import classes from './Button.module.scss'
import { useContext, useEffect, useState } from 'react'
import { BucketContext } from '../../../../App'
import SidePopup from '../../modalsUtility/SidePopup'
import BitmanItemImage from '../../../../accests/image/bitmanImage.png'
import { imageUrl } from '../../../config/config'

const Button = ({ itemId = 1, name, photo, children }) => {
   const context = useContext(BucketContext)
   const [popup, setPopup] = useState(false)

   function addingItemToLS() {
      context.dispatch({ type: 'adding_item', payload: itemId })
      setPopup(true)
   }
   useEffect(() => {
      return () => setTimeout(() => setPopup(false), 2100)
   }, [popup])
   return (
      <div className={classes.wrapper} onClick={() => addingItemToLS()}>
         {children}
         {popup ? (
            <SidePopup dataState={true}>
               <span>
                  <img
                     src={!!photo?.length ? `${imageUrl}${photo[0]}` : BitmanItemImage}
                     alt={name}
                  />
               </span>
               <span></span>
               <span>
                  <p>{name}</p> <p>Добавлен в корзину.</p>
               </span>
            </SidePopup>
         ) : null}
      </div>
   )
}

export default Button
