import classes from './BucketController.module.scss'
import { useContext } from 'react'
import { BucketContext } from '../../../App'

const BucketController = ({ state, selectingAll, selectedItems }) => {
   const context = useContext(BucketContext)
   return (
      <div className={classes.cartPageController}>
         <label className={classes.selectAll}>
            <input type='checkbox' checked={state} onClick={() => selectingAll()} />
            <span>Выбрать все</span>
         </label>
         <span
            onClick={() =>
               context.dispatch({ type: 'pop_selected_items', payload: [selectedItems] })
            }
         >
            Удалить выбранные
         </span>
      </div>
   )
}

export default BucketController
