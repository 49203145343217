import classes from './CompareItem.module.scss'
import BitmanItemImage from '../../../../accests/image/bitmanImage.png'
import Button from '../../../common/catalogWrapper/Button/Button'
import ShopIcon from '../../../../accests/image/shoppMenuIcon.svg'
import Cross from '../../../../accests/image/crossIcon.svg'
import { addRemoveCompareById, toKebabCase } from '../../../common/comFn'
import { imageUrl } from '../../../config/config'

const CompareItem = ({
   name,
   price,
   total_price,
   id,
   discount,
   hashrate,
   brand,
   coins,
   consumption,
   algorithm,
   photo_urls,
   differentAttributes,
   activeSwitcher,
   title,
}) => {
   const showAllAttributes = !activeSwitcher
   return (
      <div className={classes.wrapper}>
         <div className={classes.imgage}>
            <a href={`/asic-miners/${toKebabCase(title)}`}>
               <img
                  src={!!photo_urls?.length ? `${imageUrl}${photo_urls[0]}` : BitmanItemImage}
                  alt=''
               />
            </a>
         </div>
         <div className={classes.name}>{title}</div>
         <div className={classes.pricesWrapper}>
            <div className={classes.price}>{total_price} ₽</div>
            {!!discount && (
               <>
                  <div className={classes.oldPrice}>{price}</div>
                  <div className={classes.salleryPrice}>{discount} ₽</div>
               </>
            )}
         </div>
         <div className={classes.activWrapper}>
            <Button itemId={id} photo={photo_urls} name={name}>
               <img src={ShopIcon} alt='shopIcon' />В корзину
            </Button>
            <div className={classes.cross} onClick={() => addRemoveCompareById(id)}>
               <img src={Cross} alt='cross' />
            </div>
         </div>
         <div className={classes.hashRateWrapper}>
            <div className={classes.hashItem}>
               <div className={classes.tableItem}>
                  {(showAllAttributes || differentAttributes?.hashrate) && (
                     <div>
                        <p>Хэшрейт</p>
                        <span>{hashrate} TH/s</span>
                     </div>
                  )}
                  {(showAllAttributes || differentAttributes?.total_price) && (
                     <div>
                        <p>Цена</p>
                        <span>{total_price} ₽</span>
                     </div>
                  )}
                  {(showAllAttributes || differentAttributes?.brand) && (
                     <div>
                        <p>Бренд</p>
                        <span>{brand?.value}</span>
                     </div>
                  )}
                  {(showAllAttributes || differentAttributes?.coins) && (
                     <div>
                        <p>Монета</p>
                        <span>{coins?.map((item) => item?.value).join('/')}</span>
                     </div>
                  )}
                  {(showAllAttributes || differentAttributes?.consumption) && (
                     <div>
                        <p>Потребление</p>
                        <span>{consumption} ВТ/ч</span>
                     </div>
                  )}
                  {(showAllAttributes || differentAttributes?.algorithm) && (
                     <div>
                        <p>Алгоритм</p>
                        <span>{algorithm?.value}</span>
                     </div>
                  )}
               </div>
            </div>
         </div>
      </div>
   )
}

export default CompareItem
