import Faq from '../../common/faq/Faq'
import CatalogWatchEarly from '../../common/catalogWatchEarly/CatalogWatchEarly'

import { useGetAsics } from '../../hooks/useGetAsics'


const FaqPage = () => {
   const { data: asicsData} = useGetAsics()
   return (
      <>
         <Faq />
         <CatalogWatchEarly name={'Смотрели ранее'} link='none' items={asicsData} />
      </>
   )
}

export default FaqPage
