import React, { useEffect } from 'react'
import classes from './News.module.scss'
import NewsItem from './newsItem/NewsItem'
import Crums from '../breadCrums/Crums'
import axios from 'axios'
import { baseUrl } from '../../config/config'
import { useState, useRef } from 'react'
import Pagination from './pagination/Pagination'

const News = ({ data }) => {
   const crumsData = [['news', 'Новости']]
   const [postData, setPostData] = useState(data)
   const [newsPerPage, setNewsPerPage] = useState(9)
   const [pageAmount, setPageAmount] = useState(1)
   const [activePage, setActivePage] = useState(1)
   const [activeNews, setActiveNews] = useState('all')

   const elementWidth = useRef(null)

   const pageScrolling = (value) => {
      if (value === false) {
         setActivePage(activePage - 1 <= 0 ? 1 : activePage - 1)
      } else {
         setActivePage(activePage + 1 <= pageAmount ? activePage + 1 : pageAmount)
      }
      window.scroll(0, document.getElementById('newsPage'))
   }

   const setPage = (value) => {
      setActivePage(value)
      setTimeout(() => {
         window.scroll(0, document.getElementsByTagName('body'))
      }, 300)
   }

   function itemsToShow() {
      let processArray = []
      processArray = postData.slice(
         activePage * newsPerPage - newsPerPage,
         activePage * newsPerPage,
      )
      return processArray
   }
   function allIn() {
      setPostData(data)
      setActiveNews('all')
      setActivePage(1)
   }
   function selectingNews() {
      switch (activeNews) {
         case 'new':
            return `${baseUrl}/post/?tag=new&limit=60&offset=0`
         case 'article':
            return `${baseUrl}/post/?tag=article&limit=60&offset=0`
         default:
            return `${baseUrl}/post/?limit=60&offset=0`
      }
   }
   function sortingNews() {
      setPostData(data.filter((element) => element.tag === 'new'))
      setActiveNews('new')
      setActivePage(1)
   }
   function sortingArticle() {
      setPostData(data.filter((element) => element.tag === 'article'))
      setActiveNews('article')
      setActivePage(1)
   }
   useEffect(() => {
      if (elementWidth.current.clientWidth <= 650) {
         setNewsPerPage(3)
         setPageAmount(Math.ceil(postData.length / 3))
      } else {
         setNewsPerPage(9)
         setPageAmount(Math.ceil(postData.length / 9))
      }
      axios
         .get(selectingNews())
         .then((response) => {
            const data = response.data
            setPostData(data)
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error)
         })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [activePage, activeNews])

   return (
      <div className={classes.news + ' container'} id='newsPage' ref={elementWidth}>
         <Crums data={crumsData} />
         <div className={classes.newsHeaderPage}>
            <h3>Новости</h3>
            <div className={classes.newsHeaderPageTabs}>
               <span
                  className={activeNews === 'all' ? classes.newsHeaderPageActive : null}
                  onClick={() => allIn()}
               >
                  <span>Все</span>
                  <span>{data.length}</span>
               </span>
               <span
                  className={activeNews === 'new' ? classes.newsHeaderPageActive : null}
                  onClick={() => sortingNews()}
               >
                  <span>Новости</span>
                  <span>{data.filter((element) => element.tag === 'new').length}</span>
               </span>
               <span
                  className={activeNews === 'article' ? classes.newsHeaderPageActive : null}
                  onClick={() => sortingArticle()}
               >
                  <span>Статьи</span>
                  <span>{data.filter((element) => element.tag === 'article').length}</span>
               </span>
            </div>
         </div>
         <div className={classes.newsContentPage}>
            {itemsToShow().map((element, index) => {
               return <NewsItem key={index} data={element} />
            })}
         </div>
         {pageAmount > 1 && (
            <Pagination
               pageNumber={pageAmount}
               elementsPerPage={newsPerPage}
               activePage={activePage}
               setPage={setPage}
               pageScrolling={pageScrolling}
               dataLength={postData.length}
            />
         )}
      </div>
   )
}

export default News
