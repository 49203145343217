import classes from './Switcher.module.css'

const Switcher = ({ name, active, setActive }) => {
   return (
      <div className={classes.switcherWrapper}>
         <div className={classes.switcherName}>{name}</div>
         <div
            className={`${classes.wrapper} ${active && classes.activeWrapper}`}
            onClick={() => setActive(!active)}
         >
            <div className={`${classes.checkBox} ${active && classes.activeBox}`} />
         </div>
      </div>
   )
}

export default Switcher
