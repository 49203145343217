import classes from '../UserDataForm.module.scss'

const usersData = {
   commercial: {
      commercialNames: [
         ['Название компании', 'company_name'],
         ['Адрес', 'legal_address'],
      ],
      commercialData: [
         ['ИНН', 'inn'],
         ['КПП', 'kpp'],
      ],
      commercialContacts: [
         ['Контактное лицо', 'contact_name'],
         ['Телефон', 'phone'],
         ['E-mail', 'email'],
      ],
   },
}

function CommercialUser({ input }) {
   return (
      <>
         <div className={classes.usersName}>
            <div className={classes.inputWrapper}>
               <p>Название компании</p>
               <input
                  type='text'
                  name='company_name'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
            <div className={classes.inputWrapper}>
               <p>Адрес</p>
               <input
                  type='text'
                  name='legal_address'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
         </div>
         <div className={classes.usersComercialData}>
            <div className={classes.inputWrapper}>
               <p>ИНН</p>
               <input
                  type='text'
                  name='inn'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
            <div className={classes.inputWrapper}>
               <p>КПП</p>
               <input
                  type='text'
                  name='kpp'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
         </div>
         <div className={classes.usersContacts}>
            <div className={classes.inputWrapper}>
               <p>Контактное лицо</p>
               <input
                  type='text'
                  name='contact_name'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
            <div className={classes.inputWrapper}>
               <p>Телефон</p>
               <input
                  type='text'
                  name='phone'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
            <div className={classes.inputWrapper}>
               <p>E-mail</p>
               <input
                  type='text'
                  name='email'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
            </div>
         </div>
      </>
   )
}

export default CommercialUser
