let initialProfileStore = {
   activePopup: false,
   method: 'mail',
   mainWindow: true,
   callbackState: 'regular',
   name: '',
   email: null,
   favorite: [],
   requestRerender: false
}
export default initialProfileStore
