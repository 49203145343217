import classes from '../UserDataForm.module.scss'
import { useRef, useEffect } from 'react'

function RegularUser({ input, userData }) {
   const inputPhone = useRef()

   function formatingPhone(value) {
      switch (value.length) {
         case 1:
            return `${value.substring(0, 1).replace(/(\d{1})/, '+7')}`
         case 5:
            return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(2, 5)}) `
         case 12:
            return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(
               4,
               7,
            )}) ${value.substring(9, 12)}-`
         case 15:
            return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(
               4,
               7,
            )}) ${value.substring(9, 12)}-${value.substring(13, 15)}-`
         case 19:
            return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(
               4,
               7,
            )}) ${value.substring(9, 12)}-${value.substring(13, 15)}-${value.substring(16, 18)}`
         default:
            return value
      }
   }
   useEffect(() => {
      inputPhone.current.addEventListener('keydown', (event) => {
         if (event.keyCode === 8 || event.keyCode === 46) {
            input('phone_number', '')
         }
      })
   }, [userData])
   return (
      <>
         <div className={classes.usersName}>
            <div
               className={
                  userData.first_name !== 'error' ? classes.inputWrapper : classes.inputWrapperError
               }
            >
               <p>Имя</p>
               <input
                  value={userData.first_name}
                  type='text'
                  name='first_name'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
               <p className={classes.inputWrapperWarning}>Обязательно для заполнения</p>
            </div>
            <div
               className={
                  userData.last_name !== 'error' ? classes.inputWrapper : classes.inputWrapperError
               }
            >
               <p>Фамилия</p>
               <input
                  value={userData.last_name}
                  type='text'
                  name='last_name'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
               <p className={classes.inputWrapperWarning}>Обязательно для заполнения</p>
            </div>
         </div>
         <div className={classes.usersContacts}>
            <div
               className={
                  userData.phone_number !== 'error'
                     ? classes.inputWrapper
                     : classes.inputWrapperError
               }
            >
               <p>Номер телефона</p>
               <input
                  ref={inputPhone}
                  type='text'
                  name='phone_number'
                  value={userData.phone_number === 'error' ? null : userData.phone_number}
                  onChange={(e) => {
                     input(
                        e.target.name,
                        formatingPhone(e.target.value.replace(/([a-z]|[A-Z])/, '')),
                     )
                  }}
               />
               <p className={classes.inputWrapperWarning}>Обязательно для заполнения</p>
            </div>
            <div
               className={
                  userData.email !== 'error' ? classes.inputWrapper : classes.inputWrapperError
               }
            >
               <p>E-mail</p>
               {userData.email !== null ? (
                  <input
                     type='text'
                     value={userData.email === 'error' ? '' : userData.email}
                     name='email'
                     onChange={(e) => input(e.target.name, e.target.value)}
                  />
               ) : (
                  <input
                     type='text'
                     name='email'
                     onChange={(e) => input(e.target.name, e.target.value)}
                  />
               )}

               <p className={classes.inputWrapperWarning}>Обязательно для заполнения</p>
            </div>
            <div className={classes.inputWrapper}>
               <p>Telegram</p>
               <input
                  type='text'
                  name='telegram'
                  onChange={(e) => input(e.target.name, e.target.value)}
               />
               <p className={classes.inputWrapperWarning}>Обязательно для заполнения</p>
            </div>
         </div>
      </>
   )
}

export default RegularUser
