import classes from './AuthPopup.module.scss'
import { useState, useEffect, useContext, useRef } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import axios from 'axios'
// import { phoneFormating } from '../comFn'
import { baseUrl } from '../../config/config'
// import requared from '../axiosIntercepter'

import { ProfileContext } from '../../../App'

import imgCross from '../../../accests/image/crossIcon.svg'

const Phone = ({ input }) => {
   return (
      <div className={classes.PopupPhone}>
         <label>
            <p>Номер телефона</p>
            <span>
               <input
                  type='text'
                  placeholder='+7 000 000 00 00'
                  name='phone'
                  maxLength={10}
                  onChange={(e) => {
                     input(e.target.name, e.target.value)
                  }}
               />
            </span>
         </label>
      </div>
   )
}
const Email = ({ input }) => {
   return (
      <div className={classes.PopupMail}>
         <label>
            <p>E-mail</p>
            <span>
               <input
                  type='text'
                  // value={context.profileStore.email !== null ? context.profileStore.email : null}
                  placeholder='Enter e-mail'
                  name='email'
                  onChange={(e) => {
                     input(e.target.name, e.target.value)
                  }}
               />
            </span>
         </label>
         {/* <label>
            <p>Password</p>
            <span>
               <input
                  type='password'
                  placeholder='Enter password'
                  name='password'
                  onChange={(e) => {
                     input(e.target.name, e.target.value)
                  }}
               />
            </span>
         </label> */}
      </div>
   )
}
const AuthMethod = () => {
   const location = useLocation()
   const navigate = useNavigate()
   const [userData, setUserData] = useState({ email: null })
   const context = useContext(ProfileContext)
   const [authStatus, setAuthStatus] = useState(true)

   function dataForming(valueName, value) {
      setUserData({ ...userData, [valueName]: value.match(/^\S+@\S+\.\S+$/) })
   }

   function authCodeEnter() {
      axios
         .post(baseUrl + '/auth/gen-otp-email', JSON.stringify({ email: userData.email[0] }), {
            headers: {
               'Content-Type': 'application/json',
               Accept: 'application/json',
            },
         })
         .then((response) => {
            if (response.status === 202) {
               context.storeDispatch({ type: 'email_set', payload: userData.email[0] })
               context.storeDispatch({ type: 'popup_mainWindow', payload: false })
            }
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error.statusText)
         })
   }
   // function login() {
   //    axios
   //       .post(baseUrl + '/api/v1/auth/login', JSON.stringify(userData), {
   //          headers: {
   //             'Content-Type': 'application/json',
   //          },
   //       })
   //       .then((response) => {
   //          const data = response.data
   //          localStorage.setItem('auth_token', data.access_token)
   //          context.storeDispatch({
   //             type: 'popup_close',
   //          })
   //          window.location.reload()
   //       })
   //       .catch((error) => {
   //          console.error('Произошла ошибка:', error)
   //          setAuthStatus(false)
   //          localStorage.setItem('access_token', ``)
   //       })
   // }
   function closingPopup() {
      context.storeDispatch({
         type: 'popup_close',
      })
      localStorage.setItem('authName', '')
      localStorage.setItem('auth_token', '')
      if (location.pathname === '/profile') {
         navigate('/')
      } else if (location.pathname === '/cart/ordering') {
         navigate('/cart')
      } else if (location.pathname === '/favorites') {
         navigate('/')
      }
   }

   function switcher() {
      switch (context.profileStore.method) {
         case 'phone':
            return <Phone input={dataForming} />
         case 'mail':
            return <Email input={dataForming} />
         default:
            return null
      }
   }
   return (
      <div
         className={
            context.profileStore.activePopup ? classes.authPopupBackground : classes.activePopup
         }
      >
         <div className={classes.authPopupMain}>
            <div className={classes.PopupHeader}>
               <h1>Вход или регистрация</h1>
               <span onClick={() => closingPopup()}>
                  <img src={imgCross} alt='closingcross' />
               </span>
            </div>
            <div className={classes.PopupController}>
               <button
                  className={context.profileStore.method === 'mail' ? classes.active : null}
                  onClick={() =>
                     context.storeDispatch({
                        type: 'popup_activeMethod',
                        payload: 'mail',
                     })
                  }
               >
                  E-mail
               </button>
               {/* <button
                  className={context.profileStore.method === 'phone' ? classes.active : null}
                  onClick={() =>
                     context.storeDispatch({
                        type: 'popup_activeMethod',
                        payload: 'mail',
                     })
                  }
               >
                  Телефон
               </button> */}
            </div>
            {switcher()}
            {/* <p>
               Вы еще не{' '}
               <span onClick={() => redirectRegistration()} className={classes.register}>
                  зарегистрированы ?
               </span>
            </p>
            {!authStatus ? (
               <p className={classes.error}> Не верный пользователь или пароль</p>
            ) : null} */}
            {userData !== null && userData.email !== null ? (
               <button className={classes.PopupCodeSend} onClick={() => authCodeEnter()}>
                  Отправить код
               </button>
            ) : (
               <button className={classes.PopupPendingBtn}>Введите почтовый адрес</button>
            )}
         </div>
      </div>
   )
}
const AuthAuthorisation = () => {
   const lastIndex = useRef()
   const context = useContext(ProfileContext)
   const [error, setError] = useState(false)
   const [timerOut, setTimerOut] = useState(32)
   const [code, setCode] = useState({})

   // function timeOut() {
   //    setTimeout(() => setTimerOut((prev) => prev - 1), 1000)
   // }
   // function reduceInterval() {
   //    if (timerOut === 0) {
   //       setError(false)
   //    } else {
   //       setTimerOut((prev) => prev - 1)
   //    }
   // }

   function formating(event) {
      const nextItem = document.querySelector(
         `input[name=field-${event.target.name.slice(6) * 1 + 1}]`,
      )
      if (event.target.value.length <= 1) {
         setCode({ ...code, [event.target.name]: event.target.value })
         if (nextItem !== null) {
            nextItem.focus()
         }
      }
   }
   function formatingCode() {
      let formattedArray = ''
      Object.values(code).map((element) => {
         formattedArray = formattedArray + element
      })
      return formattedArray
   }

   function sendRequest() {
      if (error === true) {

      } else {
         axios
            .post(
               baseUrl + '/auth/login-otp-email',
               JSON.stringify({ email: context.profileStore.email, password: formatingCode() }),
               {
                  headers: {
                     'Content-Type': 'application/json',
                  },
               },
            )
            .then((response) => {
               const data = response.data
               localStorage.setItem('auth_token', data.access_token)
               localStorage.setItem('authName', context.profileStore.email)
               context.storeDispatch({
                  type: 'popup_close',
               })
               window.location.reload()
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.statusText)
               setError(true)
               // timeOut()
            })
      }

   }
   useEffect(() => {
      if (timerOut === 0) {
         context.storeDispatch({
            type: 'popup_mainWindow',
            payload: true,
         })
      } else {
         setTimeout(() => setTimerOut((prev) => prev - 1), 1000)
      }
   }, [timerOut])

   useEffect(() => {
      lastIndex.current.addEventListener('keydown', (event) => {
         if (event.keyCode === 13) {
            sendRequest()
         }
      })
   }, [code])
   return (
      <div
         className={
            context.profileStore.activePopup ? classes.authPopupBackground : classes.activePopup
         }
      >
         <div className={classes.authPopupMain}>
            <div className={classes.PopupHeaderSend}>
               <h1>Ведите код </h1>
               <span
                  onClick={() =>
                     context.storeDispatch({
                        type: 'popup_close',
                     })
                  }
               >
                  <img src={imgCross} alt='closingcross' />
               </span>
               <p>
                  {context.profileStore.method === 'mail'
                     ? 'На указанную почту был отправлен код'
                     : 'На указанный телефон был отправлен код'}
               </p>
            </div>
            <div className={classes.PopupAuthCode}>
               <p>Код из {context.profileStore.method === 'mail' ? 'письма' : 'смс сообщения'} </p>
               <span>
                  <input
                     type='text'
                     maxLength='1'
                     name='field-0'
                     inputMode='numeric'
                     pattern='[0-9]*'
                     onChange={(e) => formating(e)}
                  />
                  <input
                     type='text'
                     maxLength='1'
                     name='field-1'
                     inputMode='numeric'
                     pattern='[0-9]*'
                     onChange={(e) => formating(e)}
                  />
                  <input
                     type='text'
                     maxLength='1'
                     name='field-2'
                     inputMode='numeric'
                     pattern='[0-9]*'
                     onChange={(e) => formating(e)}
                  />
                  <input
                     type='text'
                     maxLength='1'
                     name='field-3'
                     inputMode='numeric'
                     pattern='[0-9]*'
                     onChange={(e) => formating(e)}
                  />
                  <input
                     type='text'
                     maxLength='1'
                     name='field-4'
                     inputMode='numeric'
                     pattern='[0-9]*'
                     onChange={(e) => formating(e)}
                  />
                  <input
                     ref={lastIndex}
                     type='text'
                     maxLength='1'
                     name='field-5'
                     inputMode='numeric'
                     pattern='[0-9]*'
                     onChange={(e) => formating(e)}
                  />
               </span>
               {error ? (
                  <>
                     <p className={classes.PopupAuthCodeError}>Неверный код</p>
                     <p className={classes.PopupAuthCodeErrorTimer}>
                        <span>Запросить повторно</span>
                        <span></span>
                        <span>0:{timerOut.toString().length <= 1 ? '0' + timerOut : timerOut}</span>
                     </p>
                  </>
               ) : null}
            </div>
            <span className={classes.PopupAuthCodeErrorButtons}>
               <button
                  className={classes.PopupCodeBack}
                  onClick={() =>
                     context.storeDispatch({
                        type: 'popup_mainWindow',
                        payload: true,
                     })
                  }
               >
                  Назад
               </button>
               <button className={classes.PopupCodeSendAuth} onClick={() => sendRequest()}>
                  Войти
               </button>
            </span>
         </div>
      </div>
   )
}

const Auth = () => {
   const context = useContext(ProfileContext)
   return (
      <>
         {context.profileStore.mainWindow && context.profileStore.activePopup ? (
            <AuthMethod />
         ) : context.profileStore.mainWindow !== true && context.profileStore.activePopup ? (
            <AuthAuthorisation />
         ) : null}
      </>
   )
}

export default Auth
