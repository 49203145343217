import classes from './ActiveListMenu.module.scss'
import CrossIcon from '../../../../accests/image/crossIcon.svg'
import NextArrowIcon from '../../../../accests/image/beforeArrowNext.svg'
import InputSearchMenu from '../inputSearchMenu/InputSearchMenu'

const ActiveListMenu = ({ setActive, name, menuLink, searchInput, active }) => {
   return (
      <div
         className={!active ? classes.modalWrapper : classes.activeModalWrapper}
         // className={classes.activeModalWrapper}
         onClick={(e) => {
            setActive({ active: false, modal: '' })
         }}
      >
         <div className={classes.modalContent} onClick={(e) => e.stopPropagation()}>
            <div className={classes.title}>
               {name}
               <div
                  className={classes.closeImg}
                  onClick={(e) => {
                     setActive({ active: false, modal: '' })
                  }}
               >
                  <img src={CrossIcon} alt='' />
               </div>
            </div>
            {searchInput ? (
               <InputSearchMenu />
            ) : (
               <>
                  {menuLink.map((item) =>
                     item.name === 'Калькулятор доходности' ? (
                        // item?.url?.includes('tg') ? (
                        <a
                           href={item?.url}
                           className={`${classes.menuLink} ${classes.text}`}
                           key={item?.name}
                           target='_blank'
                           style={{ height: 'calc((100vh - 192px) / 11)' }}
                           rel='noopener noreferrer'
                        >
                           <p>{item.name}</p>
                           <img src={item.img ? item.img : NextArrowIcon} alt='close' />
                        </a>
                     ) : item?.url?.includes('tel') ? (
                        <>
                           {!!item?.name?.length ? (
                              <a
                                 href={item.url}
                                 className={`${classes.telMenu} ${classes.text}`}
                                 style={{ height: 'calc((100vh - 192px) / 11)' }}
                              >
                                 <div className={classes.svgPhone}>
                                    <svg
                                       width='18'
                                       height='18'
                                       viewBox='0 0 18 18'
                                       fill='none'
                                       xmlns='http://www.w3.org/2000/svg'
                                    >
                                       <path
                                          d='M5.98356 6.37779C6.56356 7.58581 7.35422 8.71801 8.35553 9.71933C9.35685 10.7206 10.4891 11.5113 11.6971 12.0913C11.801 12.1412 11.8529 12.1661 11.9187 12.1853C12.1523 12.2534 12.4392 12.2045 12.637 12.0628C12.6927 12.0229 12.7403 11.9753 12.8356 11.88C13.1269 11.5887 13.2726 11.443 13.4191 11.3478C13.9715 10.9886 14.6837 10.9886 15.2361 11.3478C15.3825 11.443 15.5282 11.5887 15.8196 11.88L15.9819 12.0424C16.4248 12.4853 16.6462 12.7067 16.7665 12.9446C17.0058 13.4175 17.0058 13.9761 16.7665 14.449C16.6462 14.6869 16.4248 14.9083 15.9819 15.3512L15.8506 15.4825C15.4092 15.9239 15.1886 16.1446 14.8885 16.3131C14.5556 16.5001 14.0385 16.6346 13.6567 16.6334C13.3126 16.6324 13.0774 16.5657 12.607 16.4322C10.0792 15.7147 7.69387 14.361 5.70388 12.371C3.7139 10.381 2.36017 7.99569 1.6427 5.46786C1.50919 4.99749 1.44244 4.7623 1.44141 4.41818C1.44028 4.03633 1.57475 3.51925 1.76176 3.18633C1.9303 2.88631 2.15098 2.66563 2.59233 2.22428L2.72369 2.09292C3.16656 1.65005 3.388 1.42861 3.62581 1.30833C4.09878 1.0691 4.65734 1.0691 5.1303 1.30832C5.36812 1.42861 5.58955 1.65005 6.03242 2.09291L6.19481 2.25531C6.48615 2.54665 6.63182 2.69231 6.72706 2.8388C7.08622 3.3912 7.08622 4.10336 6.72706 4.65576C6.63182 4.80225 6.48615 4.94791 6.19481 5.23925C6.09955 5.33451 6.05192 5.38214 6.01206 5.43782C5.87038 5.63568 5.82146 5.92256 5.88957 6.15619C5.90873 6.22193 5.93367 6.27389 5.98356 6.37779Z'
                                          stroke='#16181D'
                                          stroke-width='1.5'
                                          stroke-linecap='round'
                                          stroke-linejoin='round'
                                       />
                                    </svg>
                                 </div>
                                 {item.name}
                              </a>
                           ) : (
                              <></>
                           )}
                        </>
                     ) : item.name === 'whatsapp' ? (
                        <div className={classes.contactWrapper}>
                           <div className={classes.contact}>
                              <div className={classes.svgCont}>
                                 <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                 >
                                    <path
                                       fill-rule='evenodd'
                                       clip-rule='evenodd'
                                       d='M1.43611 14.6692L0 20L5.39391 18.5631C7.4923 19.6904 9.92822 20.0258 12.2547 19.5076C14.5811 18.9894 16.6421 17.6524 18.0593 15.742C19.4765 13.8316 20.155 11.476 19.9702 9.10725C19.7854 6.73854 18.7497 4.5157 17.0532 2.84661C15.3567 1.17753 13.1132 0.174168 10.7343 0.0206447C8.35543 -0.132879 6.0008 0.573734 4.10249 2.01082C2.20417 3.4479 0.889507 5.51906 0.399731 7.84424C-0.0900445 10.1694 0.277924 12.5926 1.43611 14.6692ZM13.1202 10.4753C13.3757 10.5302 13.6145 10.6394 13.8185 10.7946C13.8779 10.8276 13.9429 10.8617 14.0108 10.8973C14.4014 11.1021 14.8899 11.3582 14.9803 11.7515C15.0246 11.9773 14.9937 12.2105 14.8919 12.4191C14.7186 12.7644 14.458 13.0642 14.133 13.2922C13.8081 13.5201 13.4287 13.6693 13.0282 13.7264C12.3254 13.8017 11.6142 13.6959 10.97 13.4205C10.3498 13.2202 9.75614 12.9532 9.20068 12.6249C8.09376 11.9789 7.13035 11.1362 6.36392 10.1437C6.12809 9.84158 5.90957 9.52774 5.70929 9.20356C5.43169 8.78035 5.22108 8.32118 5.08414 7.84057C5.03021 7.64054 5.00248 7.43502 5.00157 7.22862C4.98804 6.86107 5.06208 6.49534 5.21824 6.15836C5.37441 5.82138 5.60871 5.52174 5.90391 5.28149C6.07394 5.13874 6.28414 5.04545 6.50913 5.01288C6.73412 4.9803 6.9643 5.00983 7.1719 5.09791C7.57439 5.4187 7.87113 5.84146 8.02705 6.31625C8.22874 6.65491 8.32291 7.04114 8.29834 7.42889C8.18769 7.78043 7.98485 8.09996 7.70858 8.35795C7.62966 8.42166 7.58002 8.51177 7.57012 8.60931C7.56022 8.70685 7.59083 8.80421 7.6555 8.88089C8.41374 10.1097 9.59012 11.0605 10.9936 11.579C11.0716 11.6194 11.1627 11.6315 11.2495 11.6131C11.3364 11.5946 11.4131 11.5469 11.4654 11.4789C11.543 11.39 11.6207 11.2731 11.7025 11.15C11.8817 10.8804 12.0809 10.5807 12.3441 10.4775C12.5992 10.4211 12.8647 10.4203 13.1202 10.4753Z'
                                       fill='#27D045'
                                    />
                                 </svg>
                              </div>
                              <div className={classes.contText}>WhatsApp</div>
                           </div>
                           <div className={classes.contact}>
                              <div className={classes.svgCont}>
                                 <svg
                                    width='20'
                                    height='20'
                                    viewBox='0 0 20 20'
                                    fill='none'
                                    xmlns='http://www.w3.org/2000/svg'
                                 >
                                    <path
                                       fill-rule='evenodd'
                                       clip-rule='evenodd'
                                       d='M20 10C20 15.5228 15.5228 20 10 20C4.47716 20 0 15.5228 0 10C0 4.47716 4.47716 0 10 0C15.5228 0 20 4.47716 20 10ZM10.9583 6.93166C9.2403 7.67372 4.28681 9.7811 4.28681 9.7811C3.11287 10.256 3.80002 10.7012 3.80002 10.7012C3.80002 10.7012 4.80217 11.0574 5.66119 11.3245C6.52016 11.5917 6.9783 11.2949 6.9783 11.2949C6.9783 11.2949 8.9826 9.92952 11.0155 8.4751C12.4472 7.46594 12.1036 8.29702 11.76 8.65322C11.0155 9.42494 9.78432 10.6419 8.75351 11.6214C8.29542 12.0369 8.52447 12.3931 8.7249 12.5712C9.30782 13.0825 10.6807 14.0124 11.3041 14.4346C11.4768 14.5516 11.592 14.6296 11.6168 14.6489C11.76 14.7677 12.5617 15.3019 13.0485 15.1832C13.5352 15.0645 13.5925 14.3818 13.5925 14.3818C13.5925 14.3818 13.9647 11.9479 14.3083 9.72174C14.372 9.28462 14.4356 8.85667 14.495 8.45763C14.6492 7.42057 14.7744 6.57875 14.7951 6.27866C14.881 5.2695 13.8502 5.68502 13.8502 5.68502C13.8502 5.68502 11.6168 6.63486 10.9583 6.93166Z'
                                       fill='#34AADF'
                                    />
                                 </svg>
                              </div>
                              <div className={classes.contText}>Telegram</div>
                           </div>
                        </div>
                     ) : (
                        <a
                           href={item.link}
                           className={classes.menuLink}
                           key={item.name}
                           style={{ height: 'calc((100vh - 192px) / 11)' }}
                        >
                           <p className={classes.text}>{item.name}</p>
                           <img src={item.img ? item.img : NextArrowIcon} alt='close' />
                        </a>
                     ),
                  )}
               </>
            )}
         </div>
      </div>
   )
}

export default ActiveListMenu
