import { useState, useEffect, useContext } from 'react'
import Crums from '../../common/breadCrums/Crums'
import CatalogItem from '../../common/catalogWrapper/CatalogItem/CatalogItem'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import classes from './FavoritePage.module.scss'
import { ProfileContext } from '../../../App'
import { useNavigate } from 'react-router-dom'

const FavoritePage = () => {
   const context = useContext(ProfileContext)
   const navigate = useNavigate()
   const [isLoading, setLoading] = useState(false)
   const activeFavoritesArr = localStorage.getItem('favoritesArr')?.split(',') ?? []

   useEffect(() => {
      if (localStorage.getItem('authName') === null || localStorage.getItem('authName') === "") {
         context.storeDispatch({
            type: 'popup_toggle',
            payload: true,
         })
      } else {
         setLoading(true)
      }
   }, [])

   //    <div>
   //    <div className={classes.title}>Избранное</div>
   //    <div className={classes.wrapperItem}>
   //       {userData?.length ? (
   //          <>
   //             {userData?.map((item) => (
   //                <CatalogItem {...item} />
   //             ))}
   //          </>
   //       ) : (
   //          <div className={classes.noContent}>
   //             <p>
   //                Выберете предметы на странице{' '}
   //                <span
   //                   onClick={() => {
   //                      navigate('/asic-miners')
   //                      window.location.reload()
   //                   }}
   //                >
   //                   Asic-майнеров
   //                </span>{' '}
   //                что бы добавить в избранное
   //             </p>
   //          </div>
   //       )}
   //    </div>
   // </div>

   return (
      <div className={'container'}>
         <Crums data={[['', 'Избранное']]} />
         <h1 className={classes.title}>Избранное :</h1>
         {context.profileStore.favorite.length === 0 ?
            (<div className={classes.noContent}>
               <p>
                  Выберете предметы на странице{' '}
                  <span
                     onClick={() => {
                        navigate('/asic-miners')
                        window.location.reload()
                     }}
                  >
                     Asic-майнеров
                  </span>{' '}
                  что бы добавить в избранное
               </p>
            </div>) : null
         }

         {isLoading === false ? <PageSpinner /> :
            (<div className={classes.wrapperItem}>
               {context.profileStore.favorite.map(element =>
                  <CatalogItem {...element} />
               )} </div>)
         }


      </div>
   )
}

export default FavoritePage
