import { useContext, useEffect } from 'react'
import classes from './FavoriteIcon.module.scss'
import Favorites from '../../../../accests/image/favorites.svg'
import { text } from '../../../../translate'
import { baseUrl } from '../../../config/config'
import requared from '../../axiosIntercepter'
import { ProfileContext } from '../../../../App'

function FavoriteIcon() {
   const context = useContext(ProfileContext)
   const token = localStorage.getItem('auth_token')
   const lang = 'ru'

   useEffect(() => {
      if (token !== null || token !== '') {
         requared
            .get(baseUrl + '/favorite')
            .then((response) => {
               context.storeDispatch({ type: 'init_favorite', payload: response.data })
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.status, error.statusText)
            })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [context.profileStore.requestRerender])
   return (
      <a href='/favorites' className={`${classes.comparePosition} ${classes.link}`}>
         <img className={classes.linkImg} src={Favorites} alt='favorites' />
         <p className={classes.linkText}>{text[lang].header.category.favorites}</p>
         {context.profileStore.favorite.length === 0 ? null : (
            <div className={classes.notificationCount}>{context.profileStore.favorite.length}</div>
         )}
      </a>
   )
}

export default FavoriteIcon
