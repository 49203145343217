import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config/config'

export const useGetAsicsArray = (array = []) => {
   const [incomeArray,serIncomeArray] = useState([...array])
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(true)
   const processedArray = JSON.stringify({ id__in: incomeArray })
   useEffect(() => {
      axios
         .get(`${baseUrl}/asics/?filter=${processedArray}`)
         .then((response) => {
            const data = response.data
            setData(data)
            setIsLoading(false)
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error.status, error.statusText)
            setIsLoading(false)
         })
   }, [])
   return { data, isLoading }
}
