import classes from './Pagination.module.scss'
import { useEffect } from 'react'

const Pagination = ({
   pageNumber,
   elementsPerPage,
   activePage,
   dataLength,
   setPage,
   pageScrolling,
   gap = 0,
   typeOfPage = 'news',
}) => {
   function scrollingUp(value) {
      setTimeout(() => {
         window.scroll(0, document.getElementsByTagName('body'))
      }, 300)
      setPage(value)
   }
   function pagination() {
      let array = []
      for (let x = 1; x <= pageNumber; x++) {
         if (x === activePage) {
            array.push(
               <span key={x} className={classes.activePage} onClick={() => scrollingUp(x)}>
                  {x}
               </span>,
            )
         } else {
            array.push(
               <span key={x} onClick={() => scrollingUp(x)}>
                  {x}
               </span>,
            )
         }
      }
      return array
   }
   useEffect(() => {
      switch (typeOfPage) {
         case 'asics':
            document.title = `Asic - Майнеры | Страница ${activePage}.`
            break
         case 'favorites':
            document.title = `Избранное | Страница ${activePage}.`
            break
         case 'news':
            document.title = `Новости майнинга | Страница ${activePage}.`
            break
         default:
            document.title = `404`
      }
   }, [activePage, typeOfPage])
   return (
      <div className={classes.newsPagin} style={{ gap: `${gap}%` }}>
         <div className={classes.newsPaginShow}>
            <p>
               Показано {activePage * elementsPerPage - (elementsPerPage - 1)} -{' '}
               {activePage * elementsPerPage <= dataLength
                  ? activePage * elementsPerPage
                  : dataLength}{' '}
               из {dataLength}
            </p>
         </div>
         <div className={classes.newsPaginController}>
            <span onClick={() => pageScrolling(false)}>Назад</span>
            {pagination()}
            <span onClick={() => pageScrolling(true)}>Дальше</span>
         </div>
      </div>
   )
}

export default Pagination
