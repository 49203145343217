import classes from '../DescriptionItem.module.scss'

const ReviewComment = ({ created_at, description, rating, user_name, avatar_color }) => {
   const date = new Date(created_at)

   const options = { day: 'numeric', month: 'long' }
   const formattedDate = date.toLocaleDateString('ru-RU', options)
   const fiveStar = [1, 2, 3, 4, 5]
   return (
      <div className={classes.wrapperItemReview}>
         <div className={classes.titleAvatar}>
            <div className={classes.avatarIcon} style={{ background: avatar_color }}>
               {user_name?.[0].toUpperCase()}
            </div>
            <div className={classes.nameAndCreated}>
               <p>{user_name}</p>
               <span>{formattedDate}</span>
            </div>
         </div>
         <div className={classes.rating}>
            {fiveStar.map((item) => (
               <div className={classes.starWrapper} key={item}>
                  <svg
                     width='20'
                     height='20'
                     viewBox='0 0 20 20'
                     xmlns='http://www.w3.org/2000/svg'
                     key={item}
                  >
                     <path
                        d='M9.53803 0.47911C9.19773 0.316859 8.80239 0.316859 8.46209 0.47911C8.16791 0.619372 8.00571 0.862903 7.92392 0.996505C7.83936 1.13462 7.75256 1.31056 7.66795 1.48205L5.87151 5.12145L1.85313 5.7088C1.66397 5.73641 1.46988 5.76474 1.31245 5.80259C1.16015 5.83921 0.878562 5.91842 0.654469 6.15496C0.395227 6.4286 0.273308 6.80461 0.322658 7.17831C0.365318 7.50133 0.546854 7.73071 0.648688 7.84973C0.753953 7.97276 0.894494 8.1096 1.03146 8.24295L3.93796 11.0739L3.25216 15.0724C3.21978 15.261 3.18657 15.4544 3.17378 15.6159C3.16142 15.772 3.14946 16.0645 3.30505 16.3509C3.48501 16.6822 3.80491 16.9146 4.17563 16.9833C4.49614 17.0427 4.77054 16.941 4.91524 16.8809C5.06486 16.8189 5.23854 16.7275 5.40784 16.6384L9.00006 14.7493L12.5923 16.6384C12.7616 16.7275 12.9353 16.8189 13.0849 16.8809C13.2296 16.941 13.504 17.0427 13.8245 16.9833C14.1952 16.9146 14.5151 16.6822 14.6951 16.3509C14.8507 16.0645 14.8387 15.772 14.8263 15.6159C14.8136 15.4544 14.7803 15.261 14.748 15.0725L14.0622 11.0739L16.9687 8.24292C17.1056 8.10957 17.2462 7.97275 17.3514 7.84973C17.4533 7.73071 17.6348 7.50133 17.6775 7.17831C17.7268 6.80461 17.6049 6.4286 17.3457 6.15496C17.1216 5.91842 16.84 5.83922 16.6877 5.80259C16.5302 5.76474 16.3361 5.73641 16.147 5.7088L12.1286 5.12145L10.3322 1.48211C10.2476 1.31061 10.1608 1.13463 10.0762 0.996505C9.99441 0.862903 9.83221 0.619372 9.53803 0.47911Z'
                        fill={item <= rating ? `#F3CB67` : '#E2E4E9'}
                     />
                  </svg>
               </div>
            ))}
         </div>
         <div className={classes.descrComment} dangerouslySetInnerHTML={{ __html: description }} />
      </div>
   )
}

export default ReviewComment
