import classes from './CatalogWatchEarly.module.scss'
import CatalogItem from '../catalogWrapper/CatalogItem/CatalogItem'

const CatalogWatchEarly = ({ name, items }) => {
   return (
      <div className={classes.wrapper + ' container'}>
         <div className={classes.title}>
            <div className={classes.nameBlock}>{name}</div>
         </div>
         <div className={classes.itemsWrapper}>
            <div className={classes.sliderWrapper}>
               {items.map((item) => (
                  <CatalogItem {...item} key={item.id} />
               ))}
            </div>
         </div>
      </div>
   )
}

export default CatalogWatchEarly
