import axios from 'axios'
import { baseUrl } from '../config/config'
import { isExpired, decodeToken } from 'react-jwt'
import { useNavigate } from 'react-router-dom'

const dataToken = localStorage.getItem('auth_token')
const expired = isExpired(localStorage.getItem('auth_token'))
const url = `${baseUrl}/auth/me`
let token = document.cookie.split(';')
const requared = axios.create({
   headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${dataToken}`,
   },
})


requared.interceptors.request.use((config) => {
   if (expired) {
      axios
         .post(baseUrl + '/auth/refresh', {
            headers: {
               Accept: 'application/json',
               withCredentials: true
            },
         })
         .then((response) => {
            let tokenData = response
            localStorage.setItem('auth_token', tokenData.data.access_token)
            // config.headers.Accept = 'application/json'
            // config.headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`
            // return config
         })
         .catch((error) => {
            console.error(error.status, error.statusText)
         })
   } else {
      config.headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`
      config.headers.Accept = 'application/json'
      return config
   }
})

// requared.interceptors.response.use(function (error) {
// axios
//    .post(`https://dev.imine.ru/api/v1/auth/refresh`, {
//       headers: {
//          accept: 'application/json',
//          cookie: `REFRESH-TOKEN=` + dataStore,
//       },
//    })
//    .then((response) => {
//       const data = response.data
//       localStorage.setItem('auth_token', data.access_token)
//    })
// .catch((error) => {
//    error.headers.Accept = 'application/json'
//    error.headers.Authorization = `Bearer ${localStorage.getItem('auth_token')}`
// })
//    console.log(error.status)
//    return error
// })

export default requared
