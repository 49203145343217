import React from 'react'
import Error404 from '../../common/error/Error404'

const ErrorPage = () => {
   return (
      <Error404 />
   )
}

export default ErrorPage
