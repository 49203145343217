import classes from './Newsfp.module.scss'
import NewsItem from './newsItem/NewsItem'
import NewsFPitem from './newsFPitem/newsPFitem'

import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../../config/config'

const NewsFP = ({ option }) => {
   const [postData, setPostData] = useState(null)
   function scrollUp() {
      window.scroll(0, document.getElementById('newsPage'))
   }
   useEffect(() => {
      axios
         .get(`${baseUrl}/post/?limit=6&offset=0`)
         .then((response) => {
            scrollUp()
            const data = response.data
            setPostData(data)
         })
         .catch((error) => {
            scrollUp()
            console.error('Произошла ошибка:', error)
         })
   }, [])
   return (
      <div className={classes.news + ' container'}>
         <div className={classes.newsHeader}>
            {option === 'main_page' ? (
               <>
                  <h3>Новости</h3>
                  <p>Все новости</p>
               </>
            ) : (
               <>
                  <h3>Читайте также</h3>
               </>
            )}
         </div>
         <div className={classes.newsContent}>
            <div>
               {postData === null
                  ? null
                  : option === 'main_page'
                  ? postData.map((element, index) => {
                       return <NewsFPitem key={index} data={element} />
                    })
                  : option === 'news_card'
                  ? postData.map((element, index) => {
                       return <NewsItem key={index} data={element} />
                    })
                  : null}
            </div>
         </div>
      </div>
   )
}

export default NewsFP
