import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './BucketCalculator.module.scss'
// import { ProfileContext } from '../../../App'

// import axios from 'axios'

const BucketCalculator = ({ units, totalMoney }) => {
   const [token, setToken] = useState(localStorage.getItem('access_token'))
   // const [response, setResponse] = useState()
   const bucketFormated = JSON.parse(localStorage.getItem('bucket'))
   // const context = useContext(ProfileContext)
   const [deliveryCost, setDeliveryCost] = useState(3642)
   const navigate = useNavigate()
   const endOf = (value) => {
      if (value === undefined || value === null) return ''
      switch (true) {
         case value === 1:
            return value + ' товар'
         case [2, 3, 4].includes(value):
            return value + ' товара'
         default:
            return value + ' товаров'
      }
   }
   // function formatingCart() {
   //    let array = []
   //    for (const key in bucketFormated) {
   //       array.push({ asic_id: key[0], count: bucketFormated[key] })
   //    }
   //    return array
   // }
   // const order = {
   //    phone: '9023121432',
   //    email: 'gnoom2@mail.com',
   //    delivery_address: 'Test adress',
   //    delivery_type: 0,
   //    payment_type: 0,
   //    telegram: '@PartyHard',
   //    first_name: 'Patric',
   //    last_name: 'Starfish',
   // }
   // function scrollUp() {
   //    window.scroll(0, document.getElementById('newsPage'))
   // }
   function slitter(value) {
      if (typeof value === 'number') {
         return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return value
   }
   // function formattingData() {
   //    let array = []
   //    let formatedObject = { order: {}, cart: {} }
   //    for (const key in bucketFormated) {
   //       array.push({ asic_id: key[0], count: bucketFormated[key] })
   //    }
   //    return (formatedObject = { order: { ...order }, cart: { array } })
   // }

   // function sendRequest() {
   //    if (token !== null) {
   //       axios
   //          .post(
   //             'https://dev.imine.ru/api/v1/orders/individual',
   //             JSON.stringify(formattingData()),
   //             {
   //                headers: {
   //                   Accept: 'application/json',
   //                   'Content-Type': 'application/json',
   //                   Authorization: `Bearer ${token}`,
   //                },
   //             },
   //          )
   //          .then((response) => {
   //             const data = response.data
   //             setResponse(data)
   //          })
   //          .catch((error) => {
   //             console.error('Произошла ошибка:', error)
   //          })
   //    }
   // }

   useEffect(() => {
      if (JSON.parse(localStorage.getItem('amountItems')) === 0) {
         setDeliveryCost(0)
      } else {
         setDeliveryCost(3642)
      }
   }, [localStorage.getItem('amountItems'), token])
   return (
      <div className={classes.rightColMain}>
         <h3>Ваш заказ :</h3>
         <ul className={classes.priceProcessor}>
            <li className={classes.units}>
               <span>Товары</span>
               <span></span>
               <span>{slitter(totalMoney)} ₽</span>
            </li>
            {/* <li className={classes.units}>
               <span>Скидка</span>
               <span></span>
               <span>{slitter(totalMoney * 0.2)} ₽</span>
            </li> */}
            {/* <li className={classes.units}>
               <span>Доставка</span>
               <span></span>
               <span>{slitter(deliveryCost)} ₽</span>
            </li> */}
            {/* <li className={classes.units}>
               <span>Промокод</span>
               <span></span>
               <span>20 %</span>
            </li> */}
            {/* <li className={classes.removed}>
               <span></span>
               <span></span>
               <span>{slitter(totalMoney + deliveryCost)}</span>
            </li> */}
            <li className={classes.total}>
               <span>Итого :</span>
               <span></span>
               <span>{slitter(totalMoney)} ₽</span>
            </li>
         </ul>
         <button
            className={classes.orderButton}
            onClick={() => {
               navigate('/cart/ordering')
            }}
         >
            Оформить {endOf(units)}
         </button>
      </div>
   )
}

export default BucketCalculator
