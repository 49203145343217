import { useState, useEffect, useMemo } from 'react'
import axios from 'axios'
import callback from '../../../accests/image/callbackBaner.png'
import Crums from '../../common/breadCrums/Crums'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import SmallSlider from '../../common/smallSlider/SmallSlider'
import classes from './DataCenterItem.module.scss'
import price from '../../../accests/image/dc.svg'
import dashbord from '../../../accests/image/dashboard_dc.svg'
import server from '../../../accests/image/box_dc.svg'
import ModalFeedback from './modalFeedback/ModalFeedback'
import { baseUrl } from '../../config/config'
import { useParams, useNavigate } from 'react-router-dom'

const DataCemterItem = () => {
   const params = Object.values(useParams())
   const navigate = useNavigate()
   const [dataCenters, setDataCenters] = useState(null)

   useEffect(() => {
      axios
         .get(baseUrl + `/datacenters/${params}`)
         .then((respons) => {
            setDataCenters(respons.data)
         })
         .catch((error) => {
            console.error(error.status, error.statusText)
            navigate('/Error404')
         })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const [linkHash, setLinkHash] = useState('')

   useMemo(() => {
      dataCenters?.video_url?.includes('watch')
         ? setLinkHash(dataCenters?.video_url.split('=')[1].split('&')[0])
         : setLinkHash(dataCenters?.video_url?.split('/')[3].split('?')[0] ?? '')
   }, [dataCenters])

   return (
      <div className={'container'}>
         <Crums
            data={[
               ['data-centers', 'Дата-центры'],
               ['', dataCenters?.location],
            ]}
         />
         {dataCenters === null ? (
            <PageSpinner />
         ) : (
            <div className={classes.content}>
               <div className={classes.nameMobile}>{dataCenters.location}</div>
               <SmallSlider
                  photo_urls={
                     dataCenters.photo_urls === null || dataCenters.photo_urls === undefined
                        ? [...callback]
                        : [...dataCenters.photo_urls]
                  }
               />
               <div className={classes.info}>
                  <div className={classes.name}>{dataCenters.location}</div>
                  <ul className={classes.infoLabel}>
                     <li>
                        <img src={price} alt={'price'} />
                        <span>Цена за </span>кВт/ч - {dataCenters.price} р.
                     </li>
                     <li>
                        <img src={dashbord} alt={'dashboard'} />
                        <span>Свободные места: </span>
                        <span
                           className={
                              dataCenters.slots_available >= dataCenters.slots_occupied
                                 ? classes.have
                                 : classes.hvnt
                           }
                        >
                           {dataCenters.slots_available >= dataCenters.slots_occupied ? (
                              <span className={classes.dataCentersAmount}>
                                 {dataCenters.slots_available} шт.
                              </span>
                           ) : (
                              'Нет'
                           )}
                        </span>
                     </li>
                     <li>
                        <img src={server} alt={'server'} />
                        <span>Мин кол-во: </span>От {dataCenters.slots_min} шт.
                     </li>
                  </ul>
                  <div
                     className={classes.descr}
                     dangerouslySetInnerHTML={{ __html: `${dataCenters.description}` }}
                  ></div>
                  {!!linkHash.length && (
                     <div className={classes.video}>
                        <iframe
                           src={`https://www.youtube.com/embed/${linkHash}`}
                           title='YouTube video player'
                           allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                           referrerPolicy='strict-origin-when-cross-origin'
                           allowFullScreen
                        ></iframe>
                     </div>
                  )}
                  <ModalFeedback />
               </div>
            </div>
         )}
      </div>
   )
}

export default DataCemterItem
