import classes from './BucketItem.module.scss'

import { addRemoveCompareById } from '../../common/comFn'
import { BucketContext } from '../../../App'
import BitmanItemImage from '../../../accests/image/bitmanImage.png'
import plus from '../../../accests/image/bucket_plus.svg'
import minus from '../../../accests/image/bucket_minus.svg'
import del from '../../../accests/image/del_bucket.svg'
import comparisonIcon from '../../../accests/image/comparison.svg'
import { imageUrl } from '../../config/config'
import { useState, useContext, useEffect } from 'react'

import ButtonFavorite from '../catalogWrapper/ButtonFavorite/ButtonFavorite'

const BucketItem = ({ data, selecting, selected }) => {
   const context = useContext(BucketContext)
   const bucketFormated = JSON.parse(localStorage.getItem('bucket'))
   const [selectInput, setSelectInput] = useState(false)
   const [itemsAmount, setitemAmount] = useState(bucketFormated[data.id])

   function slitter(value) {
      if (value !== null) {
         let processingArray = value.toString().split('').reverse()
         let readyArray = []
         // eslint-disable-next-line array-callback-return
         processingArray.map((element, index) => {
            if (index % 3) {
               readyArray.push(element)
            } else {
               readyArray.push(element + ' ')
            }
         })
         return readyArray.reverse()
      } else {
         return null
      }
   }
   function detect(e) {
      if (e.target.value === '0') {
         context.dispatch({
            type: 'select_amount',
            payload: [1, data.id],
         })
      } else {
         context.dispatch({
            type: 'select_amount',
            payload: [e.target.value.replace(/\D/g, ''), data.id],
         })
      }
   }
   function openInput(e) {
      e.stopPropagation()
      setSelectInput(!selectInput)
   }
   useEffect(() => {
      setitemAmount(bucketFormated[data.id])
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [JSON.parse(localStorage.getItem('bucket'))])
   return (
      <div className={selected ? classes.cartItemActive : classes.cartItem}>
         <div className={classes.cartItemFst} onClick={() => selecting(data.id)}>
            <label>
               <input type='checkbox' checked={selected} />
               <img
                  src={
                     !!data.photo_urls?.length
                        ? `${imageUrl}${data.photo_urls[0]}`
                        : BitmanItemImage
                  }
                  alt={data.name}
               />
            </label>
         </div>
         <div className={classes.cartItemScnd} onClick={() => setSelectInput(false)}>
            <h4>{data.name}</h4>
            <span>
               {data.is_new === true ? <p className={classes.cartItemNew}>Новинка</p> : null}
               {data.sallary > 0 ? (
                  <p className={classes.cartItemDiscount}>{data.sallary} %</p>
               ) : data.sallary === null ? null : null}
               {data.hit === true ? <p className={classes.cartItemHit}>Хит</p> : <p> </p>}
               {data.is_new === true ? <p className={classes.cartItemHit}>Новинка</p> : <p> </p>}
            </span>
            <span>
               <p>
                  Хэшрейт: <b> {data.hashrate} TH/s</b>
               </p>
               <p>
                  Алгоритм: <b>{data.algorithm.value}</b>
               </p>
               <p>
                  Монеты: <b>{data.coins.map((element) => element.value)}</b>
               </p>
            </span>
         </div>
         <div className={classes.cartItemFrd}>
            <h4>
               <span>{slitter(data.total_price)} ₽</span>
               <span className={classes.priceSlice}>{slitter(data.price)}</span>
               {data.discount !== null ? (
                  <span className={classes.priceDiscount}>- {slitter(data.discount)} ₽</span>
               ) : null}
            </h4>
            <p>
               <span
                  className={classes.cartItemButton}
                  onClick={() =>
                     context.dispatch({ type: 'decrease_item_amount', payload: data.id })
                  }
               >
                  <img src={minus} alt='minus' />
               </span>
               <span>
                  {selectInput === false ? (
                     <span className={classes.itemAmount} onClick={(e) => openInput(e)}>
                        {itemsAmount}
                     </span>
                  ) : null}
                  {selectInput ? (
                     <input
                        className={classes.itemAmountInput}
                        type='text'
                        value={itemsAmount}
                        maxLength='4'
                        onInput={(e) => detect(e)}
                     />
                  ) : null}
               </span>
               <span
                  className={classes.cartItemButton}
                  onClick={() =>
                     context.dispatch({ type: 'increase_item_amount', payload: data.id })
                  }
               >
                  <img src={plus} alt='plus' />
               </span>
               <span
                  className={`${classes.labelActive} ${
                     localStorage
                        .getItem('compareArr')
                        ?.split(',')
                        .filter((item) => +item === +data.id).length
                        ? classes.compareActive
                        : ''
                  }`}
                  onClick={() => addRemoveCompareById(data.id)}
               >
                  <img src={comparisonIcon} alt='comparisonIcon' />
               </span>
               <ButtonFavorite itemId={data.id} />
               {/* <span className={classes.cartItemButton}>
                  <img src={favoritesIcon} alt={favoritesIcon} />
               </span> */}
               <span
                  className={classes.cartItemButton}
                  onClick={() => context.dispatch({ type: 'remove_item', payload: data.id })}
               >
                  <img src={del} alt='delete' />
               </span>
            </p>
         </div>
      </div>
   )
}

export default BucketItem
