import classes from './PageSpinner.module.scss'

const PageSpinner = () => {
   return (
      <div className={classes.loading}>
         <div>
            <h1>Загрузка...</h1>
            <div className={classes.ldsSpinner}>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
               <div></div>
            </div>
         </div>
      </div>
   )
}

export default PageSpinner
