import { useState } from 'react'
import Switcher from '../switcher/Switcher'
import classes from './AsicFilters.module.scss'
import ReactSlider from 'react-slider'
import SelectFilters from './SelectFilters/SelectFilters'
import { text } from '../../../translate'
import Arrow from '../../../accests/image/beforeArrowNext.svg'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'
import CrossImg from '../../../accests/image/crossIcon.svg'

const AsicFilters = ({
   setDiaposonCount,
   diaposonCount,
   diaposonHashRait,
   setDiaposonHashRait,
   // diaposonRating,
   // setDiaposonRating,
   activeBrands,
   setActiveBrands,
   activeSpecial,
   setActiveSpecial,
   activeAlgorytm,
   setActiveAlgorytm,
   activeMiningCoins,
   setActiveMiningCoins,
   diaposonUsePower,
   setDiaposonUsePower,
   isOpenDiscount,
   setIsOpenDiscount,
   isOpenPopylar,
   setIsOpenPopylar,
   setIsOpenMobileFilter,
   isOpenHave,
   setIsOpenHave,
   maxDiaposon,
   maxConsumption,
   maxHashRait,
   clearRes,
   minDiaposon,
   minConsumption,
   minHashRait,
   activeTags,
   setActiveTags,
}) => {
   const [isOpenHash, setIsOpenHash] = useState(false)
   const [isOpenBrand, setIsOpenBrand] = useState(!!window.location.search.length)
   // const [isOpenRaiting, setIsOpenRaiting] = useState(false)
   const [isOpenAlgorytm, setIsOpenAlgorytm] = useState(false)
   const [isOpenUsePower, setIsOpenUsePower] = useState(false)
   const [isOpenMiningCoin, setIsOpenMiningCoin] = useState(false)
   const [isOpenTags, setIsOpenTags] = useState(false)

   // const diaposonFromValue = (valueInput, state, setState, diaposon) => {
   //    if (+valueInput < 0) {
   //       return setState({ ...state, from: 0 })
   //    } else if (+valueInput > state.to) {
   //       return setState({ ...state, from: state.to - diaposon })
   //    }
   //    return setState({ ...state, from: +valueInput })
   // }
   // const diaposonToValue = (valueInput, state, setState, diaposon, max) => {
   //    if (+valueInput < diaposon) {
   //       return setState({ ...state, to: diaposon })
   //    } else if (+valueInput < state.from) {
   //       return setState({ ...state, to: state.from + diaposon })
   //    } else if (+valueInput > max) {
   //       return setState({ ...state, to: max })
   //    }
   //    return setState({ ...state, to: +valueInput })
   // }

   const { data: marketInfoData } = useGetMarketInfo()
   const body = document.getElementById('root')

   const tegOptions = marketInfoData?.asic_tags
      ? [...marketInfoData?.asic_tags, { id: 666, value: 'Хит' }]
      : []

   const lang = 'ru'
   return (
      <div className={classes.wrapper}>
         <div className={classes.title}>
            <p>Фильтры</p>
            <div
               className={classes.cross}
               onClick={() => {
                  body.style.position = 'relative'
                  setIsOpenMobileFilter(false)
               }}
            >
               <img src={CrossImg} alt='cross' />
            </div>
         </div>
         <div className={classes.diaposonWrapper}>
            <div className={classes.diaposonInputs}>
               <div>
                  <p>Цены от</p>
                  <input
                     type='number'
                     name='to'
                     value={diaposonCount.from}
                     onChange={(e) => setDiaposonCount({ ...diaposonCount, from: e.target.value })}
                  />
               </div>

               <div>
                  <p>Цены до</p>
                  <input
                     type='number'
                     name='from'
                     value={diaposonCount.to}
                     onChange={(e) => setDiaposonCount({ ...diaposonCount, to: e.target.value })}
                  />
               </div>
            </div>
            <div className={classes.sliderWrapper}>
               <ReactSlider
                  className='horizontal-slider'
                  thumbClassName='example-thumb'
                  trackClassName='example-track'
                  defaultValue={[diaposonCount.from, diaposonCount.to]}
                  ariaLabelledby={['first-slider-label', 'second-slider-label']}
                  ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                  renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                  pearling
                  max={maxDiaposon}
                  min={minDiaposon}
                  value={[diaposonCount.from, diaposonCount.to]}
                  onChange={(value) => {
                     setDiaposonCount({ from: value[0], to: value[1] })
                  }}
                  minDistance={10000}
               />
            </div>
         </div>

         <div onClick={() => setIsOpenHash(!isOpenHash)}>
            <div className={classes.powerTitle}>
               <p>{text[lang].asicMiners.filters.hashRate}</p>
               <img
                  src={Arrow}
                  alt='arrow'
                  className={isOpenHash ? classes.isOpenImg : classes.rotateImg}
               />
            </div>

            {isOpenHash && (
               <div onClick={(e) => e.stopPropagation()} className={classes.powerInput}>
                  <div className={classes.diaposonInputs}>
                     <div>
                        <input
                           type='number'
                           name='to'
                           value={diaposonHashRait.from}
                           onChange={(e) =>
                              setDiaposonHashRait({ ...diaposonHashRait, from: e.target.value })
                           }
                        />
                     </div>

                     <div>
                        <input
                           type='number'
                           name='from'
                           value={diaposonHashRait.to}
                           onChange={(e) =>
                              setDiaposonHashRait({ ...diaposonHashRait, to: e.target.value })
                           }
                        />
                     </div>
                  </div>
                  <div className={classes.sliderWrapper}>
                     <ReactSlider
                        className='horizontal-slider'
                        thumbClassName='example-thumb'
                        trackClassName='example-track'
                        defaultValue={[diaposonHashRait.from, diaposonHashRait.to]}
                        ariaLabelledby={['first-slider-label', 'second-slider-label']}
                        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        pearling
                        max={maxHashRait}
                        min={minHashRait}
                        value={[diaposonHashRait.from, diaposonHashRait.to]}
                        onChange={(value) => {
                           setDiaposonHashRait({ from: value[0], to: value[1] })
                        }}
                        minDistance={1}
                     />
                  </div>
               </div>
            )}
         </div>

         <SelectFilters
            name={text[lang].asicMiners.filters.brands}
            isOpen={isOpenBrand}
            setIsOpen={() => setIsOpenBrand(!isOpenBrand)}
            options={marketInfoData.brands}
            activeOptions={activeBrands}
            setActiveOptions={setActiveBrands}
         />

         {/* <div onClick={() => setIsOpenRaiting(!isOpenRaiting)}>
            <div className={classes.powerTitle}>
               <p>{text[lang].asicMiners.filters.ranked}</p>
               <img
                  src={Arrow}
                  alt='arrow'
                  className={isOpenRaiting ? classes.isOpenImg : classes.rotateImg}
               />
            </div>

            {isOpenRaiting && (
               <div onClick={(e) => e.stopPropagation()} className={classes.powerInput}>
                  <div className={classes.diaposonInputs}>
                     <div>
                        <input
                           type='number'
                           name='to'
                           value={diaposonRating.from}
                           onChange={(e) => setDiaposonRating({ ...diaposonRating, from: e.target.value })}
                        />
                     </div>

                     <div>
                        <input
                           type='number'
                           name='from'
                           value={diaposonRating.to}
                           onChange={(e) =>
                              diaposonToValue(
                                 e.target.value,
                                 diaposonRating,
                                 setDiaposonRating,
                                 1,
                                 5,
                              )
                           }
                           onChange={(e) => setDiaposonRating({ ...diaposonRating, to: e.target.value })}

                        />
                     </div>
                  </div>
                  <div className={classes.sliderWrapper}>
                     <ReactSlider
                        className='horizontal-slider'
                        thumbClassName='example-thumb'
                        trackClassName='example-track'
                        defaultValue={[diaposonRating.from, diaposonRating.to]}
                        ariaLabelledby={['first-slider-label', 'second-slider-label']}
                        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        pearling
                        max={5}
                        min={0}
                        value={[diaposonRating.from, diaposonRating.to]}
                        onChange={(value) => {
                           setDiaposonRating({ from: value[0], to: value[1] })
                        }}
                        minDistance={1}
                     />
                  </div>
               </div>
            )}
         </div> */}

         <SelectFilters
            name={text[lang].asicMiners.filters.algorytm}
            isOpen={isOpenAlgorytm}
            setIsOpen={() => setIsOpenAlgorytm(!isOpenAlgorytm)}
            options={marketInfoData.algorithms}
            activeOptions={activeAlgorytm}
            setActiveOptions={setActiveAlgorytm}
         />
         <div onClick={() => setIsOpenUsePower(!isOpenUsePower)}>
            <div className={classes.powerTitle}>
               <p>{text[lang].asicMiners.filters.usePower}</p>
               <img
                  src={Arrow}
                  alt='arrow'
                  className={isOpenUsePower ? classes.isOpenImg : classes.rotateImg}
               />
            </div>

            {isOpenUsePower && (
               <div onClick={(e) => e.stopPropagation()} className={classes.powerInput}>
                  <div className={classes.diaposonInputs}>
                     <div>
                        <input
                           type='number'
                           name='to'
                           value={diaposonUsePower.from}
                           onChange={(e) =>
                              setDiaposonUsePower({ ...diaposonUsePower, from: e.target.value })
                           }
                        />
                     </div>

                     <div>
                        <input
                           type='number'
                           name='from'
                           value={diaposonUsePower.to}
                           onChange={(e) =>
                              setDiaposonUsePower({ ...diaposonUsePower, to: e.target.value })
                           }
                        />
                     </div>
                  </div>
                  <div className={classes.sliderWrapper}>
                     <ReactSlider
                        className='horizontal-slider'
                        thumbClassName='example-thumb'
                        trackClassName='example-track'
                        defaultValue={[diaposonUsePower.from, diaposonUsePower.to]}
                        ariaLabelledby={['first-slider-label', 'second-slider-label']}
                        ariaValuetext={(state) => `Thumb value ${state.valueNow}`}
                        renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                        pearling
                        max={maxConsumption}
                        min={minConsumption}
                        value={[diaposonUsePower.from, diaposonUsePower.to]}
                        onChange={(value) => {
                           setDiaposonUsePower({ from: value[0], to: value[1] })
                        }}
                        minDistance={1}
                     />
                  </div>
               </div>
            )}
         </div>
         <SelectFilters
            name={text[lang].asicMiners.filters.tags}
            isOpen={isOpenTags}
            setIsOpen={() => setIsOpenTags(!isOpenTags)}
            options={tegOptions}
            activeOptions={activeTags}
            setActiveOptions={setActiveTags}
         />

         <SelectFilters
            name={text[lang].asicMiners.filters.maningCoin}
            isOpen={isOpenMiningCoin}
            setIsOpen={() => setIsOpenMiningCoin(!isOpenMiningCoin)}
            options={marketInfoData.coins}
            activeOptions={activeMiningCoins}
            setActiveOptions={setActiveMiningCoins}
         />

         <Switcher
            name={text[lang].asicMiners.filters.special}
            active={activeSpecial}
            setActive={setActiveSpecial}
         />

         <Switcher
            name={text[lang].asicMiners.filters.discount}
            active={isOpenDiscount}
            setActive={setIsOpenDiscount}
         />
         <Switcher
            name={text[lang].asicMiners.filters.popylar}
            active={isOpenPopylar}
            setActive={setIsOpenPopylar}
         />

         <Switcher
            name={text[lang].asicMiners.filters.have}
            active={isOpenHave}
            setActive={setIsOpenHave}
         />
         <div className={classes.clearBtn} onClick={() => clearRes()}>
            Сбросить фильтр
         </div>
      </div>
   )
}

export default AsicFilters
