import React from 'react'
import classes from './Header.module.scss'
import { PhoneAndLang } from './PhoneAndLang/PhoneAndLang'
import SearchInputBlock from '../searchInputAndLogo/SearchInputBlock'
import CatalogMenu from '../catalogMenu/CatalogMenu'
import NewHeaderMenu from './newHeaderMenu.jsx/NewHeaderMenu'

const Header = () => {
   return (
      <>
         <div className={classes.wrapper}>
            <div className={classes.container + ' container'}>
               <NewHeaderMenu />
               <PhoneAndLang />
            </div>
         </div>
         <SearchInputBlock />
         <CatalogMenu />
      </>
   )
}

export default Header
