import classes from './FormOrdering.module.scss'
import { useEffect, useState, useContext, useRef } from 'react'
import { useNavigate } from 'react-router-dom'

import { ProfileContext } from '../../../../App'
// import OrderPopup from '../OrderPopup'
import UserDataForm from './orderingFormElements/UserDataForm'
import RegularUserForm from './orderingFormElements/userDataItemsForm/RegularUserForm'
import CommercialUserForm from './orderingFormElements/userDataItemsForm/CommercialUserForm'
import FormDelivery from './orderingFormElements/FormDelivery'
import FormPayment from './orderingFormElements/FormPayment'
import FormRightColumn from './orderingFormElements/FormRightColumn'
import arrow from '../../../../accests/image/vuesax_arrowRight.svg'
import axios from 'axios'
import { baseUrl } from '../../../config/config'
import SuccessPopup from '../../successPopup/SuccessPopup'

const FormOrdering = ({ data }) => {
   const orderingform = useRef()
   const [discount, setDiscount] = useState(0)
   const navigate = useNavigate()
   const [activatePopup, setActivatePopup] = useState(false)
   const context = useContext(ProfileContext)
   const token = localStorage.getItem('auth_token')
   const [delivery, setDelivery] = useState(null)
   const [payment, setPayment] = useState(null)
   const [user, setUser] = useState(false)
   const [promoError, setPromoError] = useState(false)
   const [promo, setPromo] = useState('')
   const [responseData, setResponsData] = useState()
   const bucketFormated = JSON.parse(localStorage.getItem('bucket'))
   const [userData, setUserData] = useState({
      ...data,
      delivery_type: null,
      payment_type: null,
      delivery_address: '',
      telegram: null,
      // phone: null
   })
   function settingUserData(inputName, inputValue) {
      setUserData({ ...userData, [inputName]: inputValue })
   }
   function deliveryMethodFunc(value) {
      setDelivery(value)
   }
   function paymentFunc(value) {
      setPayment(value)
   }
   function validatingPayment() {
      setUserData({ ...userData, payment_type: 'error' })
   }
   const promoCheckFragment = () => {
      function promoCheck(e) {
         e.preventDefault()
         axios
            .get(baseUrl + `/orders/promo/${promo}`, {
               headers: {
                  'Content-Type': 'application/json',
               },
            })
            .then((response) => {
               let dataRecive = response
               setDiscount(dataRecive.data.discount_percents)
               setPromoError(false)
            })
            .catch((error) => {
               setPromoError(true)
               setDiscount(0)
               console.error('Произошла ошибка:', error.statusText)
            })
      }
      return (
         <>
            <label className={classes.checkPromo}>
               <input
                  type='text'
                  placeholder='Промокод'
                  minLength={5}
                  onChange={(e) => setPromo(e.target.value)}
                  value={promo}
               />
               <button onClick={(e) => promoCheck(e)}>
                  <img src={arrow} alt='arrow' />
               </button>
            </label>
            {promoError ? (
               <span className={classes.promoError}>
                  Неверный промокод или промокод с истекшим сроком действия
               </span>
            ) : null}
         </>
      )
   }
   function validatingDelivery() {
      setUserData({ ...userData, delivery_type: 'error' })
   }
   function validatingFrsName() {
      setUserData({ ...userData, first_name: 'error' })
   }
   function validatingLstName() {
      setUserData({ ...userData, last_name: 'error' })
   }
   function validatingPhone() {
      setUserData({ ...userData, phone_number: 'error' })
   }
   function validatingMail() {
      setUserData({ ...userData, email: 'error' })
   }
   function formattingData() {
      let array = []
      let formatedObject = { order: {}, cart: {} }
      for (const key in bucketFormated) {
         array.push({ asic_id: key, count: bucketFormated[key] })
      }
      return (formatedObject = {
         promocode: !promoError ? promo : '',
         order: { ...userData, phone: userData.phone_number },
         cart: array,
      })
   }
   function sendingUserOrderOnEnter(e) {
      e.preventDefault()
      if (
         userData.first_name === null ||
         userData.first_name === 'error' ||
         userData.first_name.length <= 2
      ) {
         validatingFrsName()
      } else if (
         userData.last_name === null ||
         userData.last_name === 'error' ||
         userData.last_name.length <= 2
      ) {
         validatingLstName()
      } else if (
         userData.phone_number === null ||
         userData.phone_number === 'error' ||
         userData.phone_number.length <= 2
      ) {
         validatingPhone()
      } else if (
         userData.email === null ||
         userData.email === 'error' ||
         userData.email.match(/^\S+@\S+\.\S+$/) === null
      ) {
         validatingMail()
      } else if (
         userData.delivery_type === null ||
         userData.delivery_type === 'error' ||
         (userData.delivery_type !== 0 && userData.delivery_address.length <= 5)
      ) {
         validatingDelivery()
      } else if (userData.payment_type === null || userData.payment_type === 'error') {
         validatingPayment()
      } else if (
         userData.delivery_type === null ||
         userData.delivery_type === 'error' ||
         (userData.delivery_type !== 0 && userData.delivery_address.length <= 5)
      ) {
         validatingDelivery()
      } else if (userData.payment_type === null || userData.payment_type === 'error') {
         validatingPayment()
      } else {
         axios
            .post(baseUrl + '/orders/individual', JSON.stringify(formattingData()), {
               headers: {
                  'Content-Type': 'application/json',
                  Accept: 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
               },
            })
            .then((response) => {
               if (response.status === 200) {
                  const data = response.data
                  setResponsData(data)
                  setActivatePopup(true)
               } else if (response.status === 422) {
                  context.storeDispatch({
                     type: 'popup_toggle',
                     payload: true,
                  })
               }
            })
            .catch((error) => {
               console.log(error.status, error.statusText)
               navigate('/Error404')
            })
      }
   }
   const buttonToSend = () => {
      function sendingUserOrder(e) {
         e.preventDefault()
         if (
            userData.first_name === null ||
            userData.first_name === 'error' ||
            userData.first_name.length <= 2
         ) {
            validatingFrsName()
         } else if (
            userData.last_name === null ||
            userData.last_name === 'error' ||
            userData.last_name.length <= 2
         ) {
            validatingLstName()
         } else if (
            userData.phone_number === null ||
            userData.phone_number === 'error' ||
            userData.phone_number.length <= 2
         ) {
            validatingPhone()
         } else if (
            userData.email === null ||
            userData.email === 'error' ||
            userData.email.match(/^\S+@\S+\.\S+$/) === null
         ) {
            validatingMail()
         } else if (
            userData.delivery_type === null ||
            userData.delivery_type === 'error' ||
            (userData.delivery_type !== 0 && userData.delivery_address.length <= 5)
         ) {
            validatingDelivery()
         } else if (userData.payment_type === null || userData.payment_type === 'error') {
            validatingPayment()
         } else if (
            userData.delivery_type === null ||
            userData.delivery_type === 'error' ||
            (userData.delivery_type !== 0 && userData.delivery_address.length <= 5)
         ) {
            validatingDelivery()
         } else if (userData.payment_type === null || userData.payment_type === 'error') {
            validatingPayment()
         } else {
            axios
               .post(baseUrl + '/orders/individual', JSON.stringify(formattingData()), {
                  headers: {
                     'Content-Type': 'application/json',
                     Accept: 'application/json',
                     Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
                  },
               })
               .then((response) => {
                  if (response.status === 200) {
                     const data = response.data
                     setResponsData(data)
                     setActivatePopup(true)
                  } else if (response.status === 422) {
                     context.storeDispatch({
                        type: 'popup_toggle',
                        payload: true,
                     })
                  }
               })
               .catch((error) => {
                  console.log(error.status, error.statusText)
                  navigate('/Error404')
               })
         }
      }
      return (
         <button className={classes.orderButton} onClick={(e) => sendingUserOrder(e)}>
            Оформить заказ
         </button>
      )
   }

   useEffect(() => {
      axios
         .get(baseUrl + '/auth/me', {
            headers: {
               Accept: 'application/json',
               Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
         })
         .then((response) => {
            let userDataForming = {}
            const data = response.data
            userDataForming.phone_number = data.phone_number
            userDataForming.first_name = data.first_name
            userDataForming.last_name = data.last_name
            userDataForming.email = data.email
            setUserData({ ...userData, ...userDataForming })
         })
         .catch((error) => {
            context.storeDispatch({
               type: 'popup_toggle',
               payload: true,
            })
            console.error('Произошла ошибка:', error.status, error.statusText)
         })
   }, [token])
   useEffect(() => {
      orderingform.current.addEventListener('keydown', (event) => {
         if (event.keyCode === 13) {
            event.preventDefault()
            sendingUserOrderOnEnter(event)
         }
      })
   }, [userData])
   return (
      <form action='submit' className={classes.formOrder} ref={orderingform}>
         {activatePopup ? <SuccessPopup /> : null}
         <div className={classes.fomrLeftCol}>
            <div className={classes.formSwitcher}>
               <div
                  className={!user ? classes.active : classes.userOficall}
                  onClick={() => setUser(false)}
               >
                  <p>Для физических лиц</p>
               </div>
               {/* <div
                  className={user ? classes.active : classes.userOficall}
                  onClick={() => setUser(false)}
               >
                  <p>Для юридических лиц и ИП</p>
               </div> */}
            </div>
            <UserDataForm>
               {user === true ? (
                  <CommercialUserForm input={settingUserData} userData={userData} />
               ) : (
                  <RegularUserForm input={settingUserData} userData={userData} />
               )}
            </UserDataForm>
            <FormDelivery
               setInput={settingUserData}
               data={userData}
               deliveryMeth={deliveryMethodFunc}
            />
            <FormPayment paymentMeth={paymentFunc} setInput={settingUserData} data={userData} />
         </div>
         <FormRightColumn
            promoChild={promoCheckFragment}
            button={buttonToSend}
            discountPercents={discount}
            promoValue={promo}
         />
      </form>
   )
}

export default FormOrdering
