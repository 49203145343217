import classes from './ProfileOrdersDone.module.scss'
import { useState, useEffect, useContext } from 'react'
import { ProfileContext } from '../../../App'
import axios from 'axios'
import cross from "../../../accests/image/crossIcon.svg"
import { baseUrl,imageUrl } from '../../config/config'
// import requared from '../axiosIntercepter'

const ProfileOrderDone = ({ active }) => {
   const [orderData, setOrderData] = useState()
   const context = useContext(ProfileContext)
   const [video, setVideo] = useState(null)

   function dateFormating(value) {
      let date = value.slice(0, 10).split('-').reverse()
      let formatedDate = date.map((element, index) => (index <= 1 ? element + '.' : element))
      return formatedDate
   }
   function slitter(value) {
      if (typeof value === 'number') {
         return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return value
   }

   function formatingOrder(value) {
      switch (value) {
         case -1:
            return 'Отменен'
         case 0:
            return 'Ожидает подтверждения'
         case 1:
            return 'Подтвержден'
         case 2:
            return 'Ожидает отгрузки'
         case 3:
            return 'Завершен'
         default:
            console.error('Error in orders')
      }
   }
   function itemsOrder(value) {
      let processedArray = []
      for (let key in value) {
         processedArray.push(
            <div className={classes.orderingDoneItem}>
               {value[key].asic.photo_urls === null ? null : (
                  <img src={imageUrl+`${value[key].asic.photo_urls[0]}`} alt='asic' />
               )}
               <div className={classes.orderingDoneItemHeader}>
                  <p>
                     {value[key].asic.name} <br />{' '}
                     <span className={classes.amountItems}>
                        Количество - {value[key].count} шт.
                     </span>
                  </p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>
                        {slitter(value[key].asic.total_price)} ₽
                     </span>
                     {value[key].asic.price !== 0 ? (
                        <span className={classes.orderingDoneItemDiscount}>
                           {value[key].asic.price}
                        </span>
                     ) : null}
                     {value[key].asic.discount !== null ? (
                        <span className={classes.orderingDoneItemDiscountExtra}>
                           -{slitter(value[key].asic.discount)} ₽
                        </span>
                     ) : null}
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>,
            <hr />,
         )
      }
      return processedArray
   }
   useEffect(() => {
      axios
         .get(baseUrl + '/orders?limit=100&offset=0', {
            headers: {
               Accept: 'application/json',
               Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
         })
         .then((response) => {
            const data = response.data
            setOrderData(data.filter((element) => element.status === 1 || element.status === 3  || element.status === -1).reverse())
         })
         .catch((error) => {
            context.storeDispatch({
               type: 'popup_toggle',
               payload: true,
            })
            console.error('Произошла ошибка:', error.response.status, error.response.statusText)
         })
   }, [active])
   return (
      <div
         className={
            active === 'all_orders'
               ? classes.profileOrderingDoneActive
               : classes.profileOrderingDone
         }
      >
         {orderData !== undefined
            ? orderData.map((element, index) => {
               return (
                  <div className={classes.orderingDone} key={index}>
                     <div className={classes.orderingDoneHeader}>
                        <p>
                           Заказ {element.id} <span>от {dateFormating(element.created_at)}</span>
                        </p>
                           <p
                              className={
                                 element.status === 2 ||  element.status === -1
                                    ? classes.statusOrderingDoneReject
                                    : classes.statusOrderingDoneSucces
                              }
                           >
                              {formatingOrder(element.status)}
                           </p>
                     </div>
                     {itemsOrder(element.cart.items)}
                     <span className={classes.orderingDoneItemPrice}>
                        Цена заказа: <span>{slitter(element.total_price)}</span> ₽
                     </span>
                  </div>
               )
            })
            : null}
         {/* <div className={classes.orderingDone}>
            <div className={classes.orderingDoneHeader}>
               <p>
                  Заказ Б-22841549 <span>от 17.08.2022</span>
               </p>
               <p className={classes.statusOrderingDoneReject}>Отменен</p>
            </div>
            <div className={classes.orderingDoneItem}>
               <img src={imageAsic} alt='asic' />
               <div className={classes.orderingDoneItemHeader}>
                  <p>Bitmain Antminer S19K PRO 120 TH/s</p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>190 000 ₽</span>
                     <span className={classes.orderingDoneItemDiscount}>203 000</span>
                     <span className={classes.orderingDoneItemDiscountExtra}>-13 000 ₽</span>
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>
            <hr />
            <div className={classes.orderingDoneItem}>
               <img src={imageAsic} alt='asic' />
               <div className={classes.orderingDoneItemHeader}>
                  <p>Bitmain Antminer S19K PRO 120 TH/s</p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>190 000 ₽</span>
                     <span className={classes.orderingDoneItemDiscount}>203 000</span>
                     <span className={classes.orderingDoneItemDiscountExtra}>-13 000 ₽</span>
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>
         </div>
         <div className={classes.orderingDone}>
            <div className={classes.orderingDoneHeader}>
               <p>
                  Заказ Б-22841549 <span>от 17.08.2022</span>
               </p>
               <p className={classes.statusOrderingDoneSucces}>Завершен</p>
            </div>
            <div className={classes.orderingDoneItem}>
               <img src={imageAsic} alt='asic' />
               <div className={classes.orderingDoneItemHeader}>
                  <p>Bitmain Antminer S19K PRO 120 TH/s</p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>190 000 ₽</span>
                     <span className={classes.orderingDoneItemDiscount}>203 000</span>
                     <span className={classes.orderingDoneItemDiscountExtra}>-13 000 ₽</span>
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>
            <hr />
            <div className={classes.orderingDoneItem}>
               <img src={imageAsic} alt='asic' />
               <div className={classes.orderingDoneItemHeader}>
                  <p>Bitmain Antminer S19K PRO 120 TH/s</p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>190 000 ₽</span>
                     <span className={classes.orderingDoneItemDiscount}>203 000</span>
                     <span className={classes.orderingDoneItemDiscountExtra}>-13 000 ₽</span>
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>
         </div>
         <div className={classes.orderingDone}>
            <div className={classes.orderingDoneHeader}>
               <p>
                  Заказ Б-22841549 <span>от 17.08.2022</span>
               </p>
               <p className={classes.statusOrderingDoneReject}>Отменен</p>
            </div>
            <div className={classes.orderingDoneItem}>
               <img src={imageAsic} alt='asic' />
               <div className={classes.orderingDoneItemHeader}>
                  <p>Bitmain Antminer S19K PRO 120 TH/s</p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>190 000 ₽</span>
                     <span className={classes.orderingDoneItemDiscount}>203 000</span>
                     <span className={classes.orderingDoneItemDiscountExtra}>-13 000 ₽</span>
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>
         </div> */}
      </div>
   )
}

export default ProfileOrderDone
