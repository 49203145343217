import classes from './ContactsMap.module.scss'
import activeMarkerToDraw from '../../../../accests/image/Pin_active.svg'
import unActiveMarkerToDraw from '../../../../accests/image/Pin_unactive.svg'
import React, { useState } from 'react'
import { YMaps, Map, Placemark } from 'react-yandex-maps'

const Marker = ({ defaultGeometry, isActive, elementIndex }) => {
   const [active] = useState(elementIndex === isActive ? activeMarkerToDraw : unActiveMarkerToDraw)
   return (
      <Placemark
         defaultGeometry={[...defaultGeometry]}
         defaultOptions={{
            iconLayout: 'default#image',
            iconImageHref: active,
            iconImageSize: [72, 72],
            iconImageOffset: [-3, -42],
         }}
      />
   )
}

const ContactsMapYandex = ({ activeMarker, data }) => {
   const [scrollFix, setScrollFix] = useState(false)
   return (
      <>
         {scrollFix ? null : (
            <div
               className={classes.mapCreeper}
               onClick={() => {
                  setScrollFix(true)
               }}
            ></div>
         )}
         <div className={classes.contactsMap}>
            <YMaps query={{ apikey: 'f9505ebf-733d-40d6-bfe0-c170fd0a26d3' }}>
               <Map
                  options={{ scrollZoomBehavior: false }}
                  style={{ width: '100%', height: '100%' }}
                  defaultState={{
                     center: [data[activeMarker]?.coor[0], data[activeMarker]?.coor[1]],
                     zoom: 12,
                  }}
               >
                  {data.map((element, index) => {
                     return (
                        <Marker
                           key={index}
                           elementIndex={index}
                           defaultGeometry={[element.coor[0], element.coor[1]]}
                           isActive={activeMarker}
                        />
                     )
                  })}
               </Map>
            </YMaps>
         </div>
      </>
   )
}

export default ContactsMapYandex
