import classes from './FormDelivery.module.scss'
import { useEffect, useState } from 'react'

import Coins from '../../../../../accests/image/Group_coins.svg'
import Cdek from '../../../../../accests/image/Frame_cdek.svg'
import WorkLines from '../../../../../accests/image/Frame_workLine.svg'

const Delivery = ({ setInput, deliveryMeth, data }) => {
   const [activeDelivery, setActiveDelivery] = useState(false)
   const [activeDeliveryMethod, setActiveDeliveryMethod] = useState(null)
   const deliveryMethodsArr = [
      ['Самовывоз', Coins],
      ['', Cdek],
      ['', WorkLines],
   ]

   function setUserData(value) {
      setInput('delivery_type', value)
      setActiveDeliveryMethod(value)
   }
   function activeDeliverySwith() {
      setActiveDelivery(true)
   }

   function deliveryMethods() {
      return (
         <div className={classes.deliveryMethodsCheck}>
            {deliveryMethodsArr.map((element, index) => {
               return (
                  <label
                     key={index + 1}
                     className={
                        data.delivery_type === index
                           ? classes.active
                           : classes.deliveryMethodsCheckSelects
                     }
                     onChange={() => setUserData(index)}
                  >
                     <img src={element[1]} alt={element[0]} />
                     <p>{element[0]}</p>
                     <input
                        type='checkbox'
                        name='delivery_type'
                        checked={index === data.delivery_type ? true : false}
                     />
                  </label>
               )
            })}
         </div>
      )
   }
   useEffect(() => {
      deliveryMeth(activeDeliveryMethod)
   }, [activeDeliveryMethod, data.delivety_type, data.delivery_address])
   return (
      <div
         className={
            data.delivery_type === 'error' ? classes.formDeliveryError : classes.formDelivery
         }
      >
         <div className={classes.formDeliveryHeader}>
            <h3>Доставка</h3>
            {activeDelivery === false ? (
               <input
                  type='checkbox'
                  defaultChecked={false}
                  onChange={() => activeDeliverySwith()}
               />
            ) : (
               <input disabled type='checkbox' defaultChecked={true} />
            )}
         </div>
         {(activeDelivery === true && data.delivery_type > 0) ||
         (activeDelivery === true && data.delivery_type === 'error') ? (
            <div className={classes.formDeliveryAdress}>
               <p>Адрес</p>
               <input
                  type='text'
                  name='delivery_address'
                  value={data.delivery_address}
                  onChange={(e) => {
                     setInput(e.target.name, e.target.value)
                  }}
               />
            </div>
         ) : null}
         {activeDelivery === true ? deliveryMethods() : null}
      </div>
   )
}

export default Delivery
