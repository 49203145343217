import { useEffect, useReducer, useState, use } from 'react'
import { text } from '../../../translate'
import classes from './ProfileData.module.scss'
import requared from '../axiosIntercepter'
import { baseUrl } from '../../config/config'
import SidePopup from '../modalsUtility/SidePopup'
import imgSucc from '../../../accests/image/success.svg'
import imgFail from '../../../accests/image/rejected.svg'

// import loopa from '../../../accests/image/search_loop.svg'
import pencil from '../../../accests/image/pencil.svg'

function formatingPhone(value) {
   switch (value.length) {
      case 1:
         return `${value.substring(0, 1).replace(/(\d{1})/, '+7')}`
      case 5:
         return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(2, 5)}) `
      case 12:
         return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(
            4,
            7,
         )}) ${value.substring(9, 12)}-`
      case 15:
         return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(
            4,
            7,
         )}) ${value.substring(9, 12)}-${value.substring(13, 15)}-`
      case 19:
         return `${value.substring(1, 2).replace(/(\d{1})/, '+7')} (${value.substring(
            4,
            7,
         )}) ${value.substring(9, 12)}-${value.substring(13, 15)}-${value.substring(16, 18)}`
      default:
         return value
   }
}
function reducer(state, action) {
   switch (action.type) {
      case 'changeName':
         return { ...state, first_name: action.payload }
      case 'changeSndName':
         return { ...state, last_name: action.payload }
      case 'changeThrdName':
         return { ...state, surname: action.payload }
      case 'changeMail':
         return { ...state, mail: action.payload }
      case 'changePhone':
         return { ...state, phone_number: formatingPhone(action.payload.replace(/([a-z]|[A-Z])/, '')) }
      case 'clear':
         return { ...action.payload }
      default:
         return null
   }
}

const ProfileData = ({ userData, active }) => {
   const [popup, setPopup] = useState(false)
   const [popopText, setPopupText] = useState("")
   const [userDataState, dispatch] = useReducer(reducer, { ...userData })
   const [userState, setUserState] = useState({ ...userDataState })
   const lang = 'ru'

   function formFormat(value) {
      dispatch({ type: 'changePhone', payload: value })
   }

   function submiting(event) {
      event.preventDefault()
      if (userDataState.phone_number.length >= 19 || userDataState.phone_number !== null || userDataState.phone_number !== undefined) {
         requared.patch(baseUrl + '/auth/profile', { ...userDataState })
            .then(response => {
               setPopup(true)
               setPopupText(
                  <>
                     <span><img src={imgSucc} alt="succes" /></span>
                     <span> Успех! </span>
                     <span>Даныне изменены</span>
                  </>
               )
            })
            .catch(error => {
               setPopup(true)
               setPopupText(
                  <>
                     <span><img src={imgFail} alt="succes" /></span>
                     <span></span>
                     <span></span>
                     <span>Ошибка !</span>
                  </>
               )
               console.log(error.status)
            })
      } else {
         requared.patch(baseUrl + '/auth/profile', { ...userData })
            .then(response => {
               console.log(response.status)
            })
            .catch(error => {
               console.log(error.status)
            })
      }
   }
   useEffect(() => {
      return () => setTimeout(() => setPopup(false), 2100)
   }, [popup])
   return (
      <form
         action='submit'
         preventDefault
         className={active === "profile" ? classes.formData : classes.formDataNone}
         onSubmit={(e) => submiting(e)}
      >
         <div className={classes.formNames}>
            <label>
               <p>{text[lang].profile.profileData.name}</p>
               <span>
                  {/* <img src={loopa} alt='search' /> */}
                  <input
                     type='text'
                     value={userDataState.first_name}
                     placeholder={text[lang].profile.profileData.name}
                     onChange={(event) =>
                        dispatch({ type: 'changeName', payload: event.target.value })
                     }
                  />
               </span>
            </label>
            <label>
               <p>{text[lang].profile.profileData.sndName}</p>
               <span>
                  {/* <img src={loopa} alt='search' /> */}
                  <input
                     type='text'
                     value={userDataState.last_name}
                     placeholder={text[lang].profile.profileData.sndName}
                     onChange={(event) =>
                        dispatch({ type: 'changeSndName', payload: event.target.value })
                     }
                  />
               </span>
            </label>
            <label>
               <p>{text[lang].profile.profileData.thrdName}</p>
               <span>
                  {/* <img src={loopa} alt='search' /> */}
                  <input
                     value={userDataState?.surname}
                     type='text'
                     placeholder={text[lang].profile.profileData.thrdName}
                     onChange={(event) =>
                        dispatch({ type: 'changeThrdName', payload: event.target.value })
                     }
                  />
               </span>
            </label>
         </div>
         <div className={classes.formContacts}>
            <label>
               <p>{text[lang].profile.profileData.eMail}</p>
               <span>
                  {/* <img src={loopa} alt='search' /> */}
                  <input
                     disabled
                     type='text'
                     value={userDataState?.email}
                     placeholder={text[lang].profile.profileData.eMail}
                     onChange={(event) =>
                        dispatch({ type: 'changeMail', payload: event.target.value })
                     }
                  />
                  <button className={classes.buttonDone}>Изменить</button>
                  <img src={pencil} alt='pencil' className={classes.pencil} />
               </span>
            </label>
            <label>
               <p>{text[lang].profile.profileData.contactPhone}</p>
               <span>
                  {/* <img src={loopa} alt='search' /> */}
                  <input
                     type='text'
                     value={userDataState?.phone_number}
                     placeholder='+7 000 00 00 00'
                     onChange={(event) =>
                        formFormat(event.target.value.replace(/([a-z]|[A-Z])/, ''))
                     }
                  />
                  <button className={classes.buttonDone}>Изменить</button>
                  <img src={pencil} alt='pencil' className={classes.pencil} />
               </span>
            </label>
         </div>
         <div className={classes.formButtons}>
            <button
               className={classes.formButtonCancel}
               onClick={(event) => dispatch({ type: 'clear', payload: {} })}
            >
               Отмена
            </button>
            <button className={classes.formButtonApply} type='submit'>
               Сохранить изменения{' '}
            </button>
         </div>
         {popup ?
            <SidePopup dataState={true}>
               {popopText}
            </SidePopup> : null}
      </form>
   )
}

export default ProfileData
