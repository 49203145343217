import { useGetContacts } from '../../../hooks/useGetContacts'
import classes from './PaymentSelect.module.scss'
const PaymentSelect = () => {
   const { data: contact } = useGetContacts()
   return (
      <div className={classes.wrapper}>
         <div className={classes.subTitle}>Оплата:</div>
         <div className={classes.blockWrapper}>
            <div className={classes.svg}>
               <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     d='M4.66675 9.1C4.66675 7.13982 4.66675 6.15972 5.04823 5.41103C5.38378 4.75247 5.91921 4.21703 6.57778 3.88148C7.32647 3.5 8.30656 3.5 10.2667 3.5H17.7334C19.6936 3.5 20.6737 3.5 21.4224 3.88148C22.081 4.21703 22.6164 4.75247 22.9519 5.41103C23.3334 6.15972 23.3334 7.13982 23.3334 9.1V24.5L20.1251 22.1667L17.2084 24.5L14.0001 22.1667L10.7917 24.5L7.87508 22.1667L4.66675 24.5V9.1Z'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
                  <path
                     d='M19 13H9M19 17H9M19 9H9'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
               </svg>
            </div>

            <div className={classes.textWrapper}>
               <div className={classes.subTitle2}>Безналичный расчет с НДС</div>

               <div className={classes.text}>
                  Оплата производится с вашего банковского счета на корпоративный счет IMINE.   
               </div>
            </div>
         </div>

         <div className={classes.blockWrapper}>
            <div className={classes.svg}>
               <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     d='M6.99992 12.8337V17.5003M20.9999 10.5003V15.167M19.8333 4.66699C22.6901 4.66699 24.2352 5.10421 25.0041 5.44334C25.1064 5.4885 25.1576 5.51109 25.3054 5.65209C25.394 5.73661 25.5556 5.98462 25.5972 6.09976C25.6666 6.29185 25.6666 6.39685 25.6666 6.60686V19.1467C25.6666 20.2069 25.6666 20.737 25.5076 21.0095C25.3459 21.2867 25.1899 21.4155 24.8872 21.522C24.5896 21.6268 23.9889 21.5113 22.7875 21.2805C21.9465 21.1189 20.9492 21.0003 19.8333 21.0003C16.3333 21.0003 12.8333 23.3337 8.16659 23.3337C5.30976 23.3337 3.7646 22.8964 2.99578 22.5573C2.8934 22.5121 2.8422 22.4896 2.69445 22.3486C2.60589 22.264 2.44422 22.016 2.40263 21.9009C2.33325 21.7088 2.33325 21.6038 2.33325 21.3938L2.33325 8.85399C2.33325 7.79376 2.33325 7.26364 2.49224 6.99116C2.65397 6.71398 2.80994 6.58513 3.11265 6.47861C3.41024 6.37389 4.01096 6.48931 5.21239 6.72015C6.05331 6.88172 7.05064 7.00032 8.16659 7.00032C11.6666 7.00032 15.1666 4.66699 19.8333 4.66699ZM16.9166 14.0003C16.9166 15.6112 15.6107 16.917 13.9999 16.917C12.3891 16.917 11.0833 15.6112 11.0833 14.0003C11.0833 12.3895 12.3891 11.0837 13.9999 11.0837C15.6107 11.0837 16.9166 12.3895 16.9166 14.0003Z'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
               </svg>
            </div>

            <div className={classes.textWrapper}>
               <div className={classes.subTitle2}>Наличными в офисе IMINE</div>

               <div className={classes.text}>
                  Для наличного расчета мы приглашаем вас в наш офис по адрес: {contact.address}. Не
                  забудьте предварительно согласовать время посещения.
               </div>
            </div>
         </div>

         <div className={classes.blockWrapper}>
            <div className={classes.svg}>
               <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     d='M11.7844 4.66634C13.2574 3.22296 15.2747 2.33301 17.4999 2.33301C22.0102 2.33301 25.6666 5.98935 25.6666 10.4997C25.6666 12.7249 24.7766 14.7423 23.3332 16.2152M18.6666 17.4997C18.6666 22.01 15.0102 25.6663 10.4999 25.6663C5.98959 25.6663 2.33325 22.01 2.33325 17.4997C2.33325 12.9893 5.98959 9.33301 10.4999 9.33301C15.0102 9.33301 18.6666 12.9893 18.6666 17.4997Z'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
               </svg>
            </div>

            <div className={classes.textWrapper}>
               <div className={classes.subTitle2}>Другие способы оплаты</div>
               <div className={classes.text}>
                  Чтобы выбрать другой удобный вам вариант оплаты, обратитесь к нашим менеджерам.
               </div>
            </div>
         </div>
         <div className={classes.subTitle}>Доставка:</div>

         <div className={classes.blockWrapper}>
            <div className={classes.svg}>
               <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     d='M3.5 10.5H24.5V22.1667C24.5 22.7855 24.2542 23.379 23.8166 23.8166C23.379 24.2542 22.7855 24.5 22.1667 24.5H5.83333C5.21449 24.5 4.621 24.2542 4.18342 23.8166C3.74583 23.379 3.5 22.7855 3.5 22.1667V10.5Z'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
                  <path
                     d='M3.5 10.5001L6.35833 4.78339C6.55313 4.39682 6.85161 4.07206 7.22041 3.84542C7.58922 3.61878 8.01379 3.4992 8.44667 3.50006H19.5533C19.9882 3.49703 20.4152 3.61558 20.7863 3.84234C21.1573 4.0691 21.4577 4.39504 21.6533 4.78339L24.5 10.5001'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
                  <path
                     d='M14 3.5V10.5'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
               </svg>
            </div>

            <div className={classes.textWrapper}>
               <div className={classes.subTitle2}>Самовывоз</div>
               <div className={classes.text}>
                  Вы можете получить заказ самостоятельно, для этого согласуйте с нашим менеджером
                  наличие товара и время вашего визита. Наш офис находится в Москве по адресу: 1-я
                  Магистральная улица, 13с7.
               </div>
            </div>
         </div>

         <div className={classes.blockWrapper}>
            <div className={classes.svg}>
               <svg
                  width='28'
                  height='28'
                  viewBox='0 0 28 28'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
               >
                  <path
                     d='M16.3333 8.16699H19.0601C19.3454 8.16699 19.4881 8.16699 19.6224 8.19923C19.7414 8.22781 19.8552 8.27495 19.9596 8.33891C20.0773 8.41106 20.1782 8.51195 20.38 8.71373L25.1199 13.4536C25.3216 13.6554 25.4225 13.7563 25.4947 13.874C25.5586 13.9784 25.6058 14.0922 25.6343 14.2112C25.6666 14.3455 25.6666 14.4882 25.6666 14.7735V18.0837C25.6666 18.6273 25.6666 18.8991 25.5778 19.1135C25.4594 19.3993 25.2322 19.6264 24.9464 19.7449C24.732 19.8337 24.4602 19.8337 23.9166 19.8337M18.0833 19.8337H16.3333M16.3333 19.8337V8.40033C16.3333 7.09354 16.3333 6.44014 16.0789 5.94101C15.8552 5.50197 15.4983 5.14501 15.0592 4.92131C14.5601 4.66699 13.9067 4.66699 12.5999 4.66699H6.06659C4.7598 4.66699 4.1064 4.66699 3.60727 4.92131C3.16823 5.14501 2.81127 5.50197 2.58757 5.94101C2.33325 6.44014 2.33325 7.09354 2.33325 8.40033V17.5003C2.33325 18.789 3.37792 19.8337 4.66659 19.8337M16.3333 19.8337H11.6666M11.6666 19.8337C11.6666 21.7667 10.0996 23.3337 8.16659 23.3337C6.23359 23.3337 4.66659 21.7667 4.66659 19.8337M11.6666 19.8337C11.6666 17.9007 10.0996 16.3337 8.16659 16.3337C6.23359 16.3337 4.66659 17.9007 4.66659 19.8337M23.9166 20.417C23.9166 22.0278 22.6107 23.3337 20.9999 23.3337C19.3891 23.3337 18.0833 22.0278 18.0833 20.417C18.0833 18.8062 19.3891 17.5003 20.9999 17.5003C22.6107 17.5003 23.9166 18.8062 23.9166 20.417Z'
                     stroke='#F3CB67'
                     stroke-width='2'
                     stroke-linecap='round'
                     stroke-linejoin='round'
                  />
               </svg>
            </div>

            <div className={classes.textWrapper}>
               <div className={classes.subTitle2}>Доставка транспортными компаниями</div>
               <div className={classes.text}>
                  Вы можете получить товар доставкой по РФ или СНГ. Мы сотрудничаем с компаниями
                  "СДЭК" и "Деловые линии". Доставка осуществляется до пункта выдачи в вашем городе,
                  отследить заказ можно по номеру вашего отправления на сайте транспортной компании.
               </div>
               <div className={classes.text}>
                  Если вы хотите заказать доставку другой транспортной компанией, сообщите об этом
                  нашему менеджеру при заказе.
               </div>
            </div>
         </div>
      </div>
   )
}

export default PaymentSelect
