import classes from './PhoneAndLang.module.scss'
// import RusFlag from '../../../../accests/image/rusFlag.svg'
// import MobPhone from '../../../../accests/image/phone.svg'
import { useGetContacts } from '../../../hooks/useGetContacts'

export const PhoneAndLang = () => {
   const { data: contact } = useGetContacts()
   return (
      <div className={classes.wrapper}>
         {/* <div className={classes.language}>
            <img className={classes.langImg} src={RusFlag} alt='flag' /> Русский
         </div> */}
         <div className={classes.phone}>
            {/* <img className={classes.phoneImg} src={MobPhone} alt='phone' /> */}
            <a href={`tel:${contact?.phone?.replace(/[^\d+]/g, '')}`}>{contact?.phone ?? ''}</a>
         </div>
      </div>
   )
}
