import classes from './PartnersItem.module.scss'
import backgroundSquers from '../../../../accests/image/squares.svg'
import { imageUrl } from '../../../config/config'

const PartnerLogo = ({ data }) => {
   const [name, src, href] = data
   return (
      <div className={classes.partnerWrapper}>
         <a href={href} className={classes.partnerItem} target='_blank' rel='noreferrer'>
            <img className={classes.BGImage} src={backgroundSquers} alt='backgroundSquere' />
            <img className={classes.imageLogo} src={`${imageUrl}${src}`} alt={name} />
         </a>
      </div>
   )
}

export default PartnerLogo
