import classes from './Faq.module.scss'

import FaqCol from './faqColumns/FaqCol'
import Crums from '../breadCrums/Crums'
import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../../config/config'

const Faq = () => {
   const [activeFaq, setActiveFaq] = useState(0)
   const dataCrums = [['faq', 'FAQ']]
   const [columData1, setColumData1] = useState([])
   const [columData2, setColumData2] = useState([])
   const columnsData1 = [
      {
         id: 1,
         title: 'На сколько быстро окупаются вложения?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
      {
         id: 8,
         title: 'Что такое Asic-майнер?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
      {
         id: 2,
         title: 'На сколько быстро окупаются вложения?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
      {
         id: 3,
         title: 'Что такое Asic-майнер?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
   ]
   const columnsData2 = [
      {
         id: 4,
         title: 'На сколько быстро окупаются вложения?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
      {
         id: 5,
         title: 'Что такое Asic-майнер?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
      {
         id: 6,
         title: 'На сколько быстро окупаются вложения?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
      {
         id: 7,
         title: 'Что такое Asic-майнер?',
         content:
            'Lorem ipsum dolor sit amet consectetur. Maecenas tellus sed nisi orci non venenatis. Id aenean tortor ligula aliquam enim consequat. Molestie tortor tellus ac risus fames elit. Leo rutrum arcu praesent sit lacus.',
      },
   ]

   useEffect(() => {
      axios
         .get(baseUrl + '/info/faq')
         .then((response) => {
            let faqData = response.data
            let colums = faqData.length / 2
            setColumData1(faqData.slice(0, Math.ceil(colums)))
            setColumData2(faqData.slice(Math.ceil(colums), Math.floor(colums) + Math.ceil(colums)))
         })
         .catch((error) => {
            console.error(error.status, error.statusText)
         })
   }, [])

   function selectActiveFaq(value) {
      if (value === activeFaq) {
         setActiveFaq(null)
      } else {
         setActiveFaq(value)
      }
   }
   return (
      <div className={classes.faqHeader + '  container'}>
         <Crums data={dataCrums} />
         <h3>FAQ</h3>
         <div className={classes.faqContent}>
            <FaqCol data={columData1} selectedFaq={activeFaq} selectFaq={selectActiveFaq} />
            <FaqCol data={columData2} selectedFaq={activeFaq} selectFaq={selectActiveFaq} />
         </div>
      </div>
   )
}

export default Faq
