import { useState } from 'react'
import classes from './SmallSlider.module.scss'
// import SliderArrow from '../../../accests/image/beforeArrowNext.svg'
import { imageUrl } from '../../config/config'

const SmallSlider = ({ photo_urls = [] }) => {
   const [activeSlideId, setActiveSlideId] = useState(0)
   const [countSlider, setCountSlider] = useState(
      window.innerWidth < 580
         ? 3
         : window.innerWidth < 780
         ? 5
         : window.innerWidth < 980
         ? 7
         : window.innerWidth < 1280
         ? 9
         : 5,
   )
   const [showPaginPageNumber] = useState(
      window.innerWidth < 580
         ? 3
         : window.innerWidth < 780
         ? 5
         : window.innerWidth < 980
         ? 7
         : window.innerWidth < 1280
         ? 9
         : 5,
   )

   return (
      <>
         {!!photo_urls?.length && (
            <div className={classes.sliderWrapper}>
               <div className={classes.mainImage}>
                  {photo_urls?.map((item, index) => (
                     <img
                        key={item}
                        src={`${imageUrl}/${item}`}
                        alt={item}
                        className={
                           index === activeSlideId ? classes.activeBigImg : classes.hideBitImg
                        }
                     />
                  ))}
               </div>
               <div className={classes.smallSlider}>
                  <div
                     onClick={() => {
                        setCountSlider(
                           countSlider <= showPaginPageNumber
                              ? showPaginPageNumber
                              : countSlider - 1,
                        )
                        setActiveSlideId(activeSlideId <= 0 ? 0 : activeSlideId - 1)
                     }}
                  >
                     {/* <img src={SliderArrow} alt='leftArrow' className={classes.leftArrow} /> */}
                     <svg
                        width='7'
                        height='10'
                        viewBox='0 0 7 10'
                        xmlns='http://www.w3.org/2000/svg'
                        className={classes.leftArrow}
                     >
                        <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                     </svg>
                  </div>

                  {photo_urls
                     ?.slice(countSlider - showPaginPageNumber, countSlider)
                     .map((item, index) => (
                        <div
                           key={item}
                           className={`${classes.smallSliderItem} ${
                              activeSlideId === index ? classes.activeSmallSlide : ''
                           }`}
                           onClick={() => setActiveSlideId(index)}
                        >
                           <img src={`${imageUrl}/${item}`} alt='smallImage' />
                        </div>
                     ))}
                  <div
                     onClick={() => {
                        setCountSlider(
                           photo_urls.length <= countSlider ? countSlider : countSlider + 1,
                        )
                        setActiveSlideId(
                           photo_urls.length - 1 <= activeSlideId
                              ? photo_urls.length - 1
                              : activeSlideId + 1,
                        )
                     }}
                  >
                     {/* <img src={SliderArrow} alt='rightArrow' className={classes.rightArrow} /> */}
                     <svg
                        width='7'
                        height='10'
                        viewBox='0 0 7 10'
                        xmlns='http://www.w3.org/2000/svg'
                        className={classes.rightArrow}
                     >
                        <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                     </svg>
                  </div>
               </div>
            </div>
         )}
      </>
   )
}

export default SmallSlider
