import classes from '../DescriptionItem.module.scss'

const DescrSelect = ({ descr }) => {
   return (
      <div className={classes.wrapper}>
         <div className={classes.text} dangerouslySetInnerHTML={{ __html: descr }}></div>
      </div>
   )
}

export default DescrSelect
