import CommentSelect from './commentSelect/CommentSelect'
import DescrSelect from './descrSelect/DescrSelect'
import PaymentSelect from './paymentSelect/PaymentSelect'
import StatsSelect from './statsSelect/StatsSelect'

const DescriptionItem = ({
   name,
   characteristics,
   description,
   id,
   title,
   dataReview,
   checkRefetch,
   setCheckRefetch,
   item,
}) => {
   return (
      <div>
         {name === 'Описание' && <DescrSelect descr={description} />}
         {name === 'Отзывы' && (
            <CommentSelect
               id={id}
               title={title}
               dataReview={dataReview}
               checkRefetch={checkRefetch}
               setCheckRefetch={setCheckRefetch}
            />
         )}
         {name === 'Характеристики' && (
            <StatsSelect characteristics={characteristics} item={item} />
         )}
         {name === 'Оплата и доставка' && <PaymentSelect />}
      </div>
   )
}

export default DescriptionItem
