import { useState } from 'react'
import VerticalCatalogItem from '../discountBlock/VerticalCatalogItem/VerticalCatalogItem'
import classes from './DiscountBlock.module.scss'

const DiscountBlock = ({ discountItem }) => {
   const initialTime = new Date()
   const [time, setTime] = useState('00:00:00')
   const specialTillDate = new Date(discountItem.special_till)
   const diffMilliseconds = specialTillDate - initialTime

   const diffSeconds = Math.floor(diffMilliseconds / 1000)
   const days = Math.floor(diffSeconds / (3600 * 24))
   const hours = Math.floor((diffSeconds % (3600 * 24)) / 3600)
   const minutes = Math.floor((diffSeconds % 3600) / 60)
   const seconds = diffSeconds % 60

   const formattedTime = `${days ? days + 'д ' : ''}${hours}:${minutes
      .toString()
      .padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`

   setTimeout(() => {
      setTime(formattedTime)
   }, 1000)
   return (
      <div className={classes.wrapper + ' container'}>
         <div className={classes.discountTime}>
            <h4>{discountItem?.special_offer?.discount_type}</h4>
            <p>{time}</p>
         </div>
         <VerticalCatalogItem {...discountItem} />
      </div>
   )
}

export default DiscountBlock
