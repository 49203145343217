import classes from './MainCall.module.scss'
import Phone from '../../../accests/image/whitePhone.svg'
import Arrow from '../../../accests/image/arrowTop.svg'
import { useEffect, useState } from 'react'
import { useGetContacts } from '../../hooks/useGetContacts'

const MainCall = () => {
   const scrollUp = () => {
      window.scroll(0, document.getElementById('scrollTop'))
   }
   const [scroll, setScroll] = useState(0)

   const handleScroll = () => {
      setScroll(window.scrollY)
   }

   const { data: contact } = useGetContacts()

   useEffect(() => {
      window.addEventListener('scroll', handleScroll)
      return () => window.removeEventListener('scroll', handleScroll)
   }, [])
   return (
      <div className={classes.wrapper}>
         {contact?.phone && (
            <a href={`tel:${contact?.phone?.replace(/[^\d+]/g, '')}`} className={classes.call}>
               <img src={Phone} alt='' />
            </a>
         )}

         <div
            className={classes.toTop}
            style={{ opacity: scroll > 100 ? 1 : 0 }}
            onClick={() => scrollUp()}
         >
            <img src={Arrow} alt='' />
         </div>
      </div>
   )
}

export default MainCall
