import Crums from '../../common/breadCrums/Crums'
import classes from './RightsPage.module.scss'

const RightsPage = () => {
   const crumsData = [['/rights', 'Политика конфиденциальности']]

   return (
      <div className={classes.wrapper + ' container'}>
         <Crums data={crumsData} />
         <div className={classes.subTitle}>
            Мы ценим вашу конфиденциальность и стремимся защитить ваши персональные данные.
            Настоящая Политика Конфиденциальности объясняет, какие данные мы собираем, как мы их
            используем и какие у вас есть права.
         </div>

         <div className={classes.title}>1. Общие положения</div>
         <div className={classes.subTitle}>
            1.1. Настоящая Политика Конфиденциальности (далее "Политика") регулирует обработку и
            защиту персональных данных пользователей (далее "Пользователь") веб-сайта (далее
            "Сайт").
         </div>
         <div className={classes.subTitle}>
            1.2. Персональные данные обрабатываются в соответствии с требованиями законодательства о
            защите персональных данных.
         </div>
         <div className={classes.title}>2. Сбор персональных данных</div>
         <div className={classes.subTitle}>2.1. Мы собираем следующие данные:</div>
         <ul className={classes.ulMargin}>
            <li>Имя и фамилия</li>
            <li>Контактный номер телефона</li>
            <li>Адрес электронной почты</li>
            <li>Почтовый адрес для доставки</li>
            <li>Информация о заказах</li>
         </ul>
         <div className={classes.subTitle}>
            2.2. Мы можем также собирать данные, автоматически передаваемые сайтом в процессе его
            использования:
         </div>
         <ul className={classes.ulMargin}>
            <li>IP-адрес</li>
            <li>Информация из cookies</li>
            <li>Информация о браузере (или иной программе, которая осуществляет доступ к сайту)</li>
            <li>Время доступа</li>
            <li>Адрес страницы, на которой расположен рекламный блок</li>
            <li>Реферер (адрес предыдущей страницы)</li>
         </ul>
         <div className={classes.title}>3. Использование персональных данных</div>
         <div className={classes.subTitle}>3.1. Мы используем собранные данные для:</div>
         <ul className={classes.ulMargin}>
            <li>Обработки и выполнения ваших заказов</li>
            <li>Обеспечения работы сайта и его улучшения</li>
            <li>Связи с вами по вопросам, связанным с предоставлением услуг</li>
            <li>Информирования вас о новостях, специальных предложениях и рекламных акциях</li>
            <li>Анализа и исследования пользовательского поведения на сайте</li>
         </ul>
         <div className={classes.title}>4. Передача персональных данных</div>
         <div className={classes.subTitle}>
            4.1. Мы не передаем ваши персональные данные третьим лицам, за исключением случаев:
         </div>
         <ul className={classes.ulMargin}>
            <li>
               Когда это необходимо для выполнения заказа (например, передача данных курьерской
               службе)
            </li>
            <li>
               По запросу уполномоченных государственных органов в порядке, установленном
               законодательством
            </li>
            <li>Для защиты наших прав и законных интересов</li>
         </ul>
         <div className={classes.title}>5. Защита персональных данных</div>
         <div className={classes.subTitle}>
            5.1. Мы принимаем необходимые и достаточные организационные и технические меры для
            защиты персональных данных Пользователей от неправомерного или случайного доступа,
            уничтожения, изменения, блокирования, копирования, распространения, а также от иных
            неправомерных действий третьих лиц.
         </div>
         <div className={classes.title}>6. Права Пользователей</div>
         <div className={classes.subTitle}>6.1. Пользователь имеет право:</div>
         <ul className={classes.ulMargin}>
            <li>Получать информацию о своих персональных данных и об их обработке</li>
            <li>
               Требовать уточнения, блокирования или уничтожения своих персональных данных в случае,
               если они являются неполными, устаревшими, неточными, незаконно полученными или не
               являются необходимыми для заявленной цели обработки
            </li>
            <li>Отозвать свое согласие на обработку персональных данных</li>
            <li>
               Обжаловать действия или бездействие компании в уполномоченный орган по защите прав
               субъектов персональных данных
            </li>
         </ul>
         <div className={classes.title}>7. Использование файлов cookie</div>
         <div className={classes.subTitle}>
            7.1. Наш сайт использует файлы cookie для улучшения функциональности и удобства
            пользования сайтом. Пользователь может настроить параметры использования файлов cookie в
            настройках своего браузера.
         </div>
         <div className={classes.title}>8. Изменения в Политике Конфиденциальности</div>
         <div className={classes.subTitle}>
            8.1. Мы оставляем за собой право вносить изменения в данную Политику в любое время.
            Изменения вступают в силу с момента их публикации на сайте.
         </div>
         <div className={classes.subTitle}>
            8.2. Рекомендуем регулярно проверять Политику на предмет изменений.
         </div>
         <div className={classes.title}>9. Контактная информация</div>
         <div className={classes.subTitle}>
            9.1. Если у вас возникли вопросы или предложения относительно данной Политики,
            пожалуйста, свяжитесь с нами по следующим контактам:
         </div>
         <ul className={classes.ulMargin}>
            <li>Электронная почта: info@imine.ru</li>
         </ul>
      </div>
   )
}

export default RightsPage
