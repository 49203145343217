import { Link } from 'react-router-dom'
import placeholder from '../../../../accests/image/callbackBaner.png'
import classes from './DataCenterItem.module.scss'
import price from '../../../../accests/image/dc.svg'
import dashbord from '../../../../accests/image/dashboard_dc.svg'
import server from '../../../../accests/image/box_dc.svg'
import { imageUrl } from '../../../config/config'

const DataCenterItem = ({ energyPrice, name, inStock, image, amount, id, activate, isActive }) => {
   function altNames() {
      return Math.random().toString(16).slice(2, 8)
   }
   return (
      <div
         className={isActive === id ? classes.sliderItemActive : classes.sliderItem}
         onClick={() => {
            activate()
         }}
      >
         <div className={classes.dataCenterImg} style={{ backgroundImage: `url('${image === null || image === undefined ? placeholder : imageUrl + image[0]}')` }}>
            <img src="" alt={name + "-" + altNames()} />
         </div>
         <div className={classes.dataCenterInfoBlock}>
            <p className={classes.dataCenterName}>{name}</p>
            <ul className={classes.dataCenterList}>
               <li>
                  <img src={price} alt={altNames()} />
                  <span>Цена за </span>кВт/ч - {energyPrice} р.
               </li>
               <li>
                  <img src={dashbord} alt={altNames()} />
                  <span>Свободные места: </span>
                  <span className={inStock ? classes.have : classes.hvnt}>
                     {inStock ? 'Есть' : 'Нет'}
                  </span>
               </li>
               <li>
                  <img src={server} alt={altNames()} />
                  <span>Мин кол-во: </span>От {amount}шт.
               </li>
            </ul>
            <Link to={`/data-centers/${id}`} className={classes.moarButton}>
               Подробнее
            </Link>
         </div>
      </div>
   )
}

export default DataCenterItem
