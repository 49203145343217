import classes from './CurrentCompanyRate.module.scss'
import LongArrow from '../../../../../accests/image/longArrow.svg'
import ShortArrow from '../../../../../accests/image/shortArrow.svg'
import { cursesFormatingNumber } from '../../../comFn'

export const CurrentCompanyRate = ({ name, rate, direction, image, value }) => {
   return (
      <div className={classes.wrapper}>
         <img src={image} alt={name} />
         <p className={classes.text}>{name}:</p>
         <p className={classes.value}>${cursesFormatingNumber(+value.toFixed(3))}</p>
         <img
            className={classes.arrow}
            src={direction === 'long' ? `${LongArrow}` : `${ShortArrow}`}
            alt=''
         />
         <span
            className={classes.rate}
            style={{ color: direction === 'long' ? '#00A84D' : '#BF0F0F' }}
         >
            {rate}%
         </span>
      </div>
   )
}
