import classes from './VerticalCatalogItem.module.scss'
import BitmanItemImage from '../../../../accests/image/bitmanImage.png'
import ShopIcon from '../../../../accests/image/shoppMenuIcon.svg'
import ComparisonIcon from '../../../../accests/image/comparison.svg'
import Button from '../../catalogWrapper/Button/Button'
import { text } from '../../../../translate'
import {
   addRemoveCompareById,
   addRemoveVisitedById,
   formatingNumber,
   toKebabCase,
} from '../../comFn'
import { imageUrl } from '../../../config/config'
import { useNavigate } from 'react-router-dom'

import ButtonFavorite from '../../catalogWrapper/ButtonFavorite/ButtonFavorite'

const VerticalCatalogItem = ({
   title,
   hashrate,
   coins,
   hit,
   is_new,
   price,
   total_price,
   id,
   algorithm,
   discount,
   photo_urls,
   is_in_stock,
   hashrate_um,
   tags,
}) => {
   const lang = 'ru'

   const handleClickLink = () => {
      window.scroll(0, 0)
      addRemoveVisitedById(id)
      navigate(`/asic-miners/${toKebabCase(title)}`)
   }

   const navigate = useNavigate()
   return (
      <div className={classes.wrapper}>
         <div className={classes.mainImage} onClick={() => handleClickLink()}>
            <img
               src={!!photo_urls?.length ? `${imageUrl}${photo_urls[0]}` : BitmanItemImage}
               alt=''
            />
         </div>
         <div className={classes.contentWrapper}>
            <div className={classes.description} onClick={() => handleClickLink()}>
               <div className={classes.name}>{title}</div>
               <div className={classes.labels}>
                  {!!is_in_stock && (
                     <div className={classes.hit}>
                        {text[lang].mainPage.catalogItem.is_in_stock}
                     </div>
                  )}
                  {!!is_new && (
                     <div className={classes.sallary}>{text[lang].mainPage.catalogItem.isNew}</div>
                  )}
                  {!!hit && (
                     <div className={classes.newItem}>{text[lang].mainPage.catalogItem.hit}</div>
                  )}
                  {tags?.map((tag) => (
                     <div
                        className={classes.otherTag}
                        key={tag.id}
                        style={{
                           color: tag.color ?? '#80899c',
                           border: `1px solid ${tag.color ?? '#80899c'}`,
                        }}
                     >
                        {tag?.value}
                     </div>
                  ))}
               </div>
               <div className={classes.descrWrapper}>
                  <div className={classes.descriptionValue}>
                     {text[lang].mainPage.catalogItem.hashRate}:
                     <span>
                        {hashrate} {hashrate_um?.value ?? ''}
                     </span>
                  </div>
                  <div className={classes.descriptionValue}>
                     {text[lang].mainPage.catalogItem.algorytm}:
                     <span>{algorithm?.value || ''}</span>
                  </div>
                  <div className={classes.descriptionValue}>
                     {text[lang].mainPage.catalogItem.coins}:
                     <span>{coins?.map((item) => item.value).join('/')}</span>
                  </div>
               </div>
            </div>
            <div className={classes.activeBlockWrapper}>
               <div className={classes.pricesWrapper}>
                  <div className={classes.discountWrapper}>
                     {!!discount && (
                        <>
                           <div className={classes.price}>{formatingNumber(price)}</div>
                           <div className={classes.salleryPrice}>
                              -{formatingNumber(discount)} ₽
                           </div>
                        </>
                     )}
                  </div>
                  <div className={classes.total_price}>{formatingNumber(total_price)} ₽</div>
               </div>
               <div className={classes.activeBlock}>
                  <Button itemId={id} photo={photo_urls} name={title}>
                     <img src={ShopIcon} alt='shopIcon' /> В корзину
                  </Button>
                  <div
                     className={`${classes.labelActive} ${
                        localStorage
                           .getItem('compareArr')
                           ?.split(',')
                           .filter((item) => +item === +id).length
                           ? classes.compareActive
                           : ''
                     }`}
                     onClick={() => addRemoveCompareById(id)}
                  >
                     <img src={ComparisonIcon} alt='ComparisonIcon' />
                  </div>
                  {/* <div className={classes.labelActive}>
                     <img src={FavoritesIcon} alt='FavoritesIcon' />
                  </div> */}
                  <ButtonFavorite itemId={id} />
               </div>
            </div>
         </div>
      </div>
   )
}

export default VerticalCatalogItem
