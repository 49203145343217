import { text } from '../../../translate'
import classes from './AboutInfo.module.scss'
import zero5 from '../../../accests/image/zero5Image.svg'
import zero3 from '../../../accests/image/zero3Image.svg'

const AboutInfo = () => {
   const lang = 'ru'
   return (
      <div className={classes.wrapper + ' container'}>
         <div className={classes.infoBlock}>
            <div className={classes.numberBlock}>
               <h3>2</h3>
               <img className={classes.zero5} src={zero5} alt='zero5' />
            </div>
            <p>{text[lang].mainPage.aboutInfo.text1}</p>
         </div>

         <div className={classes.infoBlock}>
            <div className={classes.numberBlock}>
               <h3>5</h3>
               <img className={classes.zero3} src={zero3} alt='zero3' />
            </div>
            <p>{text[lang].mainPage.aboutInfo.text2}</p>
         </div>

         <div className={classes.infoBlock}>
            <div className={classes.numberBlock}>
               <h3>5</h3>
            </div>
            <p>{text[lang].mainPage.aboutInfo.text3}</p>
         </div>
      </div>
   )
}

export default AboutInfo
