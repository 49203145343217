import classes from './DataCenter.module.scss'
import Baner from '../../../accests/image/banerDC.png'
import сhatty from '../../../accests/image/chatty.png'
import tools from '../../../accests/image/tools.png'
import lock from '../../../accests/image/lock.png'
import light from '../../../accests/image/light.png'

import Crums from '../breadCrums/Crums'
import DataCenterSlider from './DataCenterSlider'

const DataCenter = () => {
   const dataCrums = [['data-centers', 'Дата-центры']]
   const fakeData = [
      {
         title: 'Охрана территории',
         content: ' Все локации оборудованы системами видеонаблюдения и охраняются 24/7',
         image: lock,
      },
      {
         title: 'Регулярное Тех-Обслуживание',
         content: 'Ежедневно контролируем состояние вашего оборудования',
         image: tools,
      },
      {
         title: '«Белое» электричество ',
         content: 'Полностью легальное и прозрачное электричество с НДС',
         image: light,
      },
      {
         title: 'Тех-поддержка',
         content: 'Наши специалисты всегда готовы вам помочь',
         image: сhatty,
      },
   ]
   function altNames() {
      return Math.random().toString(16).slice(2, 8)
   }

   return (
      <div className={classes.dataCenter + ' container'}>
         <Crums data={dataCrums} />
         <div className={classes.dataCenterBaner} style={{ backgroundImage: `url(${Baner})` }}>
            {/* <img src={Baner} alt={altNames()} /> */}
            <div className={classes.bannerContent}>
               <h3>Инвестируйте и размещайте свое майнинг оборудование вместе с IMINE</h3>
               <p>Безопасность, стабильность и надежность ваших инвестиций</p>
               <a href='https://t.me/iminesalesbot' className={classes.bannerButton}>
                  Получить консультацию
               </a>
            </div>
         </div>
         <DataCenterSlider />
         <div className={classes.dataCenterOptions}>
            <div className={classes.dataCenterOptionsContainer}>
               <div className={classes.dataCentersContainer}>
                  <h3 className={classes.dataCentersContentTitle}>Что мы предлагаем ?</h3>
                  <div className={classes.dataCenterContentWrapper}>
                     {fakeData.map((element, index) => {
                        return (
                           <div className={classes.dataCenterElement} key={index}>
                              <div className={classes.dataCenterElementInfo}>
                                 <h4>{element.title}</h4>
                                 <p>{element.content}</p>
                              </div>
                              <img src={element.image} alt={altNames()} />
                           </div>
                        )
                     })}
                  </div>
               </div>
            </div>
         </div>
      </div>
   )
}

export default DataCenter
