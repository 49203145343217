import { useState, useEffect } from 'react'
import classes from './DataCenterSlider.module.scss'
import axios from 'axios'
import { baseUrl } from '../../config/config'

import DataCenterItem from './dataCenterItems/DataCenterItem'

const DataCenterSlider = () => {
   const [dataCenters, setDataCenters] = useState([])
   const [activeCenter, setActiveCenter] = useState(null)

   function activateCenter(value) {
      setActiveCenter(value)
   }
   useEffect(() => {
      axios.get(
         baseUrl + "/datacenters/?limit=100&offset=0"
      ).then(respons => {
         setDataCenters(respons.data)
      }).catch(error => {
         console.error(error.status, error.statusText)
      })
   }, [])
   return (
      <div className={classes.dataCenterSlider}>
         <h3>Дата-центры</h3>
         <div className={classes.dataCenterSliderElements}>
            <div>
               {dataCenters.map((element) => {
                  return (
                     <DataCenterItem
                        key={element.id}
                        id={element.id}
                        energyPrice={element.price}
                        name={element.location}
                        inStock={element.slots_available >= element.slots_occupied ? true : false}
                        image={element.photo_urls}
                        amount={element.slots_min}
                        activate={() => activateCenter(element.id)} isActive={activeCenter}
                     />
                  )
               })}
            </div>
         </div>
      </div>
   )
}

export default DataCenterSlider
