import { useState } from 'react'
import classes from './SidePopup.module.scss'
import { createPortal } from 'react-dom'

const SidePopup = ({ children, dataState = false }) => {
   const [state, setState] = useState(dataState)
   return createPortal(
      state ? (
         <div className= {classes.sidePopup }>{children}</div>
      ) : null,
      document.getElementById('root'),
   )
}

export default SidePopup
