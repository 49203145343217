import { text } from '../../../translate'
import classes from './Advantages.module.scss'

const Advantages = () => {
   const lang = 'ru'
   return (
      <div className={classes.wrapper + ' container'}>
         <div className={classes.title}>{text[lang].mainPage.advantages}</div>
         <div className={classes.itemsWrapper}>
            <div className={classes.item}>
               <div className={classes.iconSvg}>
                  <svg
                     width='48'
                     height='48'
                     viewBox='0 0 48 48'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                  >
                     <path
                        d='M35.2 10.6205L23.9 4.54055C22.7 3.90055 21.28 3.90055 20.08 4.54055L8.79996 10.6205C7.97996 11.0805 7.45996 11.9605 7.45996 12.9205C7.45996 13.9005 7.95996 14.7805 8.79996 15.2205L20.1 21.3005C20.7 21.6205 21.36 21.7805 22 21.7805C22.64 21.7805 23.32 21.6205 23.9 21.3005L35.2 15.2205C36.02 14.7805 36.54 13.9005 36.54 12.9205C36.54 11.9605 36.02 11.0805 35.2 10.6205Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M18.24 23.4212L7.74 18.1812C6.92 17.7612 6 17.8212 5.22 18.2812C4.46 18.7612 4 19.5812 4 20.4812V30.4012C4 32.1212 4.96 33.6612 6.5 34.4412L17 39.6812C17.36 39.8612 17.76 39.9612 18.16 39.9612C18.62 39.9612 19.1 39.8212 19.52 39.5812C20.28 39.1012 20.74 38.2812 20.74 37.3812V27.4612C20.72 25.7412 19.76 24.2012 18.24 23.4212Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M40.0003 20.4812V25.4012C39.0403 25.1212 38.0203 25.0012 37.0003 25.0012C34.2803 25.0012 31.6203 25.9412 29.5203 27.6212C26.6403 29.8812 25.0003 33.3012 25.0003 37.0012C25.0003 37.9812 25.1203 38.9612 25.3803 39.9012C25.0803 39.8612 24.7803 39.7412 24.5003 39.5612C23.7403 39.1012 23.2803 38.2812 23.2803 37.3812V27.4612C23.2803 25.7412 24.2403 24.2012 25.7603 23.4212L36.2603 18.1812C37.0803 17.7612 38.0003 17.8212 38.7803 18.2812C39.5403 18.7612 40.0003 19.5812 40.0003 20.4812Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M43.96 31.34C42.32 29.32 39.82 28.04 37 28.04C34.88 28.04 32.92 28.78 31.38 30.02C29.3 31.66 28 34.2 28 37.04C28 38.72 28.48 40.32 29.3 41.68C29.84 42.58 30.52 43.36 31.32 44H31.34C32.88 45.28 34.86 46.04 37 46.04C39.28 46.04 41.34 45.2 42.92 43.8C43.62 43.2 44.22 42.48 44.7 41.68C45.52 40.32 46 38.72 46 37.04C46 34.88 45.24 32.88 43.96 31.34ZM41.52 35.92L36.72 40.36C36.44 40.62 36.06 40.76 35.7 40.76C35.32 40.76 34.94 40.62 34.64 40.32L32.42 38.1C31.84 37.52 31.84 36.56 32.42 35.98C33 35.4 33.96 35.4 34.54 35.98L35.74 37.18L39.48 33.72C40.08 33.16 41.04 33.2 41.6 33.8C42.18 34.42 42.14 35.36 41.52 35.92Z'
                        fill='#ECD06C'
                     />
                  </svg>
               </div>

               <div className={classes.text}>{text[lang].mainPage.description.text1}</div>
            </div>
            <div className={classes.item}>
               <div className={classes.iconSvg}>
                  <svg
                     width='32'
                     height='32'
                     viewBox='0 0 32 32'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                     className={classes.sizeIcon}
                  >
                     <path
                        d='M24.7201 5.49378L17.3867 2.74711C16.6267 2.46711 15.3867 2.46711 14.6267 2.74711L7.2934 5.49378C5.88006 6.02711 4.7334 7.68044 4.7334 9.18711V19.9871C4.7334 21.0671 5.44007 22.4938 6.30673 23.1338L13.6401 28.6138C14.9334 29.5871 17.0534 29.5871 18.3467 28.6138L25.6801 23.1338C26.5467 22.4804 27.2534 21.0671 27.2534 19.9871V9.18711C27.2667 7.68044 26.1201 6.02711 24.7201 5.49378ZM20.6401 12.9604L14.9067 18.6938C14.7067 18.8938 14.4534 18.9871 14.2001 18.9871C13.9467 18.9871 13.6934 18.8938 13.4934 18.6938L11.3601 16.5338C10.9734 16.1471 10.9734 15.5071 11.3601 15.1204C11.7467 14.7338 12.3867 14.7338 12.7734 15.1204L14.2134 16.5604L19.2401 11.5338C19.6267 11.1471 20.2667 11.1471 20.6534 11.5338C21.0401 11.9204 21.0401 12.5738 20.6401 12.9604Z'
                        fill='#ECD06C'
                     />
                  </svg>
               </div>

               <div className={classes.text}>{text[lang].mainPage.description.text2}</div>
            </div>
            <div className={classes.item}>
               <div className={classes.iconSvg}>
                  <svg
                     width='32'
                     height='32'
                     viewBox='0 0 32 32'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                     className={classes.sizeIcon}
                  >
                     <path
                        d='M24.6265 22.4403L25.1465 26.6537C25.2799 27.7603 24.0932 28.5337 23.1465 27.9603L18.5332 25.2137C18.2132 25.027 18.1332 24.627 18.3065 24.307C18.9732 23.0803 19.3332 21.6937 19.3332 20.307C19.3332 15.427 15.1465 11.4537 9.99986 11.4537C8.94653 11.4537 7.91986 11.6137 6.95986 11.9337C6.46652 12.0937 5.98653 11.6403 6.10653 11.1337C7.31986 6.28033 11.9865 2.66699 17.5599 2.66699C24.0665 2.66699 29.3332 7.58699 29.3332 13.6537C29.3332 17.2537 27.4799 20.4403 24.6265 22.4403Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M17.3337 20.3065C17.3337 21.8931 16.747 23.3598 15.7603 24.5198C14.4403 26.1198 12.347 27.1465 10.0003 27.1465L6.52033 29.2131C5.93366 29.5731 5.18699 29.0798 5.26699 28.3998L5.60033 25.7731C3.81366 24.5331 2.66699 22.5465 2.66699 20.3065C2.66699 17.9598 3.92033 15.8931 5.84033 14.6665C7.02699 13.8931 8.45366 13.4531 10.0003 13.4531C14.0537 13.4531 17.3337 16.5198 17.3337 20.3065Z'
                        fill='#ECD06C'
                     />
                  </svg>
               </div>

               <div className={classes.text}>{text[lang].mainPage.description.text3}</div>
            </div>
            <div className={classes.item}>
               <div className={classes.iconSvg}>
                  <svg
                     width='32'
                     height='32'
                     viewBox='0 0 32 32'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                     className={classes.sizeIcon}
                  >
                     <path
                        d='M6.66634 20C5.30634 20 4.05301 20.52 3.10634 21.3733C2.89301 21.56 2.69301 21.76 2.51967 21.9867C1.77301 22.9067 1.33301 24.0667 1.33301 25.3333C1.33301 28.28 3.73301 30.6667 6.66634 30.6667C7.94634 30.6667 9.10634 30.2133 10.0263 29.48C10.773 28.8533 11.3597 28.04 11.6797 27.1067C11.893 26.56 11.9997 25.96 11.9997 25.3333C11.9997 22.4 9.61301 20 6.66634 20ZM9.86634 24.3067C9.86634 24.68 9.57301 24.96 9.21301 24.96C8.85301 24.96 8.55967 24.68 8.55967 24.3067V24.1733C8.55967 24.0267 8.45301 23.9067 8.30634 23.9067H5.63967C5.77301 24.16 5.74634 24.4667 5.53301 24.68C5.41301 24.8 5.25301 24.8667 5.06634 24.8667C4.90634 24.8667 4.73301 24.8 4.61301 24.68L3.65301 23.72C3.59967 23.6667 3.54634 23.5867 3.50634 23.5067C3.45301 23.3467 3.45301 23.1733 3.50634 23.0133C3.54634 22.9467 3.58634 22.8533 3.65301 22.8L4.61301 21.8533C4.86634 21.5867 5.27967 21.5867 5.51967 21.8533C5.73301 22.0533 5.75967 22.3733 5.62634 22.6133H8.30634C9.15967 22.6133 9.85301 23.3067 9.85301 24.1733V24.3067H9.86634ZM8.26634 29.0133C8.09301 29.0133 7.91967 28.96 7.79967 28.8267C7.58634 28.6267 7.55967 28.3067 7.69301 28.0667H5.01301C4.15967 28.0667 3.46634 27.3733 3.46634 26.5067V26.3733C3.46634 26 3.75967 25.72 4.11967 25.72C4.47967 25.72 4.77301 26 4.77301 26.3733V26.5067C4.77301 26.6533 4.87967 26.7733 5.02634 26.7733H7.70634C7.57301 26.52 7.59967 26.2133 7.81301 26C8.06634 25.7467 8.47967 25.7467 8.71967 26L9.67968 26.96C9.73301 27.0133 9.78634 27.0933 9.82634 27.1733C9.87967 27.3333 9.87967 27.5067 9.82634 27.6667C9.78634 27.7333 9.73301 27.8133 9.67968 27.8667L8.71967 28.8133C8.59967 28.9467 8.42634 29.0133 8.26634 29.0133Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M19.7997 5.26673V10.3334H17.7997V5.26673C17.7997 4.90673 17.4797 4.7334 17.2663 4.7334C17.1997 4.7334 17.133 4.74673 17.0663 4.7734L6.49301 8.76007C5.78634 9.02673 5.33301 9.6934 5.33301 10.4534V11.3467C4.11967 12.2534 3.33301 13.7067 3.33301 15.3467V10.4534C3.33301 8.86673 4.30634 7.4534 5.78634 6.8934L16.373 2.8934C16.6663 2.78673 16.973 2.7334 17.2663 2.7334C18.5997 2.7334 19.7997 3.8134 19.7997 5.26673Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M28.6663 19.3337V20.667C28.6663 21.027 28.3863 21.3203 28.0129 21.3337H26.0663C25.3596 21.3337 24.7196 20.8137 24.6663 20.1203C24.6263 19.707 24.7863 19.3203 25.0529 19.0537C25.2929 18.8003 25.6263 18.667 25.9863 18.667H27.9996C28.3863 18.6803 28.6663 18.9737 28.6663 19.3337Z'
                        fill='#ECD06C'
                     />
                     <path
                        d='M25.973 17.2663H27.333C28.0663 17.2663 28.6663 16.6663 28.6663 15.933V15.3463C28.6663 12.5863 26.413 10.333 23.653 10.333H8.34634C7.21301 10.333 6.17301 10.7063 5.33301 11.3463C4.11967 12.253 3.33301 13.7063 3.33301 15.3463V17.7197C3.33301 18.2263 3.86634 18.5463 4.34634 18.3863C5.09301 18.133 5.87967 17.9997 6.66634 17.9997C10.7063 17.9997 13.9997 21.293 13.9997 25.333C13.9997 26.293 13.7463 27.3463 13.3463 28.2797C13.133 28.7597 13.4663 29.333 13.9863 29.333H23.653C26.413 29.333 28.6663 27.0797 28.6663 24.3197V24.0663C28.6663 23.333 28.0663 22.733 27.333 22.733H26.173C24.893 22.733 23.6663 21.9463 23.333 20.7063C23.0663 19.693 23.3863 18.7063 24.053 18.0663C24.5463 17.5597 25.2263 17.2663 25.973 17.2663ZM18.6663 16.9997H11.9997C11.453 16.9997 10.9997 16.5463 10.9997 15.9997C10.9997 15.453 11.453 14.9997 11.9997 14.9997H18.6663C19.213 14.9997 19.6663 15.453 19.6663 15.9997C19.6663 16.5463 19.213 16.9997 18.6663 16.9997Z'
                        fill='#ECD06C'
                     />
                  </svg>
               </div>

               <div className={classes.text}>{text[lang].mainPage.description.text4}</div>
            </div>
         </div>
      </div>
   )
}

export default Advantages
