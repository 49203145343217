import React, { useContext } from 'react'
import classes from './SearchInputBlock.module.scss'
import Comparison from '../../../accests/image/comparison.svg'
import WhatsApp from '../../../accests/image/colorWhatsapp.svg'
import TG from '../../../accests/image/colorTG.svg'
import Phone from '../../../accests/image/phoneHeader.svg'
import ShoppCart from '../../../accests/image/shoppCart.svg'
import Logo from '../../../accests/image/Logo.svg'
import InputSearch from './inputSearch/InputSearch'
import { BucketContext, ProfileContext } from '../../../App'
import UserIcon from '../../../accests/image/userIconBlack.svg'
import { text } from '../../../translate'

import FavoriteIcon from './favoriteIcon/FavoriteIcon'
import AuthButton from '../catalogMenu/CatalogListItem/AuthButton'
import { useGetContacts } from '../../hooks/useGetContacts'

const SearchInputBlock = () => {
   const context = useContext(BucketContext)
   const contextProfile = useContext(ProfileContext)
   const lang = 'ru'
   const { data: contact } = useGetContacts()

   return (
      <>
         <div className={classes.wrapper + ' container'}>
            <a href='/'>
               <img className={classes.logo} src={Logo} alt='logo' id='logo' />
            </a>

            <InputSearch />
            <div className={classes.linksWrapper}>
               <a href='/compare' className={`${classes.comparePosition} ${classes.link}`}>
                  <img
                     className={classes.linkImg}
                     src={Comparison}
                     alt='comparison'
                     id={'scrollTop'}
                  />
                  <p className={classes.linkText}>{text[lang].header.category.comparisons}</p>
                  {!!localStorage.getItem('compareArr')?.split(',').length &&
                     localStorage.getItem('compareArr')?.split(',')[0] !== '' && (
                        <div className={classes.notificationCount}>
                           {localStorage.getItem('compareArr')?.split(',').length ?? []}
                        </div>
                     )}
               </a>
               {/* <a href='/favorites' className={`${classes.comparePosition} ${classes.link}`}>
                  <img className={classes.linkImg} src={Favorites} alt='favorites' />
                  <p className={classes.linkText}>{text[lang].header.category.favorites}</p>
                  {!!localStorage.getItem('favoritesArr')?.split(',').length &&
                     localStorage.getItem('favoritesArr')?.split(',')[0] !== '' && (
                        <div className={classes.notificationCount}>
                           {localStorage.getItem('favoritesArr')?.split(',').length ?? []}
                        </div>
                     )}
               </a> */}
               <FavoriteIcon />
               <a href='/cart' className={`${classes.comparePosition} ${classes.link}`}>
                  <img className={classes.linkImg} src={ShoppCart} alt='shoppCart' />
                  <p className={classes.linkText}>{text[lang].header.category.basket}</p>
                  {context.store.amountItems === 0 ||
                  localStorage.getItem('amountItems') === '0' ? null : localStorage.getItem(
                       'amountItems',
                    ) !== '0' ? (
                     <div className={classes.notificationCount}>
                        {localStorage.getItem('amountItems')}
                     </div>
                  ) : (
                     <div className={classes.notificationCount}>{context.store.amountItems}</div>
                  )}
               </a>
               <a
                  href={contact?.wa}
                  className={`${classes.bgCall} ${classes.comparePosition} ${classes.link}`}
                  target='_blank'
                  rel='noreferrer'
               >
                  <img className={classes.linkImg} src={WhatsApp} alt='WhatsApp' id={'scrollTop'} />
                  <p className={classes.linkText}>{text[lang].header.category.whatsapp}</p>
               </a>
               <a
                  href={contact?.telegram}
                  className={`${classes.bgCall} ${classes.comparePosition} ${classes.link}`}
                  target='_blank'
                  rel='noreferrer'
               >
                  <img className={classes.linkImg} src={TG} alt='Telegram' id={'scrollTop'} />
                  <p className={classes.linkText}>{text[lang].header.category.tg}</p>
               </a>
               {contact?.phone && (
                  <a
                     href={`tel:${contact?.phone?.replace(/[^\d+]/g, '')}`}
                     className={`${classes.bgCall} ${classes.comparePosition} ${classes.link}`}
                  >
                     <img className={classes.linkImg} src={Phone} alt='phone' id={'scrollTop'} />
                     <p className={classes.linkText}>{text[lang].header.category.call}</p>
                  </a>
               )}

               <AuthButton size={'laptop-mobile'}>
                  <span
                     onClick={() => {
                        contextProfile.storeDispatch({ type: 'popup_toggle', payload: true })
                     }}
                  >
                     {localStorage.getItem('authName') === '' ? (
                        <img src={UserIcon} alt='userBlack' />
                     ) : null}
                  </span>
               </AuthButton>
            </div>
         </div>

         {/* modile version */}

         <div className={classes.mobileWrapper}>
            <div className={classes.mobilePosBlock}>
               <a href='/'>
                  <img className={classes.logo} src={Logo} alt='logo' id='logo' />
               </a>
               <div className={classes.linksWrapper}>
                  <a href='/compare' className={`${classes.comparePosition} ${classes.link}`}>
                     <img src={Comparison} alt='comparison' id={'scrollTop'} />
                     {!!localStorage.getItem('compareArr')?.split(',').length &&
                        localStorage.getItem('compareArr')?.split(',')[0] !== '' && (
                           <div className={classes.notificationCount}>
                              {localStorage.getItem('compareArr')?.split(',').length ?? []}
                           </div>
                        )}
                  </a>
                  <FavoriteIcon />
                  {/* <a href='/compare' className={classes.link}>
                     <img src={Favorites} alt='favorites' />
                  </a> */}
                  {/* <a href='/cart' className={`${classes.comparePosition} ${classes.link}`}>
                     <img src={ShoppCart} alt='shoppCart' />
                     {context.store.amountItems === 0 ||
                        localStorage.getItem('amountItems') === '0' ? null : localStorage.getItem(
                           'amountItems',
                        ) !== '0' ? (
                        <div className={classes.notificationCount}>
                           {localStorage.getItem('amountItems')}
                        </div>
                     ) : (
                        <div className={classes.notificationCount}>{context.store.amountItems}</div>
                     )}
                  </a> */}
                  <a
                     href={contact?.wa}
                     className={`${classes.bgCall} ${classes.comparePosition} ${classes.link}`}
                     target='_blank'
                     rel='noreferrer'
                  >
                     <img
                        className={classes.linkImg}
                        src={WhatsApp}
                        alt='WhatsApp'
                        id={'scrollTop'}
                     />
                     <p className={classes.linkText}>{text[lang].header.category.whatsapp}</p>
                  </a>
                  <a
                     href={contact?.telegram}
                     className={`${classes.bgCall} ${classes.comparePosition} ${classes.link}`}
                     target='_blank'
                     rel='noreferrer'
                  >
                     <img className={classes.linkImg} src={TG} alt='Telegram' id={'scrollTop'} />
                     <p className={classes.linkText}>{text[lang].header.category.tg}</p>
                  </a>
                  {contact?.phone && (
                     <a
                        href={`tel:${contact?.phone?.replace(/[^\d+]/g, '')}`}
                        className={`${classes.bgCall} ${classes.comparePosition} ${classes.link}`}
                     >
                        <img className={classes.linkImg} src={Phone} alt='phone' id={'scrollTop'} />
                        <p className={classes.linkText}>{text[lang].header.category.call}</p>
                     </a>
                  )}

                  <AuthButton size={'laptop-mobile'}>
                     <span
                        onClick={() => {
                           contextProfile.storeDispatch({ type: 'popup_toggle', payload: true })
                        }}
                     >
                        {localStorage.getItem('authName') === '' ? (
                           <img src={UserIcon} alt='userBlack' />
                        ) : null}
                     </span>
                  </AuthButton>
               </div>
            </div>
            <hr color='#E2E4E9' />
            <InputSearch />
         </div>
      </>
   )
}

export default SearchInputBlock
