import React, { useEffect, useState } from 'react'
import classes from './Newsfp.module.scss'
import NewsFPitem from './newsFPitem/newsPFitem'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { baseUrl } from '../../config/config'

const NewsFP = () => {
   const navigate = useNavigate()
   const [postData, setPostData] = useState(null)

   function scrollUp() {
      window.scroll(0, document.getElementById('newsPage'))
   }
   useEffect(() => {
      axios
         .get(`${baseUrl}/post/?limit=6&offset=0`)
         .then((response) => {
            const data = response.data
            setPostData(data)
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error)
         })
   }, [])

   return (
      <div className={classes.news + ' container'}>
         <div className={classes.newsHeader}>
            <h3>Новости</h3>
            <div
               className={classes.linkToNews}
               onClick={() => {
                  scrollUp()
                  navigate('/news')
               }}
            >
               Все новости
            </div>
         </div>
         <div className={classes.newsContent}>
            <div>
               {postData !== null
                  ? postData.map((element, index) => {
                       return <NewsFPitem key={index} data={element} />
                    })
                  : null}
            </div>
         </div>
      </div>
   )
}

export default NewsFP
