import OrderingComp from '../../common/ordering/Ordering'

const Ordering = () => {
   return (
      <>
         <OrderingComp />
      </>
   )
}

export default Ordering
