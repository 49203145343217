import classes from './FaqCol.module.scss'


const FaqCol = ({ data, selectedFaq, selectFaq }) => {
   return (
      <ul className={classes.faqColumn}>
         {data.map((element, index) => {
            return (
               <li key={index}>
                  <span onClick={() => selectFaq(element.id)} dangerouslySetInnerHTML={{ __html: element.question }}></span>
                  <span className={element.id === selectedFaq ? classes.active : null} dangerouslySetInnerHTML={{ __html: element.answer }}>
                  </span>
                  <span></span>
               </li>
            )
         })}
      </ul>
   )
}

export default FaqCol
