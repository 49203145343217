import classes from './ButtonFavorite.module.scss'
import { useContext, useEffect, useState } from 'react'
import FavoritesIcon from '../../../../accests/image/favorites.svg'
import ActiveFavoritesIcon from '../../../../accests/image/activeFavorites.svg'
import requared from '../../axiosIntercepter'
import { baseUrl } from '../../../config/config'

import { ProfileContext } from '../../../../App'

function ButtonFavorite({ itemId = 1, tooltip }) {
   const context = useContext(ProfileContext)
   const [activeFavoriteIcon, setActive] = useState(false)
   const [authName] = useState(localStorage.getItem('authName'))

   function favoriteToggleAdd() {
      if (authName !== '' && authName !== null) {
         requared
            .post(baseUrl + '/favorite', { asic_id: itemId })
            .then((response) => {
               setActive(true)
               context.storeDispatch({ type: 'renew', payload: itemId })
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.status, error.statusText)
            })
      } else {
         context.storeDispatch({
            type: 'popup_toggle',
            payload: true,
         })
      }
   }

   function favoriteToggleDelete() {
      if (authName !== '' && authName !== null) {
         requared
            .delete(baseUrl + '/favorite', { data: { asic_id: itemId } })
            .then((response) => {
               setActive(false)
               context.storeDispatch({
                  type: 'rerender',
                  payload: context.profileStore.favorite.filter((element) => element.id !== itemId),
               })
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.status, error.statusText)
            })
      } else {
         context.storeDispatch({
            type: 'popup_toggle',
            payload: true,
         })
      }
   }

   useEffect(() => {
      context.profileStore.favorite.map((element) =>
         element.id === itemId ? setActive(true) : null,
      )
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [context.profileStore.favorite])

   return (
      <>
         {activeFavoriteIcon === false ? (
            <div className={classes.labelActive} onClick={() => favoriteToggleAdd()}>
               <img src={FavoritesIcon} alt='FavoritesIcon' />
               {tooltip && <span>В избранное</span>}
            </div>
         ) : (
            <div className={classes.labelActive} onClick={() => favoriteToggleDelete()}>
               <img src={ActiveFavoritesIcon} alt='ActiveFavoritesIcon' />
               {tooltip && <span>В избранное</span>}
            </div>
         )}
      </>
   )
}

export default ButtonFavorite
