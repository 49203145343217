import { useEffect, useContext } from 'react'

import classes from './MiningWhy11.module.scss'
import bitcoinBlue from '../../../accests/image/imgMainMaining.png'
import checkIcon from '../../../accests/image/chckMark.svg'
import shieldIcon from '../../../accests/image/shieldMark.svg'
import walletIcon from '../../../accests/image/walletMark.svg'
import splash from '../../../accests/image/splashMark.svg'
import safeIcon from '../../../accests/image/safe.svg'
import coinIcon from '../../../accests/image/coin.svg'
import keyIcon from '../../../accests/image/key.svg'
import pickAxeIcon from '../../../accests/image/pickAxe.svg'

import { ProfileContext } from '../../../App'

import Crums from '../breadCrums/Crums'
import AboutInfo from '../../common/aboutInfo/AboutInfo'

const Mining = () => {
   const context = useContext(ProfileContext)
   const crumsData = [['mining-management', 'Майнинг под ключ']]
   const miningReasons = [
      {
         img: checkIcon,
         title: 'Майнинг это просто',
         content: 'Мы берём на себя все процессы, вам только нужно наблюдать за ростом прибыли',
      },
      {
         img: shieldIcon,
         title: 'Полная безопасность',
         content: 'Максимально надежное и защищенное решение для майнинга криптовалют',
      },
      {
         img: walletIcon,
         title: 'Низкий порог входа',
         content: 'С минимальным бюджетом и знаниями в криптовалютах и майнинге',
      },
   ]
   const miningWtf = [
      {
         title: 'Подбор и покупка оборудования',
         content: 'Наши специалисты подберут оптимальное оборудование  под ваш бюджет и цели',
      },
      {
         title: 'Подписание договора',
         content:
            'Мы соблюдаем прозрачность в работе и для обеспечения безопасности подписываем договор',
      },
      {
         title: 'Проверка оборудования',
         content:
            'Всё оборудование проверено и работает исправно, гарантируя надежность ваших инвестиций',
      },
      {
         title: 'Размещение и подключение',
         content:
            'Устанавливаем оборудование в нашем дата-центре с круглосуточной охраной и специалистами, которые подключат  и настроят ваши устройства',
      },
      {
         title: 'Обслуживание и поддержка',
         content:
            'Следим за состоянием оборудования 24/7. В случае возникших вопросов наша служба поддержки окажет вам помощь',
      },
      {
         title: 'Начисления и прибыль',
         content:
            'На ваш аккаунт поступают ежедневные начисления, благодаря чему вы будете всегда в курсе своих доходов',
      },
   ]
   const miningProfits = [
      { img: safeIcon, titel: 'Депозит', content: { profit: 3, entering: 100, risk: 5 } },
      { img: coinIcon, titel: 'Акции', content: { profit: 10, entering: 100, risk: 8 } },
      { img: keyIcon, titel: 'Недвижимость', content: { profit: 12, entering: 50000, risk: 2 } },
      { img: pickAxeIcon, titel: 'Майнинг', content: { profit: 65, entering: 1200, risk: 2 } },
   ]
   function slitter(value) {
      let processingArray = value.toString().split('').reverse()
      let readyArray = []
      processingArray.map((element, index) => {
         if (index % 3) {
            readyArray.push(element)
         } else {
            readyArray.push(element + ' ')
         }
      })
      return readyArray.reverse()
   }
   function altNames() {
      return Math.random().toString(16).slice(2, 8)
   }
   function classDepends(value) {
      switch (true) {
         case [3, 8].includes(value):
            return classes.bad
         case [100, 2, 1200, 65].includes(value):
            return classes.good
         case [5, 10, 12, 50000].includes(value):
            return classes.normal
         default:
            console.error('Coloring issue')
      }
   }
   function colorDepends(value) {
      switch (true) {
         case [5].includes(value):
            return classes.normalLine
         case [2].includes(value):
            return classes.goodLine
         case [8].includes(value):
            return classes.badLine
         default:
            console.error('Coloring issue')
      }
   }

   useEffect(() => {
      context.storeDispatch({ type: 'mining_manager', payload: 'mining' })
      return () => {
         context.storeDispatch({ type: 'regular' })
      }
   }, [])
   return (
      <div className={classes.miningMain + ' container'}>
         <Crums data={crumsData} />
         <div className={classes.miningBanner}>
            <img src={bitcoinBlue} alt={altNames()} />
            <div>
               <h3>Майнинг под ключ</h3>
               <p>Инвестируйте в майнинг вместе с нами</p>
               <a
                  href={'tg://resolve?domain=@iminesalesbot1'}
                  target='_blank'
                  rel='noopener noreferrer'
               >
                  Получить консультацию
               </a>
            </div>
         </div>
         <div className={classes.miningReasons}>
            {miningReasons.map((element, index) => {
               return (
                  <div key={index} className={classes.miningReasonsItem}>
                     <span>
                        <img src={element.img} alt={altNames()} />
                     </span>
                     <h4>{element.title}</h4>
                     <p>{element.content}</p>
                  </div>
               )
            })}
         </div>
         <div className={classes.miningWtf}>
            <h3 className={classes.miningWtfHeader}>Что такое “Майнинг под ключ”?</h3>
            <div className={classes.miningWtfLeftCol}>
               {miningWtf.slice(0, 3).map((element, index) => {
                  return (
                     <div className={classes.miningWtfItem} key={index}>
                        <p>{index + 1}</p>
                        <span>
                           <h4>{element.title}</h4>
                           <p>{element.content}</p>
                        </span>
                     </div>
                  )
               })}
            </div>
            <div className={classes.miningWtfRightCol}>
               {miningWtf.slice(3, 6).map((element, index) => {
                  return (
                     <div className={classes.miningWtfItem} key={index}>
                        <p>{index + 4}</p>
                        <span>
                           <h4>{element.title}</h4>
                           <p>{element.content}</p>
                        </span>
                     </div>
                  )
               })}
            </div>
         </div>
         <div className={classes.miningInvestingOComparis}>
            <h3>Майнинг инвестирование в сравнении</h3>
            <div className={classes.miningInvestingOComparisContent}>
               {miningProfits.map((element, index) => {
                  return (
                     <div className={classes.miningInvestingOComparisItem} key={index}>
                        <span className={classes.miningInvestingOComparisItemImg}>
                           <img src={splash} alt={altNames()} />
                           <img src={element.img} alt={altNames()} />
                        </span>
                        <h4>{element.titel}</h4>
                        <div className={classes.miningInvestingOComparisItemContent}>
                           <div className={classDepends(element.content.profit)}>
                              <p>Доходность</p>
                              <p>{element.content.profit}% годовых</p>
                           </div>
                           <div className={classDepends(element.content.entering)}>
                              <p>Минимальный вход</p>
                              <p>От ${slitter(element.content.entering)}</p>
                           </div>
                           <div className={classDepends(element.content.risk)}>
                              <p>Риск</p>
                              <p>
                                 <span>{element.content.risk}/10</span>
                                 <span>
                                    <span
                                       className={colorDepends(element.content.risk)}
                                       style={{ gridColumn: `1 / ${element.content.risk}` }}
                                    ></span>
                                 </span>
                              </p>
                           </div>
                        </div>
                     </div>
                  )
               })}
            </div>
         </div>
         <div className={classes.miningWhyUs}>
            <h3>Почему мы</h3>
            <AboutInfo />
         </div>
      </div>
   )
}

export default Mining
