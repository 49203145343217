import classes from './BucketPage.module.scss'
import { useState, useEffect } from 'react'
import { useGetAsics } from '../../hooks/useGetAsics'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import Crums from '../../common/breadCrums/Crums'
import BucketController from '../../common/bucket/BucketController'
import BucketItem from '../../common/bucket/BucketItem'
import BucketCalculator from '../../common/bucket/BucketCalculator'
import WatchBefore from '../../common/catalogWatchEarly/CatalogWatchEarly'

const BucketPage = () => {
   const bucketFormated = JSON.parse(localStorage.getItem('bucket'))
   const bucketItems = Object.keys(JSON.parse(localStorage.getItem('bucket'))).map(
      (element) => element * 1,
   )
   const { data: asicsData, isLoading } = useGetAsics()
   const processedData = asicsData.filter((element) => bucketItems.includes(element.id))
   const dataCrums = [['cart', 'Корзина']]
   const [selectingAllItems, setSelectingAllItems] = useState(false)
   const [unitsTotalAmount, setUnitsTotalAmount] = useState(0)
   const [allMoneyCalc, setAllMoneyCalc] = useState(0)
   const [selectedUnits, setSelectedUnits] = useState([])
   const [counter, setCounter] = useState(0)
   const setSelectingAll = () => {
      let array = []
      if (selectingAllItems === true) {
         setSelectedUnits(array)
      } else {
         asicsData.map((element) => {
            array.push(element.id)
         })
      }
      setSelectingAllItems(!selectingAllItems)
      setCounter(counter + 1)
      setSelectedUnits(array)
   }

   const selectElement = (valueId) => {
      let processedArray = selectedUnits
      if (processedArray.includes(valueId) !== true) {
         processedArray.push(valueId)
      } else {
         processedArray = processedArray.filter((element) => element !== valueId)
      }
      setSelectingAllItems(false)
      setSelectedUnits(processedArray)
      setCounter(counter + 1)
   }

   const endOf = (value) => {
      switch (true) {
         case value === 1:
            return value + ' товар'
         case [2, 3, 4].includes(value):
            return value + ' товара'
         default:
            return value + ' товаров'
      }
   }

   useEffect(() => {
      let startAmountofMoney = 0
      setAllMoneyCalc(
         processedData.reduce(
            (accumulate, element) => accumulate + bucketFormated[element.id] * element.total_price,
            startAmountofMoney,
         ),
      )
      setUnitsTotalAmount(JSON.parse(localStorage.getItem('amountItems')))
   }, [JSON.parse(localStorage.getItem('bucket')), counter])
   return (
      <>
         <div className={classes.cartPageMain + ' container'}>
            <Crums data={dataCrums} />
            <h3>
               <span> Корзина </span>
               <span>{endOf(JSON.parse(localStorage.getItem('amountItems')))}</span>
            </h3>
            <div className={classes.cartPageContent}>
               <div className={classes.cartPageContentLeftCol}>
                  <BucketController
                     state={selectingAllItems}
                     selectingAll={() => setSelectingAll()}
                     selectedItems={selectedUnits}
                  />
                  {isLoading === false ? (
                     processedData.map((element, index) => {
                        return (
                           <BucketItem
                              key={index}
                              data={element}
                              selecting={selectElement}
                              selected={selectedUnits.includes(element.id) ? true : false}
                           />
                        )
                     })
                  ) : (
                     <PageSpinner />
                  )}
               </div>
               <div className={classes.cartPageContentRightCol}>
                  <BucketCalculator units={unitsTotalAmount} totalMoney={allMoneyCalc} />
               </div>
            </div>
         </div>
         <WatchBefore name={'Смотрели ранее'} link='none' items={asicsData} />
      </>
   )
}

export default BucketPage
