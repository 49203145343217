import { useState } from 'react'
import Crums from '../../common/breadCrums/Crums'
import Switcher from '../../common/switcher/Switcher'
import classes from './ComparePage.module.scss'
import CompareItem from './compareItem/CompareItem'
import { useGetAsics } from '../../hooks/useGetAsics'
import { endOf } from '../asicMinersPage/AsicMinersPage'
import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper'
import { useNavigate } from 'react-router-dom'
import PageSpinner from '../../common/pageSpinner/PageSpinner'

const ComparePage = () => {
   const [activeSwitcher, setActiveSwitcher] = useState(false)
   const activeCompareArr = localStorage.getItem('compareArr')?.split(',') ?? []
   const { data: asicsData, isLoading } = useGetAsics()
   const navigate = useNavigate()

   const resultCompare = asicsData?.filter((item) => {
      for (const id of activeCompareArr) {
         if (item.id === +id) {
            return true
         }
      }
      return false
   })

   const differentAttributes = activeSwitcher ? getDifferentAttributes(resultCompare) : {}

   return (
      <div className={'container'}>
         <Crums data={[['compare', 'Сравнение товаров']]} />
         {isLoading ? (
            <PageSpinner />
         ) : (
            <>
               <div className={classes.title}>
                  Сравнение товаров <span>{endOf(resultCompare?.length)}</span>
               </div>

               <div className={classes.actionTable}>
                  <div
                     onClick={() => {
                        navigate('/asic-miners')
                        window.location.reload()
                     }}
                     className={classes.addItem}
                  >
                     <span>+</span>Добавить товары
                  </div>
                  <div className={classes.switcher}>
                     <p>Только отличия</p>
                     <Switcher active={activeSwitcher} setActive={setActiveSwitcher} />
                  </div>
               </div>
               {!!resultCompare?.length ? (
                  <>
                     <div className={classes.content}>
                        <div className={classes.itemsWrapper}>
                           {resultCompare.map((item) => (
                              <CompareItem
                                 key={item.id}
                                 {...item}
                                 differentAttributes={differentAttributes}
                                 activeSwitcher={activeSwitcher}
                              />
                           ))}
                        </div>
                     </div>
                  </>
               ) : (
                  <div className={classes.noContent}>
                     <p>
                        Выберете предметы на странице{' '}
                        <span
                           onClick={() => {
                              navigate('/asic-miners')
                              window.location.reload()
                           }}
                        >
                           Asic-майнеров
                        </span>{' '}
                        для сравнения
                     </p>
                  </div>
               )}
            </>
         )}
         <CatalogWrapper name={'Смотрели ранее'} link='none' items={asicsData} />
      </div>
   )
}

const getDifferentAttributes = (items) => {
   const attributes = ['hashrate', 'total_price', 'brand', 'coins', 'consumption', 'algorithm']

   const differentAttributes = {}

   attributes.forEach((attr) => {
      const values = items.map((item) => JSON.stringify(item[attr]))
      const allEqual = values.every((value) => value === values[0])

      if (!allEqual) {
         differentAttributes[attr] = true
      }
   })

   return differentAttributes
}

export default ComparePage
