import Crums from '../../common/breadCrums/Crums'
import classes from './TermsPage.module.scss'

const TermsPage = () => {
   const crumsData = [['/terms', 'Пользовательское соглашение']]

   return (
      <div className={classes.wrapper + ' container'}>
         <Crums data={crumsData} />
         <div className={classes.subTitle}>
            Добро пожаловать на наш сайт! Пожалуйста, внимательно прочитайте данное Пользовательское
            соглашение перед использованием нашего веб-сайта. Заходя на наш сайт, вы соглашаетесь
            соблюдать и быть связанными следующими условиями и положениями.
         </div>

         <div className={classes.title}>1. Общие положения</div>
         <div className={classes.subTitle}>
            1.1. Настоящее соглашение регулирует отношения между владельцем сайта (далее "Компания")
            и пользователем (далее "Пользователь").
         </div>
         <div className={classes.subTitle}>
            1.2. Пользовательское соглашение вступает в силу с момента первого использования сайта
            Пользователем.
         </div>
         <div className={classes.subTitle}>
            1.3. Компания оставляет за собой право вносить изменения в данное соглашение без
            предварительного уведомления Пользователя.
         </div>
         <div className={classes.title}>2. Использование сайта</div>
         <div className={classes.subTitle}>
            2.1. Сайт предоставляет Пользователю возможность приобретения майнинг-оборудования.
         </div>
         <div className={classes.subTitle}>
            2.2. Для использования некоторых функций сайта может потребоваться регистрация и
            создание учетной записи.
         </div>
         <div className={classes.subTitle}>
            2.3. Пользователь обязуется предоставлять точную и актуальную информацию при регистрации
            и поддерживать её в актуальном состоянии.
         </div>
         <div className={classes.title}>3. Обязательства Пользователя</div>
         <div className={classes.subTitle}>
            3.1. Пользователь обязуется не использовать сайт для незаконных целей или деятельности.
         </div>
         <div className={classes.subTitle}>
            3.2. Пользователь обязуется не нарушать права интеллектуальной собственности Компании и
            третьих лиц.
         </div>
         <div className={classes.subTitle}>
            3.3. Пользователь обязуется соблюдать все применимые законы и нормативные акты при
            использовании сайта.
         </div>
         <div className={classes.title}>4. Обязательства Компании</div>
         <div className={classes.subTitle}>
            4.1. Компания обязуется обеспечивать работу сайта в пределах разумной доступности и
            надежности.
         </div>
         <div className={classes.subTitle}>
            4.2. Компания не несет ответственности за временные технические сбои и перерывы в работе
            сайта, вызванные техническими причинами.
         </div>
         <div className={classes.title}>5. Конфиденциальность</div>
         <div className={classes.subTitle}>
            5.1. Компания обязуется соблюдать конфиденциальность информации, предоставленной
            Пользователем, и использовать её исключительно в целях выполнения настоящего соглашения.
         </div>
         <div className={classes.subTitle}>
            5.2. Компания обязуется не передавать личную информацию Пользователя третьим лицам без
            его согласия, за исключением случаев, предусмотренных законом.
         </div>
         <div className={classes.title}>6. Отказ от ответственности</div>
         <div className={classes.subTitle}>
            6.1. Компания не несет ответственности за убытки, возникшие в результате использования
            или невозможности использования сайта.
         </div>
         <div className={classes.subTitle}>
            6.2. Компания не несет ответственности за достоверность и актуальность информации,
            предоставленной на сайте.
         </div>
         <div className={classes.title}>7. Разрешение споров</div>
         <div className={classes.subTitle}>
            7.1. В случае возникновения споров и разногласий, связанных с исполнением настоящего
            соглашения, стороны обязуются решить их путем переговоров.
         </div>
         <div className={classes.subTitle}>
            7.2. В случае невозможности разрешения споров путем переговоров, споры подлежат
            рассмотрению в судебном порядке по месту нахождения Компании.
         </div>
         <div className={classes.title}>8. Заключительные положения</div>
         <div className={classes.subTitle}>
            8.1. Настоящее соглашение регулируется и толкуется в соответствии с законодательством.
         </div>
         <div className={classes.subTitle}>
            8.2. Если какое-либо положение настоящего соглашения будет признано недействительным или
            неисполнимым, остальные положения останутся в силе и будут обязательными для сторон.
         </div>
         <div className={classes.subTitle}>
            Спасибо за то, что выбрали наш магазин! Если у вас возникнут вопросы или предложения,
            пожалуйста, свяжитесь с нами.
         </div>
      </div>
   )
}

export default TermsPage
