import { text } from '../../../translate'
import FilterItem from './FilterItem/FilterItem'
import classes from './FilterTags.module.scss'

const lang = 'ru'

const FilterTags = ({ active, setActive }) => {
   const filterArr = [
      {
         name: text[lang].asicMiners.sorting.sortBTC,
         art: 'BTC',
      },
      {
         name: text[lang].asicMiners.sorting.sortLTC,
         art: 'LTC',
      },
      {
         name: text[lang].asicMiners.sorting.sortPower,
         art: 'Power',
      },
      {
         name: text[lang].asicMiners.sorting.sortFast,
         art: 'Fast',
      },
      {
         name: text[lang].asicMiners.sorting.sortMoney,
         art: 'Money',
      },
      // {
      //    name: text[lang].asicMiners.sorting.sortComment,
      //    art: 'Comment',
      // },
   ]
   return (
      <div className={classes.wrapper}>
         {filterArr.map((item) => (
            <FilterItem
               key={item.name}
               setActive={setActive}
               art={item.art}
               name={item.name}
               active={active === item.art}
            />
         ))}
      </div>
   )
}

export default FilterTags
