import classes from './ProfileOrdersPending.module.scss'
// import imageAsic from '../../../accests/image/asic_miner-01.png'
// import requared from '../axiosIntercepter'
import { ProfileContext } from '../../../App'
import cross from "../../../accests/image/crossIcon.svg"
import { baseUrl, imageUrl } from '../../config/config'

import axios from 'axios'
import { useEffect, useState, useContext } from 'react'

const ProfileOrderPending = ({ active }) => {
   const [orderData, setOrderData] = useState()
   const context = useContext(ProfileContext)
   const [video, setVideo] = useState(null)
   function formatingOrder(value) {
      switch (value) {
         case -1:
            return 'Отменен'
         case 0:
            return 'Ожидает подтверждения'
         case 1:
            return 'Подтвержден'
         case 2:
            return 'Ожидает отгрузки'
         case 3:
            return 'Завершен'
         default:
            console.error('Error in orders')
      }
   }
   function dateFormating(value) {
      let date = value.slice(0, 10).split('-').reverse()
      let formatedDate = date.map((element, index) => (index <= 1 ? element + '.' : element))
      return formatedDate
   }
   function slitter(value) {
      if (typeof value === 'number') {
         return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return value
   }

   function itemsOrder(value, url, id) {

      let processedArray = []
      for (let key in value) {
         processedArray.push(
            <div className={classes.orderingPendingItem}>
               {value[key].asic.photo_urls === null ? null : (
                  <img src={imageUrl + `/${value[key]?.asic.photo_urls[0]}`} alt='asic' />
               )}
               <div className={classes.orderingPendingItemHeader}>
                  <p>
                     {value[key].asic.name} <br />{' '}
                     <span className={classes.amountItems}>
                        Количество - {value[key].count} шт.
                     </span>
                  </p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>
                        {slitter(value[key].asic.total_price)} ₽
                     </span>
                     {value[key].asic.price !== 0 ? (
                        <span className={classes.orderingDoneItemDiscount}>
                           {value[key].asic.price}
                        </span>
                     ) : null}
                     {value[key].asic.discount !== null ? (
                        <span className={classes.orderingDoneItemDiscountExtra}>
                           -{slitter(value[key].asic.discount)} ₽
                        </span>
                     ) : null}
                  </p>
               </div>
            </div>,
            <hr />,
         )
      }
      return processedArray
   }
   useEffect(() => {
      axios
         .get(baseUrl + '/orders?limit=100&offset=0', {
            headers: {
               Accept: 'application/json',
               Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
         })
         .then((response) => {
            const data = response.data
            setOrderData(data.filter((element) => element.status === 0 || element.status === 2).reverse())
         })
         .catch((error) => {
            context.storeDispatch({
               type: 'popup_toggle',
               payload: true,
            })
            console.error('Произошла ошибка:', error.response.status, error.response.statusText)
         })
   }, [active])
   return (
      <div
         className={
            active === 'pending_orders'
               ? classes.profileOrderingPendingActive
               : classes.profileOrderingPending
         }
      >
         {orderData !== undefined
            ? orderData.map((element, index) => {
               return (
                  <div className={classes.orderingPending} key={index}>
                     <div className={classes.orderingPendingHeader}>
                        <p>
                           Заказ {element.id} <span>от {dateFormating(element.created_at)}</span>
                        </p>
                        <span className={classes.orderStatus}>
                           <p className={classes.statusOrderingPending}>
                              {formatingOrder(element.status)}
                           </p>
                           {element.confirmation_url !== null ? <button className={classes.activeButton} onClick={() => setVideo(element.id)}>Видеопроверка</button> : null}
                           {video === element.id ?
                              <div className={classes.popupVideo}>
                                 <span onClick={() => setVideo(null)}><img src={cross} alt="cross" /></span>
                                 <video src={imageUrl + element.confirmation_url} autoPlay={true} controls={true}>
                                    Браузер не поддерживает видеоплеер, скачать можно по <a href={imageUrl + element.confirmation_url}><strong>ссылке.</strong></a>
                                 </video>
                              </div>
                              : null
                           }
                        </span>
                     </div>
                     {itemsOrder(element.cart.items, element.confirmation_url, element.id)}
                     <span className={classes.orderingDoneItemPrice}>
                        Ожидается оплата в размере <span>{slitter(element.total_price)}</span> ₽
                     </span>
                  </div>
               )
            })
            : null}
         {/* <div className={classes.orderingPending}>
            <div className={classes.orderingPendingHeader}>
               <p>
                  Заказ Б-22841549 <span>от 17.08.2022</span>
               </p>
               <p className={classes.statusOrderingPending}>Ожидает отгрузки</p>
               <p className={classes.statusOrderingPendingMobile}>Отменен</p>
            </div>
            <div className={classes.orderingPendingItem}>
               <img src={imageAsic} alt='asic' />
               <div className={classes.orderingPendingItemHeader}>
                  <p>Bitmain Antminer S19K PRO 120 TH/s</p>
                  <p>
                     <span className={classes.orderingDoneItemPrice}>190 000 ₽</span>
                     <span className={classes.orderingDoneItemDiscount}>203 000</span>
                     <span className={classes.orderingDoneItemDiscountExtra}>-13 000 ₽</span>
                  </p>
               </div>
               <button>Видеопроверка</button>
            </div>
         </div> */}
      </div>
   )
}

export default ProfileOrderPending
