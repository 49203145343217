import classes from './NewsItem.module.scss'
import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { imageUrl } from '../../../config/config'
import React from 'react'

import { toKebabCase } from '../../../common/comFn'
import regularBaner from '../../../../accests/image/callbackBaner.png'

const NewsItem = ({ data }) => {
   const navigate = useNavigate()
   const [image, setImage] = useState()

   function scrollUp() {
      window.scroll(0, document.getElementById('newsPage'))
   }
   useEffect(() => {
      setImage(data.photos[0] === undefined ? regularBaner : imageUrl + data.photos[0].store_url)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   function formatDate(dateString) {
      const months = [
         'января',
         'февраля',
         'марта',
         'апреля',
         'мая',
         'июня',
         'июля',
         'августа',
         'сентября',
         'октября',
         'ноября',
         'декабря',
      ]

      const date = new Date(dateString)
      const day = date.getDate() // Получаем день месяца
      const month = months[date.getMonth()] // Получаем название месяца
      const year = date.getFullYear() // Получаем год

      return `${day} ${month}, ${year}`
   }

   return (
      <div
         className={classes.newsContentItemSmallAlt}
         onClick={() => {
            scrollUp()
            navigate(`/news/${data.slug !== null ? data.slug + '-' + data.id : data.id} `, {
               state: { id: data.id, name: toKebabCase(data.title), slug: data.slug },
            })
            window.location.reload()
         }}
      >
         <span
            onClick={() => scrollUp()}
            style={{
               backgroundImage: `url(
                  ${image}
               )`,
            }}
         >
            {/* <img
               src={
                  data.photos[0] === undefined ? regularBaner : imageUrl + data.photos[0].store_url
               }
               alt={data.title}
            /> */}
         </span>
         <div className={classes.textWrapper}>
            <div className={classes.theme}>{data?.topic ?? 'IMINE'}</div>
            <div className={classes.title}>{data?.title.split(' ').slice(0, 6).join(' ')}...</div>
            <div className={classes.author}>{`${data?.post_owner.fio} · ${formatDate(
               data.created_at,
            )}`}</div>
            {/* <p>
               <span>{data.post_owner.fio}</span>
               <span>{dateFormating(data.created_at)}</span>
            </p>
            <h3 dangerouslySetInnerHTML={{ __html: data?.title }} />
            <h4
               className={data.newsCard === true ? classes.newsCardAlso : null}
               dangerouslySetInnerHTML={{ __html: data?.description }}
            /> */}
         </div>
      </div>
   )
}

export default NewsItem
