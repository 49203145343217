import React from 'react'
import { useNavigate } from 'react-router-dom'
import classes from '../Header.module.scss'

const NewHeaderMenu = () => {
   const navigate = useNavigate()
   const menuArr = [
      { name: 'О компании', link: '/about-us' },
      { name: 'Оплата и доставка', link: '/payment' },
      { name: 'Блог', link: '/news' },
      { name: 'Контакты', link: '/contacts' },
      { name: 'Помощь', link: '/faq' },
   ]
   const handleLink = (link) => {
      window.scroll(0, 0)
      navigate(link)
   }
   return (
      <div className={classes.newMenuWrapper}>
         {menuArr.map((item) => (
            <div key={item.name} onClick={() => handleLink(item.link)}>
               {item.name}
            </div>
         ))}
      </div>
   )
}

export default NewHeaderMenu
