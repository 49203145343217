import { useState } from 'react'
import { text } from '../../../translate'
import classes from './ProfileSwitcher.module.scss'

const ProfileSwitcher = ({ selectActiveList ,active }) => {
   const [activeList, setActiveList] = useState(null)
   const lang = 'ru'

   const oprionsList = [
      [text[lang].profile.profileSwitcher.profileSettings,"profile"],
      [text[lang].profile.profileSwitcher.currentOrders,"pending_orders"],
      [text[lang].profile.profileSwitcher.orderHistory,"all_orders"],
   ]
   function select(value) {
      setActiveList(value)
      selectActiveList(value)
   }
   return (
      <div className={classes.profileListSwitcherMain}>
         <ul className={classes.profileListSwitcher}>
            {oprionsList.map((element, index) => {
               return (
                  <li
                     key={index}
                     onClick={() => select(element[1])}
                     className={active === element[1] ? classes.profileSwitcherActive : null}
                  >
                     {element[0]}
                  </li>
               )
            })}
         </ul>
      </div>
   )
}

export default ProfileSwitcher
