import classes from './AboutCompany.module.scss'
import { useState, useEffect, useRef } from 'react'
import arrowLeft from '../../../accests/image/sliderArrow.svg'

import { Link } from 'react-router-dom'
import Crums from '../breadCrums/Crums'
import AboutInfo from '../aboutInfo/AboutInfo'
import PartnerLogo from '../partnerLogo/PartnerLogo'

import axios from 'axios'
import { baseUrl, imageUrl } from '../../config/config'

const AboutCompany = () => {
   const crumsData = [['about-us', 'О компании']]
   const [sliderArr, setSliderArr] = useState([])
   const [companyInfo, setCompanyInfo] = useState({})
   const sliderRef = useRef()
   const [activeSlide, setActiveSlide] = useState(1)
   const prevSlide = activeSlide - 1 < 1 ? sliderArr.length : activeSlide - 1
   const nextSlide = activeSlide + 1 >= sliderArr.length + 1 ? 1 : activeSlide + 1

   useEffect(() => {
      axios
         .get(baseUrl + '/info/about-us')
         .then((response) => {
            let aboutData = response.data
            setSliderArr(aboutData.slider)
            setCompanyInfo(aboutData.company_info)
         })
         .catch((error) => {
            console.error(error.status, error.statusText)
         })
   }, [])

   return (
      <div className={classes.aboutCompanyMain + ' container'}>
         <Crums data={crumsData} />
         <h3>О компании</h3>
         <div className={classes.slider} ref={sliderRef}>
            {sliderArr.map((item, index) => (
               <Link
                  style={{ backgroundImage: `url('${imageUrl + item}')` }}
                  to={item.link}
                  key={index}
                  className={`${classes.slideWrapper} ${activeSlide === index + 1 ? classes.activeSlide : ''
                     } ${prevSlide === index + 1 ? classes.prevSlide : ''} ${nextSlide === index + 1 ? classes.nextSlide : ''
                     }`}
               >
                  <img
                     src={imageUrl + item}
                     alt='imageBanner'
                     data-noimage
                     className={`${classes.mainBanner}`}
                  />
               </Link>
            ))}
            {/* <img src={imgNotepad} alt='huge' /> */}
            <div className={classes.aboutCompanyMainSliderController}>
               <div className={classes.leftSliderArrow}
                  onClick={() =>
                     setActiveSlide(activeSlide - 1 < 1 ? sliderArr.length : activeSlide - 1)
                  }
               >
                  <img src={arrowLeft} alt='arrow' />
               </div>
               <div className={classes.rightSliderArrow}
                  onClick={() =>
                     setActiveSlide(activeSlide + 1 > sliderArr.length ? 1 : activeSlide + 1)
                  }
               >
                  <img src={arrowLeft} alt='arrow' />
               </div>
            </div>
         </div>
         <AboutInfo />
         <div className={classes.aboutCompanyInfo}>
            <div>
               <img src={imageUrl + companyInfo?.img} alt='Charts' />
            </div>
            <div dangerouslySetInnerHTML={{ __html: companyInfo?.about }}>
            </div>
         </div>
         <PartnerLogo />
      </div>
   )
}

export default AboutCompany
