import classes from './ContactsItem.module.scss'
import map from '../../../../accests/image/elements_map.svg'
import mail from '../../../../accests/image/elements_mail.svg'
import phone from '../../../../accests/image/elements_phone.svg'

const ContactsItem = ({ data, toggleActiveContact, isActive }) => {
   return (
      <div
         className={
            isActive + 1 === data.id
               ? classes.contactsItem + ' ' + classes.contactActive
               : classes.contactsItem
         }
         onClick={() => toggleActiveContact()}
      >
         <img src={data.image} alt={data.name} />
         <h4>{data.name}</h4>
         <ul className={classes.listParent}>
            <li className={classes.list}>
               <img src={map} alt='map' /> <p>{data.adress}</p>
            </li>
            {data?.phone && (
               <li className={classes.list}>
                  <img src={phone} alt='phone' />{' '}
                  {/* eslint-disable-next-line react/jsx-no-target-blank */}
                  <a href={`tel: ${data.phone?.replace(/[^\d+]/g, '')}`} target='_blank'>
                     {data.phone}
                  </a>
               </li>
            )}

            <li className={classes.list}>
               <img src={mail} alt='mail' />{' '}
               {/* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href={`mailto:${data.mail}`} target='_blank'>
                  {data.mail}
               </a>
            </li>
            <li className={classes.list}>
               {/* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href={data.socials.watsup.link} target='_blank'>
                  <img src={data.socials.watsup.src} alt={data.socials.watsup.name} />
               </a>
               {/* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href={data.socials.telega.link} target='_blank'>
                  <img src={data.socials.telega.src} alt={data.socials.telega.name} />
               </a>

               {/* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href={data.socials.insta.link} target='_blank'>
                  <img src={data.socials.insta.src} alt={data.socials.insta.name} />
               </a>

               {/* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href={data.socials.youtube.link} target='_blank'>
                  <img src={data.socials.youtube.src} alt={data.socials.youtube.name} />
               </a>

               {/* eslint-disable-next-line react/jsx-no-target-blank */}
               <a href={data.socials.vk.link} target='_blank'>
                  <img src={data.socials.vk.src} alt={data.socials.vk.name} />
               </a>
            </li>
         </ul>
         <a href='https://t.me/iminesalesbot' className={classes.btnCom}>
            Получить консультацию
         </a>
      </div>
   )
}

export default ContactsItem
