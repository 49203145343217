import axios from 'axios'
import { baseUrl } from '../config/config'
//POST /api/v1/marketing/Placement Request
export const postSendFeedback = ({
   state,
   setTextModal,
   setPopup,
   setState,
   setWhatsApp,
   setTg,
   setPhone,
}) => {
   axios
      .post(`${baseUrl}/marketing/datacenter-placement`, state, {
         headers: {
            'Content-Type': 'application/json',
         },
      })
      .then(() => {
         setTextModal('Ваша заявка успешно отправлена!')
         setPopup(true)
         setTimeout(() => {
            setState({
               name: '',
               phones: [{ messenger: 'phone', phone: '' }],
               item_count: 1,
               item: '',
            })
            setWhatsApp({ phone: '', messenger: 'whatsapp' })
            setTg({ phone: '', messenger: 'telegram' })
            setPhone({ phone: '', messenger: 'phone' })
         }, 1500)
      })
      .catch(() => {})
}
