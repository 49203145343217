import { useNavigate } from 'react-router-dom'
import classes from './MobileMenuIcon.module.scss'
import { useContext, useEffect } from 'react'
import { BucketContext } from '../../../../App'

const MobileMenuIcon = ({
   name,
   url,
   isOpen,
   setOpen,
   link,
   activeMenuName,
   setActiveMenuName,
}) => {
   const checkOpenFn = () => {
      if (!isOpen.active) return true
      else if (isOpen.modal === name) return false
      return true
   }

   const context = useContext(BucketContext)

   const navigate = useNavigate()

   const handleLink = () => {
      window.scrollTo({ top: 0, behavior: 'smooth' })
      window.scrollTo(0, 0)
      setOpen(false)

      setTimeout(() => {
         navigate(link)
      }, 300)

      // window.location.reload()
   }

   useEffect(() => {
      if (window.location.pathname === '/') {
         setActiveMenuName('Главная')
      }
      if (window.location.pathname === '/cart') {
         setActiveMenuName('Корзина')
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [window.location.pathname, setActiveMenuName])

   return (
      <div
         className={classes.wrapper}
         onClick={() => {
            link ? handleLink() : setOpen({ active: checkOpenFn(), modal: name })
            setActiveMenuName(name)
         }}
      >
         <div
            className={classes.svg}
            style={{ fill: `${activeMenuName === name ? ' #fff' : ' #80899C'}` }}
         >
            {url}
         </div>
         <p style={{ color: `${activeMenuName === name ? ' #fff' : ' #80899C'}` }}>{name}</p>
         {name === 'Корзина' && (
            <>
               {context.store.amountItems === 0 ||
               localStorage.getItem('amountItems') === '0' ? null : localStorage.getItem(
                    'amountItems',
                 ) !== '0' ? (
                  <div className={classes.notificationCount}>
                     {localStorage.getItem('amountItems')}
                  </div>
               ) : (
                  <div className={classes.notificationCount}>{context.store.amountItems}</div>
               )}
            </>
         )}
      </div>
   )
}

export default MobileMenuIcon
