import News from '../../common/news/News'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import axios from 'axios'
import { baseUrl } from '../../config/config'

import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const NewsPage = () => {
   const redirect = useNavigate()
   const [postData, setPostData] = useState(null)
   useEffect(() => {
      axios
         .get(`${baseUrl}/post/?limit=60&offset=0`)
         .then((response) => {
            const data = response.data
            setPostData(data)
         })
         .catch((error) => {
            redirect('/Error404')
            console.error('Произошла ошибка:', error)
         })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return <>{postData !== null ? <News data={postData} /> : <PageSpinner />}</>
}

export default NewsPage
