import { useEffect, useState } from 'react'
import classes from '../DescriptionItem.module.scss'
import { baseUrl } from '../../../config/config'
import ImgSucc from '../../../../accests/image/success.svg'
import Cross from '../../../../accests/image/crossIcon.svg'
import SidePopup from '../../modalsUtility/SidePopup'
import axios from 'axios'

const ModalReview = ({ id, close, checkRefetch, setCheckRefetch }) => {
   const [rating, setRating] = useState(1)
   const fiveStar = [1, 2, 3, 4, 5]
   const [name, setName] = useState('')
   const [email, setEmail] = useState('')
   const [review, setReview] = useState('')

   const [popup, setPopup] = useState(false)

   const [textModal, setTextModal] = useState('')
   useEffect(() => {
      return () => setTimeout(() => setPopup(false), 2100)
   }, [popup])

   function postReview() {
      axios
         .post(baseUrl + `/asics/${id}/reviews`, {
            rating: rating,
            description: review,
            user_name: name,
            user_email: email,
         })
         .then(() => {
            setTextModal('Ваш отзыв отправлен на модерацию!')
            setTimeout(() => {
               close()
               setName('')
               setEmail('')
               setReview('')
               setCheckRefetch(checkRefetch + 1)
            }, 2100)
         })
         .catch((error) => {
            setTextModal('Что-то пошло не так!')
         })
      setPopup(true)
   }

   function checkFields() {
      if (!name) {
         setTextModal('Введите ваше имя!')
         setPopup(true)
      } else if (!email) {
         setTextModal('Введите ваш email!')
         setPopup(true)
      } else if (!review) {
         setTextModal('Отзыв не может быть пустым!')
         setPopup(true)
      } else {
         postReview()
      }
   }

   return (
      <div className={classes.wrapperModal}>
         <div className={classes.modalTitle}>Ваша оценка товара</div>
         <div className={classes.ratingModalS}>
            {fiveStar.map((item) => (
               <div className={classes.starWrapperModal} onClick={() => setRating(item)} key={item}>
                  <svg
                     width='28'
                     height='28'
                     viewBox='0 0 28 28'
                     fill='none'
                     xmlns='http://www.w3.org/2000/svg'
                  >
                     <path
                        d='M14.8609 0.867552C14.3165 0.607951 13.6839 0.607951 13.1394 0.867552C12.6688 1.09197 12.4092 1.48162 12.2784 1.69538C12.1431 1.91637 12.0042 2.19787 11.8688 2.47226L8.99451 8.2953L2.56511 9.23505C2.26246 9.27923 1.95191 9.32455 1.70002 9.38512C1.45634 9.44372 1.0058 9.57045 0.647249 9.94891C0.23246 10.3867 0.0373906 10.9884 0.116351 11.5863C0.184606 12.1031 0.475065 12.4701 0.637999 12.6605C0.806422 12.8574 1.03129 13.0763 1.25044 13.2897L5.90083 17.8192L4.80355 24.2169C4.75175 24.5185 4.69861 24.828 4.67815 25.0864C4.65836 25.3362 4.63924 25.8041 4.88817 26.2624C5.17611 26.7925 5.68795 27.1643 6.28111 27.2743C6.79391 27.3694 7.23296 27.2065 7.46447 27.1105C7.70387 27.0111 7.98176 26.8649 8.25264 26.7224L14.0002 23.6998L19.7478 26.7224C20.0186 26.8649 20.2965 27.0111 20.5359 27.1105C20.7674 27.2065 21.2065 27.3694 21.7193 27.2743C22.3124 27.1643 22.8243 26.7925 23.1122 26.2624C23.3612 25.8041 23.342 25.3362 23.3222 25.0864C23.3018 24.828 23.2487 24.5186 23.1968 24.2169L22.0996 17.8192L26.75 13.2896C26.9691 13.0763 27.194 12.8574 27.3624 12.6605C27.5253 12.4701 27.8158 12.1031 27.884 11.5863C27.963 10.9884 27.7679 10.3867 27.3531 9.94891C26.9946 9.57045 26.544 9.44372 26.3004 9.38512C26.0485 9.32455 25.7379 9.27923 25.4353 9.23505L19.0059 8.2953L16.1316 2.47236C15.9962 2.19796 15.8573 1.91639 15.722 1.69538C15.5911 1.48162 15.3316 1.09197 14.8609 0.867552Z'
                        fill={item <= rating ? `#F3CB67` : '#E2E4E9'}
                     />
                  </svg>
               </div>
            ))}
         </div>
         <div className={classes.inputWrapper}>
            <div className={classes.inputLabel}>Имя</div>
            <input
               className={classes.inputField}
               value={name}
               onChange={(e) => setName(e.target.value)}
               placeholder='Введите ваше имя'
            />
         </div>
         <div className={classes.inputWrapper}>
            <div className={classes.inputLabel}>Email</div>
            <input
               className={classes.inputField}
               value={email}
               onChange={(e) => setEmail(e.target.value)}
               placeholder='Введите ваш email'
            />
         </div>
         <div className={classes.inputWrapper}>
            <div className={classes.inputLabel}>Отзыв</div>
            <textarea
               className={`${classes.inputField} ${classes.textArea}`}
               value={review}
               onChange={(e) => setReview(e.target.value)}
               placeholder='Опишите ваши впечатления от покупки и использования товара'
            />
         </div>

         <div className={classes.buttonModal} onClick={checkFields}>
            Отправить
         </div>
         {popup ? (
            <SidePopup dataState={true}>
               <span
                  className={
                     textModal === 'Что-то пошло не так!' || !name || !email || !review
                        ? classes.crossWrapper
                        : ''
                  }
               >
                  <img
                     src={
                        textModal === 'Что-то пошло не так!' || !name || !email || !review
                           ? Cross
                           : ImgSucc
                     }
                     alt={'success'}
                  />
               </span>
               <span></span>
               <span>
                  <p>{textModal}</p>
               </span>
            </SidePopup>
         ) : null}
      </div>
   )
}

export default ModalReview
