import Button from '../catalogWrapper/Button/Button'
import classes from './MobileFilter.module.scss'

const MobileFilter = ({ children, count, isOpenMobileFilter, setIsOpenMobileFilter }) => {
   const body = document.getElementById('root')

   return (
      <>
         <div className={classes.wrapper}>
            <div
               className={classes.buttonWrapper}
               onClick={() => {
                  body.style.position = 'relative'
                  setIsOpenMobileFilter(!isOpenMobileFilter)
               }}
            >
               <Button>Показать все товары ({count})</Button>
            </div>
         </div>

         <div
            className={classes.close}
            onClick={() => {
               body.style.position = 'relative'
               setIsOpenMobileFilter(!isOpenMobileFilter)
            }}
         />

         <div className={classes.content}>{children}</div>
      </>
   )
}

export default MobileFilter
