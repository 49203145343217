import classes from './PartnerLogo.module.scss'
import PartnersItem from './partnersItem/PartnersItem'
import { text } from '../../../translate'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'
import { useEffect, useRef, useState } from 'react'

const PartnerLogo = () => {
   const lang = 'ru'
   const [currentIndex, setCurrentIndex] = useState(0)
   const [itemsPerSlide, setItemsPerSlide] = useState(window.innerWidth >= 561 ? 3 : 2)
   const sliderRef = useRef(null)
   const { data } = useGetMarketInfo()

   const dataPartners = data?.partners?.length ? data.partners : []

   useEffect(() => {
      const handleResize = () => {
         setItemsPerSlide(window.innerWidth >= 561 ? 3 : 2)
      }

      window.addEventListener('resize', handleResize)
      return () => {
         window.removeEventListener('resize', handleResize)
      }
   }, [])

   const handleScroll = () => {
      if (sliderRef.current) {
         const scrollLeft = sliderRef.current.scrollLeft
         const slideWidth = sliderRef.current.clientWidth / itemsPerSlide
         const newIndex =
            itemsPerSlide > 2
               ? Math.round(scrollLeft / (slideWidth * itemsPerSlide))
               : Math.ceil(scrollLeft / (slideWidth * itemsPerSlide))
         setCurrentIndex(newIndex)
      }
   }

   useEffect(() => {
      const slider = sliderRef.current
      slider.addEventListener('scroll', handleScroll)
      return () => {
         slider.removeEventListener('scroll', handleScroll)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [itemsPerSlide])

   const renderIndicators = () => {
      const numIndicators = Math.ceil(dataPartners.length / itemsPerSlide)
      return Array.from({ length: numIndicators }).map((_, index) => (
         <span
            key={index}
            className={`${classes.indicator} ${currentIndex === index ? classes.active : ''}`}
         />
      ))
   }

   return (
      <div className={'container'}>
         <div className={classes.title}>{text[lang].mainPage.partners}</div>
         <div className={classes.contentWrapper}>
            <div className={classes.content} ref={sliderRef}>
               {dataPartners.map((element, index) => (
                  <PartnersItem
                     key={index}
                     data={[element.name, element.store_url, element.link]}
                  />
               ))}
            </div>
            {/* {dataPartners.length > itemsPerSlide && (
               <div className={classes.indicators}>{renderIndicators()}</div>
            )} */}
         </div>
      </div>
   )
}

export default PartnerLogo
