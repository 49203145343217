export const addRemoveCompareById = (id) => {
   let resCompareArr = localStorage.getItem('compareArr')?.split(',') ?? []

   if (resCompareArr[0] === '') {
      localStorage.setItem('compareArr', id)
   } else if (!!resCompareArr.filter((item) => +item === +id).length) {
      const test = resCompareArr.filter((item) => +item !== +id)
      localStorage.setItem('compareArr', test)
   } else {
      resCompareArr.push('' + id)
      localStorage.setItem('compareArr', resCompareArr)
   }
   window.location.reload()
}

export const addRemoveFavoritesById = (id) => {
   let resFavoritesArr = localStorage.getItem('favoritesArr')?.split(',') ?? []

   if (resFavoritesArr[0] === '') {
      localStorage.setItem('favoritesArr', id)
   } else if (!!resFavoritesArr.filter((item) => +item === +id).length) {
      const test = resFavoritesArr.filter((item) => +item !== +id)
      localStorage.setItem('favoritesArr', test)
   } else {
      resFavoritesArr.push('' + id)
      localStorage.setItem('favoritesArr', resFavoritesArr)
   }
   window.location.reload()
}

export const addRemoveVisitedById = (id) => {
   let resVisitedArr = localStorage.getItem('visitedArr')?.split(',') ?? []

   if (resVisitedArr[0] === '') {
      localStorage.setItem('visitedArr', id)
   } else if (!!resVisitedArr.filter((item) => +item === +id).length) {
      const refreshId = resVisitedArr.filter((item) => +item !== +id)
      refreshId.push('' + id)
      localStorage.setItem('visitedArr', refreshId)
   } else {
      resVisitedArr.push('' + id)
      localStorage.setItem('visitedArr', resVisitedArr)
   }
}

export function phoneFormating(value) {
   let processedValue = ''
   processedValue = value.replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/g, '+7 ($2) $3-$4-$5')
   return processedValue
}
export const formatingNumber = (number) => {
   return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const cursesFormatingNumber = (number) => {
   return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

export const toKebabCase = (str) => {
   return str
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '')
}
