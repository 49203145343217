import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import { Navigation } from 'swiper/modules'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'
import { imageUrl } from '../../config/config'
import classes from './CompanyLogo.module.scss'

const CompanyLogo = () => {
   const navigate = useNavigate()
   const { data } = useGetMarketInfo()

   const winWeight = window.innerWidth
   const breakpoints = {
      1: {
         slidesPerView: 1,
         spaceBetween: 20,
      },
      320: {
         slidesPerView: 2,
         spaceBetween: 20,
      },
      768: {
         slidesPerView: 3,
         spaceBetween: 20,
      },
   }

   return (
      <div className={classes.companyWrapper + ' container'}>
         {winWeight > 768 ? (
            <>
               <Swiper
                  slidesPerView={3}
                  spaceBetween={20}
                  navigation={{
                     nextEl: `.${classes.rightPos}`,
                     prevEl: `.${classes.leftPos}`,
                  }}
                  breakpoints={breakpoints}
                  modules={[Navigation]}
                  className={classes.sliderWrapper}
               >
                  {data?.brands?.map((item) => (
                     <SwiperSlide key={item.id}>
                        <div
                           key={item.id}
                           className={classes.smallSliderItem}
                           onClick={() => navigate(`/asic-miners?company=${item.id}`)}
                        >
                           <img src={`${imageUrl}/${item.store_url}`} alt='smallImage' />
                        </div>
                     </SwiperSlide>
                  ))}
               </Swiper>
               <div className={`${classes.wrapperArrow} ${classes.rightPos}`}>
                  <svg width='7' height='10' viewBox='0 0 7 10' xmlns='http://www.w3.org/2000/svg'>
                     <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                  </svg>
               </div>
               <div className={`${classes.wrapperArrow} ${classes.leftPos}`}>
                  <svg width='7' height='10' viewBox='0 0 7 10' xmlns='http://www.w3.org/2000/svg'>
                     <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                  </svg>
               </div>
            </>
         ) : (
            <div className={classes.mobileSliderWrapper}>
               {data?.brands?.map((item) => (
                  <div
                     key={item.id}
                     className={classes.smallSliderItem}
                     onClick={() => navigate(`/asic-miners?company=${item.id}`)}
                  >
                     <img src={`${imageUrl}/${item.store_url}`} alt='smallImage' />
                  </div>
               ))}
            </div>
         )}
      </div>
   )
}

export default CompanyLogo
