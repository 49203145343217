import classes from '../DescriptionItem.module.scss'
import HashRait from '../../../../accests/image/hashRaitCard.svg'
import Algorytm from '../../../../accests/image/algorytmItem.svg'
import Coins from '../../../../accests/image/coinsItem.svg'
import Energo from '../../../../accests/image/energoIcon.svg'
import Power from '../../../../accests/image/powerIcon.svg'
import Cooller from '../../../../accests/image/coolerIcon.svg'
import Temp from '../../../../accests/image/tempIcon.svg'
import Size from '../../../../accests/image/sizeIcon.svg'
import Voice from '../../../../accests/image/voiceIcon.svg'

const StatsSelect = ({ characteristics, item }) => {
   return (
      // <div className={classes.description} dangerouslySetInnerHTML={{ __html: characteristics }} />

      <div className={classes.wrapper}>
         <h1 className={classes.title}>Характеристики {item?.title}</h1>
         {item?.hashrate && item?.hashrate_um?.value && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={HashRait} alt='hash' />
                  </div>
                  Хешрейт:
               </div>
               <div className={classes.charValue}>
                  {item?.hashrate} {item?.hashrate_um?.value}
               </div>
            </div>
         )}

         {item?.algorithm?.value && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Algorytm} alt='hash' />
                  </div>
                  Алгоритм:
               </div>
               <div className={classes.charValue}>{item?.algorithm?.value}</div>
            </div>
         )}
         {item?.coins && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Coins} alt='coins' />
                  </div>
                  Добываемые монеты:
               </div>
               <div className={classes.charValue}>
                  {item?.coins?.map((item) => item.value).join('/')}
               </div>
            </div>
         )}
         {item?.consumption && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Energo} alt='energo' />
                  </div>
                  Потребление, Вт/ч:
               </div>
               <div className={classes.charValue}>{`${item?.consumption}  ± 5%`}</div>
            </div>
         )}

         {item?.power_supply && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Power} alt='power' />
                  </div>
                  Источник питания:
               </div>
               <div className={classes.charValue}>{item?.power_supply}</div>
            </div>
         )}
         {item?.cooling && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Cooller} alt='cooller' />
                  </div>
                  Охлаждение:
               </div>
               <div className={classes.charValue}>{item?.cooling}</div>
            </div>
         )}
         {item?.operating_temperature && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Temp} alt='tempa' />
                  </div>
                  Рабочая температура, °С:
               </div>
               <div className={classes.charValue}>{item?.operating_temperature}</div>
            </div>
         )}
         {item?.dimensions && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Size} alt='size' />
                  </div>
                  Размеры, мм:
               </div>
               <div className={classes.charValue}>{item?.dimensions}</div>
            </div>
         )}
         {item?.noise_level && (
            <div className={classes.charWrapper}>
               <div className={classes.nameChar}>
                  <div className={classes.iconWrapper}>
                     <img src={Voice} alt='voice' />
                  </div>
                  Уровень шума, дБ:
               </div>
               <div className={classes.charValue}>{item?.noise_level}</div>
            </div>
         )}
      </div>
   )
}

export default StatsSelect
