import classes from './newsFPitem.module.scss'

const NewsFPitem = ({ data }) => {
   return (
      <div className={classes.newsContentItemSmall}>
         <img src={data.image} alt={data.title} />
         <p>
            <span dangerouslySetInnerHTML={{ __html: data?.title }}></span>
            <span>{data.date}</span>
         </p>
         <h3 dangerouslySetInnerHTML={{ __html: data?.content }}></h3>
      </div>
   )
}

export default NewsFPitem
