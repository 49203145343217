import classes from './CatalogListItem.module.css'
// import NextArrowIcon from '../../../../accests/image/beforeArrowNext.svg'
import { useNavigate } from 'react-router-dom'

const CatalogListItem = ({ name, link, closedModal }) => {
   const navigate = useNavigate()

   return (
      <div
         className={classes.wrapper}
         onClick={() => {
            navigate(link)
            closedModal()
            window.location.reload()
         }}
      >
         {name}
         {/* <img className={classes.arrowImg} src={NextArrowIcon} alt='arrowNext' /> */}
         <svg width='7' height='10' viewBox='0 0 7 10' xmlns='http://www.w3.org/2000/svg'>
            <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
         </svg>
      </div>
   )
}

export default CatalogListItem
