import classes from './newsFPitem.module.scss'
import { useNavigate } from 'react-router-dom'
import regularBanner from '../../../../accests/image/callbackBaner.png'
import { imageUrl } from "../../../config/config"
import { toKebabCase } from '../../comFn'

const NewsFPitem = ({ data }) => {
   function dateFormating(value) {
      let date = value.slice(0, 10).split('-').reverse()
      let formatedDate = date.map((element, index) => (index <= 1 ? element + '.' : element))
      return formatedDate
   }
   function scrollUp() {
      window.scroll(0, document.getElementById('newsPage'))
   }
   const navigate = useNavigate()
   return (
      <div
         className={classes.newsContentItemSmall}
         onClick={() => {
            navigate(`/news/${data.slug !== null ? (data.slug + "-" + data.id) : data.id} `, { state: { id: data.id, name: toKebabCase(data.title), slug: data.slug } })
            scrollUp()
            window.location.reload()
         }}
      >
         <div style={{ backgroundImage: `url(${data.photos[0] === undefined ? regularBanner : imageUrl + data.photos[0].store_url})` }} alt={data.title}           >
         </div>
         <p>
            <span>{data.post_owner.fio}</span>
            <span>{dateFormating(data.created_at)}</span>
         </p>
         <h3 onClick={() => scrollUp()} dangerouslySetInnerHTML={{ __html: data?.title }}></h3>
      </div>
   )
}

export default NewsFPitem
