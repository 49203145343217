import classes from './Ordering.module.scss'
import { useEffect, useState, useContext } from 'react'

import { ProfileContext } from '../../../App'
import Crums from '../breadCrums/Crums'
import Delivery from './orderingItems/Delivery'
import PaymentMethod from './orderingItems/PaymentMethod'
import RightColumCart from './orderingItems/RightColumCart'
import UserData from './orderingItems/UsersData'
import { baseUrl } from '../../config/config'
import FormOrdering from './orderingItems/FormOrdering'
import axios from 'axios'
import requared from '../axiosIntercepter'

const Ordering = () => {
   const context = useContext(ProfileContext)
   const token = localStorage.getItem('auth_token')
   const dataCrums = [
      ['cart', 'Корзина'],
      ['ordering', 'Оформление заказа'],
   ]
   const [delivery, setDelivery] = useState(null)
   const [user, setUser] = useState(false)
   const [userData, setUserData] = useState({
   })

   const endOf = (value) => {
      switch (true) {
         case value === 1:
            return value + ' товар'
         case [2, 3, 4].includes(value):
            return value + ' товара'
         default:
            return value + ' товаров'
      }
   }

   function settingUserData(inputName, inputValue) {
      setUserData({ ...userData, [inputName]: inputValue })
   }
   function deliveryMethodFunc(value) {
      setDelivery(value)
   }
   function payment() {}

   function validatingPayment() {
      setUserData({ ...userData, payment_type: 'error' })
   }
   function validatingDelivery() {
      setUserData({ ...userData, delivery_type: 'error' })
   }
   useEffect(() => {
      requared
         .get(baseUrl+'/auth/me')
         .then((response) => {
            let userDataForming = {}
            const data = response.data
            userDataForming.phone_number = data.phone_number
            userDataForming.first_name = data.first_name
            userDataForming.last_name = data.last_name
            userDataForming.email = data.email
            setUserData({ ...userData, ...userDataForming })
         })
         .catch((error) => {
            context.storeDispatch({
               type: 'popup_toggle',
               payload: true,
            })
            console.error('Произошла ошибка:', error.status, error.statusText)
         })
   }, [token])
   // useEffect(() => {
   //    axios
   //       .get(`https://dev.imine.ru/api/v1/orders/delivery_price/${delivery}`)
   //       .then((response) => {
   //          const data = response.data
   //          setUserData({ ...userData, delivery_price: data.delivery_price })
   //       })
   //       .catch((error) => {
   //          console.error('Произошла ошибка:', error.status, error.statusText)
   //       })
   // }, [delivery])
   return (
      <div className={classes.orderingMain + ' container'}>
         <Crums data={dataCrums} />
         <div className={classes.orderingContent}>
            <h3>
               <span>Оформление заказа </span>
               <span>{endOf(JSON.parse(localStorage.getItem('amountItems')))}</span>
            </h3>
            <FormOrdering orderData={userData} />
         </div>
      </div>
   )
}

export default Ordering
