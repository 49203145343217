import classes from '../UsersData.module.scss'

function CommercialUser({ input }) {
   const usersData = {
      commercial: {
         commercialNames: [
            ['Название компании', 'company_name'],
            ['Адрес', 'legal_address'],
         ],
         commercialData: [
            ['ИНН', 'inn'],
            ['КПП', 'kpp'],
         ],
         commercialContacts: [
            ['Контактное лицо', 'contact_name'],
            ['Телефон', 'phone'],
            ['E-mail', 'email'],
         ],
      },
   }
   return (
      <>
         <div className={classes.usersName}>
            {usersData.commercial.commercialNames.map((element, index) => {
               return (
                  <div key={index} className={classes.inputWrapper}>
                     <p>{element[0]}</p>
                     <input
                        type='text'
                        name={element[1]}
                        onChange={(e) => input(e.target.name, e.target.value)}
                     />
                  </div>
               )
            })}
         </div>
         <div className={classes.usersComercialData}>
            {usersData.commercial.commercialData.map((element, index) => {
               return (
                  <div key={index} className={classes.inputWrapper}>
                     <p>{element[0]}</p>
                     <input
                        type='text'
                        name={element[1]}
                        onChange={(e) => input(e.target.name, e.target.value)}
                     />
                  </div>
               )
            })}
         </div>
         <div className={classes.usersContacts}>
            {usersData.commercial.commercialContacts.map((element, index) => {
               return (
                  <div key={index} className={classes.inputWrapper}>
                     <p>{element[0]}</p>
                     <input
                        type='text'
                        name={element[1]}
                        onChange={(e) => input(e.target.name, e.target.value)}
                     />
                  </div>
               )
            })}
         </div>
      </>
   )
}

export default CommercialUser
