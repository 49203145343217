import classes from './NewCard.module.scss'
import Crums from '../breadCrums/Crums'
import NewsFP from './Newsfp'
// import eye from '../../../accests/image/eye.svg'
// import telega from '../../../accests/image/teleg.svg'
// import insta from '../../../accests/image/insta.svg'
// import regularBaner from '../../../accests/image/callbackBaner.png'
// import imgSucc from '../../../accests/image/succes_239.svg'
// import SidePopup from '../modalsUtility/SidePopup'

// import { useState } from 'react'

const NewCard = ({ data }) => {
   // const [popup, setPopup] = useState(false)
   const crumsData = [
      ['news', 'Новости'],
      ['card', data.title],
   ]
   window.scroll(0, document.getElementById('newsPage'))

   function formatDate(dateString) {
      const months = [
         'января',
         'февраля',
         'марта',
         'апреля',
         'мая',
         'июня',
         'июля',
         'августа',
         'сентября',
         'октября',
         'ноября',
         'декабря',
      ]

      const date = new Date(dateString)
      const day = date.getDate() // Получаем день месяца
      const month = months[date.getMonth()] // Получаем название месяца
      const year = date.getFullYear() // Получаем год

      return `${day} ${month}, ${year}`
   }
   return (
      <div className={'container ' + classes.Post} id='news-header'>
         <Crums data={crumsData} />
         <div className={classes.newCardAutor}>
            <ul className={classes.newCardAutorInfo}>
               <li className={classes.newsDate}>Новости · {formatDate(data.created_at)}</li>
            </ul>
            <h3>{data.title}</h3>

            <ul className={classes.newCardAutorBio}>
               <li>
                  <div
                     className={classes.bioAvatar}
                     style={{
                        backgroundImage: `url(https://dev.imine.ru/${data.post_owner.store_url})`,
                     }}
                  />
               </li>
               <li>
                  <p className={classes.bioPostOwner}>{data.post_owner.fio}</p>
               </li>
            </ul>
         </div>
         <div className={classes.newCardAutorPost}>
            {data.photos[0] === undefined ? null : (
               <img
                  className={classes.PostImage}
                  src={`https://dev.imine.ru/${data.photos[0]?.store_url}`}
                  alt={data.title}
               />
            )}
            <div className={classes.PostContent}>
               <div dangerouslySetInnerHTML={{ __html: data.description }}></div>
            </div>
            {/* {popup === true ? (
               <SidePopup dataState={true}>
                  <>
                     <span>
                        <img src={imgSucc} alt='success' />
                     </span>
                     <span>Успех!</span>
                     <span> Сслылка скопирована!</span>
                  </>
               </SidePopup>
            ) : null} */}
         </div>
         <NewsFP option={'news_card'} />
      </div>
   )
}

export default NewCard
