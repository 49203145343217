import { useRef, useContext } from 'react'
import classes from './Footer.module.scss'
import insta from '../../../accests/image/insta_soc.svg'
import watsup from '../../../accests/image/watsup.svg'
import telega from '../../../accests/image/teleg.svg'
import youtube from '../../../accests/image/youtube.svg'
import emailFooter from '../../../accests/image/email-footer.svg'
import phoneFooter from '../../../accests/image/phone-footer.svg'

import vk from '../../../accests/image/vk.svg'
import logo from '../../../accests/image/logoFooter.svg'
import { useNavigate } from 'react-router-dom'

import { useGetContacts } from '../../hooks/useGetContacts'

import { ProfileContext } from '../../../App'
import RegularCB from './footerItems/RegularCB'
import MiningManagement from './footerItems/MiningManagement'

const Footer = () => {
   const { data: contact } = useGetContacts()
   const context = useContext(ProfileContext)
   const navigate = useNavigate()
   const footerWidth = useRef(700)

   function scrollup(url = '/') {
      window.scrollTo(0, 0)
      navigate(url)
   }

   return (
      <>
         {context.profileStore.callbackState === 'regular' ? <RegularCB /> : <MiningManagement />}
         <footer className={classes.footer + ' container'} ref={footerWidth}>
            <div className={classes.footerLogo}>
               <div onClick={() => scrollup()}>
                  <img src={logo} alt='logo' />
               </div>
               <span className={classes.logoText}>
                  Официальный поставщик майнинг оборудования в России
               </span>
            </div>

            <div className={classes.footerNav}>
               <ul className={classes.footerNavColumn}>
                  <div className={classes.contactWrapper}>
                     <a href={`mailto:${contact?.email}`}>
                        <img src={emailFooter} alt='email' />
                        {contact?.email}
                     </a>
                     {contact?.phone && (
                        <a href={`tel: ${contact?.phone?.replace(/[^\d+]/g, '')}`}>
                           <img src={phoneFooter} alt='phone' />
                           {contact?.phone}
                        </a>
                     )}
                  </div>
                  <li onClick={() => scrollup('/terms')} className={classes.noMobile}>
                     Пользовательское соглашение
                  </li>
                  <li onClick={() => scrollup('/rights')} className={classes.noMobile}>
                     Политика конфиденциальности
                  </li>
                  <div className={classes.contactLinks}>
                     <a href={contact?.wa} target='_blank' rel='noreferrer'>
                        <img src={watsup} alt='' />
                     </a>
                     <a href={contact?.telegram} target='_blank' rel='noreferrer'>
                        <img src={telega} alt='' />
                     </a>
                     <a href={contact?.youtube} target='_blank' rel='noreferrer'>
                        <img src={youtube} alt='' />
                     </a>
                     <a href={contact?.vk} target='_blank' rel='noreferrer'>
                        <img src={vk} alt='' />
                     </a>
                     <a href='https://instagram.com/imine.russia' target='_blank' rel='noreferrer'>
                        <img src={insta} alt='' />
                     </a>
                  </div>
               </ul>
               <ul className={classes.footerNavColumn}>
                  <li>
                     <a href='https://t.me/ImineShopBot' target='_blank' rel='noreferrer'>
                        Калькулятор доходности
                     </a>
                  </li>
                  <li>
                     <a href='https://t.me/iminesalesbot' target='_blank' rel='noreferrer'>
                        Быстрый заказ
                     </a>
                  </li>
                  <li>
                     <a
                        href='https://m.bitmain.com/support/warranty'
                        target='_blank'
                        rel='noreferrer'
                     >
                        Проверить гарантию Antminer
                     </a>
                  </li>
                  <li>
                     <a href='https://whatsmíner.com/support/' target='_blank' rel='noreferrer'>
                        Проверить гарантию Whatsminer
                     </a>
                  </li>
               </ul>
               <ul className={classes.footerNavColumn}>
                  <li onClick={() => scrollup('/data-centers')}>Дата центры</li>
                  <li onClick={() => scrollup('/mining-management')}>Майнинг под ключ</li>
                  <li onClick={() => scrollup('/about-us')}>О компании</li>
                  <li onClick={() => scrollup('/news')}>Новости</li>
                  <li onClick={() => scrollup('/terms')} className={classes.mobile}>
                     Пользовательское соглашение
                  </li>
                  <li onClick={() => scrollup('/rights')} className={classes.mobile}>
                     Политика конфиденциальности
                  </li>
               </ul>
            </div>
         </footer>
      </>
   )
}

export default Footer
