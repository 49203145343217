import { text } from '../../../translate'
import classes from './ProfilePage.module.scss'
import { useState, useEffect, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import PageSpinner from '../../common/pageSpinner/PageSpinner'
import Crums from '../../common/breadCrums/Crums'
import ProfileSwitcher from '../../common/profile/ProfileSwitcher'
import ProfileData from '../../common/profile/ProfileData'
import ProfileOrdersDone from '../../common/profile/ProfileOrdersDone'
import ProfileOrdersPending from '../../common/profile/ProfileOrdersPending'
import { ProfileContext } from '../../../App'
import { baseUrl } from '../../config/config'
import requared from '../../common/axiosIntercepter'

const ProfilePage = () => {
   const naviState = useLocation()
   const [token] = useState(localStorage.getItem('auth_token'))
   const context = useContext(ProfileContext)
   const [activeList, setActiveList] = useState(naviState?.state || 'profile')
   const [userData, setUserData] = useState()
   const [pageSpinnerActive, setPageSpinnerActive] = useState(true)
   const lang = 'ru'
   const crumsData = [['/profile', 'Профиль']]

   const selectActiveList = (value) => {
      setActiveList(value)
   }

   useEffect(() => {
      requared
         .get(baseUrl + '/auth/me')
         .then((response) => {
            let userDataForming = {}
            const data = response.data
            userDataForming.phone_number = data.phone_number
            userDataForming.first_name = data.first_name
            userDataForming.last_name = data.last_name
            userDataForming.surname = data.surname
            userDataForming.email = data.email
            setUserData({ ...userDataForming })
            setPageSpinnerActive(false)
         })
         .catch((error) => {
            context.storeDispatch({
               type: 'popup_toggle',
               payload: true,
            })
            console.error('Произошла ошибка:', error?.response?.status, error?.response?.statusText)
         })
   }, [token])

   return (
      <>
         {pageSpinnerActive ? (
            <PageSpinner />
         ) : (
            <div className={classes.profileMain + ' container'}>
               <Crums data={crumsData} />
               <h3>{text[lang].profile.headerName}</h3>
               <div className={classes.profileController}>
                  <div className={classes.profileControllerLeftCol}>
                     <ProfileSwitcher selectActiveList={selectActiveList} active={activeList} />
                  </div>
                  <div className={classes.profileControllerRightCol}>
                     <ProfileData userData={userData} active={activeList} />
                     <ProfileOrdersPending active={activeList} />
                     <ProfileOrdersDone active={activeList} />
                  </div>
               </div>
            </div>
         )}
      </>
   )
}

export default ProfilePage
