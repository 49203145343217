import React from "react"
// import MiningWhy from "../../common/miningWhy/MiningWhy"
import MiningWhy11 from "../../common/miningWhy/MiningWhy11"

const MiningMain = () => {
    return (      
            <MiningWhy11 />
    )
}

export default MiningMain