import classes from './Error404.module.scss'

import huge404 from '../../../accests/image/huge404.svg'
import Crums from '../breadCrums/Crums'
import { Link } from 'react-router-dom'

const Error404 = () => {
   const crumsData = [['error', 'Страница не найдена']]
   return (
      <div className={classes.errorMain + ' container'}>
         <Crums data={crumsData} />
         <div className={classes.errorMain}>
            <span className={classes.errorMainContent}>
               <img src={huge404} alt='Error404' className={classes.errorImage} />
               <h3>
                  Страница, которую вы ищете, <br />
                  не существует или была перемещена
               </h3>
               <Link to='/' className={classes.errorButton}>
                  На главную
               </Link>
            </span>
         </div>
      </div>
   )
}

export default Error404
