function setLocalStorage() {
   if (localStorage.getItem('bucket') === null) {
      localStorage.setItem('bucket', '{}')
   }
   if (localStorage.getItem('amountItems') === null) {
      localStorage.setItem('amountItems', 0)
   }
}

function calculateForCart(value) {
   let initialState = 0
   let formedArray = Object.values(value)
   return formedArray.reduce((accumulate, element) => element + accumulate, initialState)
}

setLocalStorage()

// export const initialBucketState = {
//    bucket: JSON.parse(localStorage.getItem('bucket')) || {},
//    amountItems: parseInt(localStorage.getItem('amountItems'), 10) || 0,
// }

export default function reducer(state, action) {
   switch (action.type) {
      case 'adding_item':
         let bucket = JSON.parse(localStorage.getItem('bucket'))
         if (Object.keys(bucket).length === 0 || bucket[action.payload] === undefined) {
            bucket[action.payload] = bucket[action.payload] = 1
            localStorage.setItem('bucket', JSON.stringify(bucket))
         } else {
            bucket[action.payload] = ++bucket[action.payload]
            localStorage.setItem('bucket', JSON.stringify(bucket))
         }
         localStorage.setItem('amountItems', calculateForCart(bucket))
         return { ...state, amountItems: calculateForCart(bucket) }
      case 'remove_item':
         let removeItem = JSON.parse(localStorage.getItem('bucket'))
         delete removeItem[action.payload]
         localStorage.setItem('bucket', JSON.stringify(removeItem))
         localStorage.setItem('amountItems', calculateForCart(removeItem))
         return { ...state, amountItems: calculateForCart(removeItem) }
      case 'decrease_item_amount':
         let dereaseBucket = JSON.parse(localStorage.getItem('bucket'))
         if (dereaseBucket[action.payload] <= 1) {
            dereaseBucket[action.payload] = 1
         } else {
            dereaseBucket[action.payload] = --dereaseBucket[action.payload]
         }
         localStorage.setItem('bucket', JSON.stringify(dereaseBucket))
         localStorage.setItem('amountItems', calculateForCart(dereaseBucket))
         return { ...state, amountItems: calculateForCart(dereaseBucket) }
      case 'increase_item_amount':
         let increaseBucket = JSON.parse(localStorage.getItem('bucket'))
         increaseBucket[action.payload] = ++increaseBucket[action.payload]
         localStorage.setItem('bucket', JSON.stringify(increaseBucket))
         localStorage.setItem('amountItems', calculateForCart(increaseBucket))
         return { ...state, amountItems: calculateForCart(increaseBucket) }
      case 'select_amount':
         let selectAmountBucket = JSON.parse(localStorage.getItem('bucket'))
         selectAmountBucket[action.payload[1]] = action.payload[0] * 1
         localStorage.setItem('bucket', JSON.stringify(selectAmountBucket))
         localStorage.setItem('amountItems', calculateForCart(selectAmountBucket))
         return { ...state, amountItems: calculateForCart(selectAmountBucket) }
      case 'pop_selected_items':
         let removeRequest = action.payload
         let regularBucket = JSON.parse(localStorage.getItem('bucket'))
         let removeBucket = Object.keys(JSON.parse(localStorage.getItem('bucket'))).map(
            (element) => element * 1,
         )
         removeBucket.map((element) => {
            if (removeRequest[0].includes(element)) {
               delete regularBucket[element]
            }
         })
         localStorage.setItem('bucket', JSON.stringify(regularBucket))
         localStorage.setItem('amountItems', calculateForCart(regularBucket))
         return { ...state, amountItems: calculateForCart(regularBucket) }
      case 'clear_amount_items':
         return { ...state, amountItems: 0 }
      default:
         return console.log('error in bucket reducer')
   }
}
