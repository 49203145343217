import classes from './FormRightColumn.module.scss'
import { useGetAsicsArray } from '../../../../hooks/useGetAsicsArray'

import { useEffect, useState } from 'react'

const RightColumCart = ({ promoChild, button, discountPercents, promoValue }) => {
   const bucketFormated = JSON.parse(localStorage.getItem('bucket'))
   const [totalMoney, setTotalMoney] = useState(0)
   const bucketItems = Object.keys(JSON.parse(localStorage.getItem('bucket'))).map(
      (element) => element * 1,
   )
   const { data: asicsDataArray, isLoading } = useGetAsicsArray([...bucketItems])
   const processedData = asicsDataArray.filter((element) => bucketItems.includes(element.id))

   function slitter(value) {
      if (typeof value === 'number') {
         return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
      }
      return value
   }
   useEffect(() => {
      let startAmountofMoney = 0
      setTotalMoney(
         processedData.reduce(
            (accumulate, element) => accumulate + bucketFormated[element.id] * element.total_price,
            startAmountofMoney,
         ),
      )
   }, [isLoading])
   return (
      <div className={classes.rightColMain}>
         <h3>Ваш заказ :</h3>
         {promoChild()}
         <ul className={classes.itemsList}></ul>
         <ul className={classes.priceProcessor}>
            <ul className={classes.itemsList}>
               {processedData.map((element, index) => {
                  return (
                     <li key={index} className={classes.listItem}>
                        <span> {element.name}</span>
                        <span></span>
                        <span> {bucketFormated[element.id]}шт.</span>
                     </li>
                  )
               })}
            </ul>
            <li className={classes.units}>
               <span>Товары</span>
               <span></span>
               <span>{slitter(totalMoney)} ₽</span>
            </li>

            {discountPercents > 0 ? (
               <li className={classes.units}>
                  <span>Скидка ({discountPercents}%)</span>
                  <span></span>
                  <span>{slitter((totalMoney / 100) * discountPercents)} ₽</span>
               </li>
            ) : null}
            {/* {data.delivery_price !== 0 ? (
               <li className={classes.units}>
                  <span>Доставка</span>
                  <span></span>
                  <span>{slitter(data.delivery_price)} ₽</span>
               </li>
            ) : null} */}
            {discountPercents > 0 ? (
               <li className={classes.units}>
                  <span>Промокод</span>
                  <span></span>
                  <span>-{discountPercents}%</span>
               </li>
            ) : null}

            {/* <li className={classes.removed}>
               <span></span>
               <span></span>
               <span>203 000</span>
            </li> */}
            <li className={classes.total}>
               <span>Итого :</span>
               <span></span>
               <span>
                  {discountPercents !== 0
                     ? slitter(totalMoney - totalMoney * (discountPercents / 100))
                     : slitter(totalMoney)}
                  ₽
               </span>
            </li>
         </ul>
         {button()}
      </div>
   )
}

export default RightColumCart
