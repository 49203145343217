import UserIcon from '../../../../accests/image/userIcon.svg'
import { useContext, useEffect, useState } from 'react'
import classes from './AuthButton.module.scss'
import axios from 'axios'
import { baseUrl } from '../../../config/config'
import { useNavigate, useLocation } from 'react-router-dom'

// import requared from '../../axiosIntercepter'
import UserBlack from '../../../../accests/image/userIconBlack.svg'
import userImage from '../../../../accests/image/user_frame.svg'
import { ProfileContext } from '../../../../App'
import requared from '../../axiosIntercepter'

const AuthButton = ({ children, size = 'desktop' }) => {
   const locationState = useLocation()
   const navigate = useNavigate()
   const context = useContext(ProfileContext)
   const token = localStorage.getItem('auth_token')
   const [userData, setUserData] = useState()
   const [dropDownMenu, setDropDownMenu] = useState(false)

   function redirecting() {
      if (localStorage.getItem('auth_token') !== null) {
         navigate('/profile')
      } else {
         context.storeDispatch({
            type: 'popup_toggle',
            payload: !context.activePopup,
         })
      }
   }
   function logout() {
      context.storeDispatch({
         type: 'logout',
      })
      navigate('/')
   }
   //  `adding test string `
   useEffect(() => {
      requared
         .get(baseUrl + '/auth/me')
         .then((response) => {
            const data = response.data
            if (data.email !== null) {
               localStorage.setItem('authName', data.email)
            } else {
               localStorage.setItem('authName', data.phone)
            }
            setUserData(data.email !== null ? data.email : data.phone)
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error.status)
            localStorage.setItem('authName', '')
         })
   }, [])

   return (
      <div className={size === 'desktop' ? classes.logout : classes.logoutMobile}>
         <img src={UserIcon} alt='user' className={classes.userImg} />
         {localStorage.getItem('authName') !== '' ? (
            <>
               <span
                  onMouseEnter={() => {
                     setDropDownMenu(true)
                  }}
               >
                  {size === 'desktop' ? 'Профиль' : <img src={UserBlack} alt='user' />}
               </span>
               {dropDownMenu ? (
                  <div
                     className={classes.profileDropdown}
                     onMouseLeave={() => {
                        setDropDownMenu(false)
                     }}
                  >
                     <ul>
                        <li className={classes.authProfile}>
                           <img src={userImage} alt='userImage' />
                           <span>{localStorage.getItem('authName')}</span>
                        </li>

                        {locationState.pathname === '/profile' ? null : (
                           <>
                              <li onClick={() => navigate('/profile', { state: 'profile' })}>
                                 <span>Настройки профиля</span>
                              </li>
                              <li onClick={() => navigate('/profile', { state: 'pending_orders' })}>
                                 <span>Текущие заказы</span>
                              </li>
                              <li onClick={() => navigate('/profile', { state: 'all_orders' })}>
                                 <span>История заказов</span>
                              </li>
                           </>
                        )}

                        <li onClick={() => logout()}>
                           <span onClick={() => logout()}>Выйти</span>
                        </li>
                     </ul>
                  </div>
               ) : null}
            </>
         ) : (
            children
         )}
      </div>
   )
}

export default AuthButton
