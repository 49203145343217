import { useEffect, useState } from 'react'
import Visa from '../../../../../accests/image/g13_visa.svg'
import Rubles from '../../../../../accests/image/ruble.svg'
import USDT from '../../../../../accests/image/USDT 1_dollar.svg'

import classes from './FormPayment.module.scss'

const PaymentMethod = ({ paymentMeth, setInput, data }) => {
   const [activeMethod, setActiveMethod] = useState(null)
   const paymentMethodsArr = [
      ['USDT', USDT],
      ['Наличные', Rubles],
      ['Банковской картой', Visa],
   ]

   function setUserData(inputName, inputValue) {
      setInput(inputName, inputValue)
      setActiveMethod(inputValue)
   }

   useEffect(() => {
      paymentMeth(activeMethod)
   }, [activeMethod, data.payment_type])
   return (
      <div
         className={
            data.payment_type === 'error' ? classes.paymentMethodsError : classes.paymentMethods
         }
      >
         <h3>Способы оплаты</h3>
         <div className={classes.paymentMethodsCheck}>
            {paymentMethodsArr.map((element, index) => {
               return (
                  <label
                     key={index}
                     className={
                        activeMethod === index ? classes.active : classes.paymentMethodsCheckBlock
                     }
                     onChange={() => setUserData('payment_type', index)}
                  >
                     <img src={element[1]} alt={element[0]} />
                     <p>{element[0]}</p>
                     <input type='checkbox' checked={index === activeMethod ? true : false} />
                  </label>
               )
            })}
         </div>
      </div>
   )
}

export default PaymentMethod
