import classes from './SelectFilters.module.css'
import Arrow from '../../../../accests/image/beforeArrowNext.svg'

const SelectFilters = ({ name, isOpen, setIsOpen, options, activeOptions, setActiveOptions }) => {
   const checkIsShowId = (id) => {
      if (!!activeOptions.filter((item) => item === id).length) {
         setActiveOptions(activeOptions.filter((item) => item !== id))
      } else {
         setActiveOptions([...activeOptions, id])
      }
   }

   return (
      <div className={classes.wrapper}>
         <div className={classes.title} onClick={() => setIsOpen(!isOpen)}>
            <p>{name}</p>
            <img
               src={Arrow}
               alt='arrow'
               className={isOpen ? classes.isOpenImg : classes.rotateImg}
            />
         </div>
         {isOpen && (
            <div className={classes.optionsWrapper}>
               {options?.map((item) => (
                  <div
                     key={item.id}
                     className={classes.option}
                     onClick={() => checkIsShowId(item.id)}
                  >
                     <div className={classes.activeNameOption}>
                        <div
                           className={`${classes.checkBox} ${
                              !!activeOptions.filter((i) => i === item.id).length
                                 ? classes.activeCheckBox
                                 : ''
                           }`}
                        />
                        <div className={classes.text}>{item.value}</div>
                     </div>
                     <div className={classes.count}>{item.count}</div>
                  </div>
               ))}
            </div>
         )}
      </div>
   )
}

export default SelectFilters
