import classes from './MiningManagement.module.scss'
import { useEffect, useState, useRef } from 'react'

import mobileBaner from '../../../../accests/image/phoneCallbackBaner.png'
import regularBaner from '../../../../accests/image/callbackBaner.png'
import SidePopup from '../../modalsUtility/SidePopup'
import ImgSucc from '../../../../accests/image/success.svg'
import Cross from '../../../../accests/image/crossIcon.svg'
import { postSendFeedback } from '../../../hooks/usePostSendFeedback'
import { phoneFormating } from '../../comFn'

const MiningManagement = () => {
   const inputRef = useRef()
   const [popup, setPopup] = useState(false)
   const [state, setState] = useState({
      name: '',
      phone: '',
   })

   useEffect(() => {
      inputRef.current.addEventListener('keydown', (event) => {
         if (event.keyCode === 8 || event.keyCode === 46) {
            setState({ ...state, phone: '' })
         }
      })
   }, [state])

   useEffect(() => {
      return () => setTimeout(() => setPopup(false), 3000)
   }, [popup])
   const [textModal, setTextModal] = useState('')

   const sendData = () => {
      if (!state?.name && !state?.phone) {
         setTextModal('Введите ваше имя и номер телефона!')
      } else if (!state?.name) {
         setTextModal('Введите ваше имя!')
      } else if (!state?.phone) {
         setTextModal('Введите ваш номер телефона!')
      } else if (state?.phone.length < 15) {
         setTextModal('Номер телефона слишком короткий!')
      } else {
         postSendFeedback({ state, setTextModal, setPopup, setState })
      }
      setPopup(true)
   }
   return (
      <div className={classes.callbackMining}>
         <div className={classes.callbackMiningContent}>
            <div className={classes.callbackInfoBaner}>
               <img src={regularBaner} alt='baner' />
            </div>
            <form
               action='submin'
               className={classes.callbackForm}
               onSubmit={(e) => e.preventDefault()}
            >
               <div>
                  <h4>Заказать обратный звонок</h4>
                  <p>
                     Нажимая на кнопку, я соглашаюсь на обработку{' '}
                     <a href='/rights'>персональных данных</a>
                  </p>
               </div>
               <input
                  type='text'
                  placeholder='Ваше имя'
                  value={state.name}
                  onChange={(e) => {
                     setState({ ...state, name: e.target.value })
                  }}
               />

               <input
                  ref={inputRef}
                  type='text'
                  placeholder='Ваш телефон'
                  maxLength={15}
                  value={state.phone}
                  onChange={(e) => {
                     setState({ ...state, phone: phoneFormating(e.target.value) })
                  }}
               />
               <button onClick={() => sendData()}>Отправить</button>
            </form>
            <div className={classes.callbackInfoBanerPhone}>
               <img src={mobileBaner} alt='baner' />
            </div>
         </div>
         {popup ? (
            <SidePopup dataState={true}>
               <span
                  className={
                     !state.name || !state.phone || state.phone.length < 10
                        ? classes.crossWrapper
                        : ''
                  }
               >
                  <img
                     src={!state.name || !state.phone || state.phone.length < 10 ? Cross : ImgSucc}
                     alt={'success'}
                  />
               </span>
               <span></span>
               <span>
                  <p>{textModal}</p>
               </span>
            </SidePopup>
         ) : null}
      </div>
   )
}

export default MiningManagement
