import { useEffect, useState } from 'react'
import axios from 'axios'

export const useGetCurses = () => {
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(true)

   useEffect(() => {
      const fetchData = async () => {
         try {
            const response = await axios.get(
               // 'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin,the-open-network&vs_currencies=usd&include_24hr_change=true',
               'https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,binancecoin,the-open-network,kaspa,litecoin&vs_currencies=usd&include_24hr_change=true',
            )
            setData(response.data)
            localStorage.setItem('courses', JSON.stringify(response.data))
            localStorage.setItem('timeCourses', new Date().getTime())
         } catch (error) {
            console.error('Произошла ошибка:', error)
         } finally {
            setIsLoading(false)
         }
      }

      fetchData()
   }, [])

   return { data, isLoading }
}
