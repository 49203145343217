import Regular from './userDataItemsForm/RegularUserForm'
import Commercial from './userDataItemsForm/CommercialUserForm'
import classes from './UserDataForm.module.scss'

const UserDataForm = ({ children}) => {
   return (
      <div className={classes.usersDataMain}>
         <h3>Данные покупателя</h3>
         {children}
      </div>
   )
}

export default UserDataForm
