import CatalogWrapper from '../../common/catalogWrapper/CatalogWrapper'
import CompanyLogo from '../../common/companyLogo/CompanyLogo'
import InvestionBlock from '../../common/investionBlock/InvestionBlock'
import DiscountBlock from '../../../components/common/discountBlock/DiscountBlock'
import Advantages from '../../common/advantages/Advantages'
import { text } from '../../../translate'
import AboutInfo from '../../common/aboutInfo/AboutInfo'
import PartnerLogo from '../../common/partnerLogo/PartnerLogo'
import NewsFP from '../../common/newsFP/Newsfp'
import { useGetAsics } from '../../hooks/useGetAsics'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import classes from './MainPage.module.scss'
import Courses from '../../common/header/courses/Courses'

export const MainPage = () => {
   const { data: profitData, isLoading: profitLoader } = useGetAsics('/marketing/most_profitable')
   const { data: newItemsData, isLoading: newLoader } = useGetAsics('/marketing/new_products')
   const { data: saleData, isLoading: saleLoader } = useGetAsics('/marketing/sale_items')
   const { data: hitData, isLoading: hitLoader } = useGetAsics('/marketing/bestsellers')
   const { data: discountItem, isLoading: discountLoader } = useGetAsics('/marketing/featured_asic')

   const lang = 'ru'
   return (
      <div className={classes.mobileHidden}>
         {profitLoader && newLoader && saleLoader && hitLoader && discountLoader ? (
            <PageSpinner />
         ) : (
            <>
               <InvestionBlock />
               <CompanyLogo />
               <div className={classes.containerCourses}>
                  {/* <div className='container'> */}
                  <div className={classes.coursesWrapper}>
                     <Courses />
                  </div>
               </div>
               {!!hitData.length && (
                  <CatalogWrapper
                     name={text[lang].mainPage.hitPayment}
                     link='/asic-miners'
                     items={hitData}
                     isLoading={hitLoader}
                  />
               )}

               {!!discountItem && <DiscountBlock discountItem={discountItem} />}
               {!!newItemsData.length && (
                  <CatalogWrapper
                     name={text[lang].mainPage.newItems}
                     link='/asic-miners'
                     items={newItemsData}
                     isLoading={newLoader}
                  />
               )}

               <Advantages />
               {!!profitData.length && (
                  <CatalogWrapper
                     name={text[lang].mainPage.topItems}
                     link='/asic-miners'
                     items={profitData}
                     isLoading={profitLoader}
                  />
               )}

               <AboutInfo />
               {!!saleData.length && (
                  <CatalogWrapper
                     name={text[lang].mainPage.sale}
                     link='/asic-miners'
                     items={saleData}
                     isLoading={saleLoader}
                  />
               )}

               <NewsFP />
               <PartnerLogo />
            </>
         )}
      </div>
   )
}
