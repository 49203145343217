import NewCard from '../../common/news/NewCard'
import PageSpinner from '../../common/pageSpinner/PageSpinner'
import axios from 'axios'
import { baseUrl } from '../../config/config'

import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

const NewCardPage = () => {
   const redirect = useNavigate()
   const searchState = useParams()
   const [pageId] = useState(...Object.values(searchState))
   const [postData, setPostData] = useState(null)
   useEffect(() => {
      axios
         .get(`${baseUrl}/post/${pageId.split('-').at(-1)}`)
         .then((response) => {
            let data = response.data
            setPostData(data)
         })
         .catch((error) => {
            redirect('/Error404')
            console.error('Произошла ошибка:', error)
         })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])
   return <>{postData !== null ? <NewCard data={postData} /> : <PageSpinner />}</>
}

export default NewCardPage
