import classes from './SuccessPopup.module.scss'
import sucImg from '../../../accests/image/succes_239.svg'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'

import { BucketContext } from '../../../App'

const SuccessPopup = () => {
   const navigate = useNavigate()
   const context = useContext(BucketContext)

   function scrollUp() {
      window.scroll(0, document.getElementById('newsPage'))
   }
   function closeAndRedirectProfile() {
      localStorage.setItem('bucket', '{}')
      localStorage.setItem('amountItems', 0)
      context.dispatch({ type: 'clear_amount_items', payload: null })
      scrollUp()
      navigate('/profile', { state: 'pending_orders' })
   }
   function closeAndRedirectAsic() {
      localStorage.setItem('bucket', '{}')
      localStorage.setItem('amountItems', 0)
      context.dispatch({ type: 'clear_amount_items', payload: null })
      scrollUp()
      navigate('/asic-miners')
   }
   return (
      <div className={classes.sucBack}>
         <div className={classes.sucModal}>
            <img src={sucImg} alt='succes' />
            <span>
               <h4>Спасибо за заказ</h4>
               <p>Наши менеджеры свяжутся с вами в ближайшее время</p>
            </span>

            <span>
               <button className={classes.btnCom} onClick={() => closeAndRedirectProfile()}>
                  Посмотреть заказ
               </button>
               <button className={classes.shopping} onClick={() => closeAndRedirectAsic()}>
                  Продолжить покупки
               </button>
            </span>
         </div>
      </div>
   )
}
export default SuccessPopup
