import { useEffect, useState } from 'react'
import axios from 'axios'
import { baseUrl } from '../config/config'

export const useGetAsics = (url = '/asics/') => {
   const [data, setData] = useState([])
   const [isLoading, setIsLoading] = useState(true)
   useEffect(() => {
      axios
         .get(`${baseUrl}${url}`)
         .then((response) => {
            const data = response.data
            setData(data)
            setIsLoading(false)
         })
         .catch((error) => {
            console.error('Произошла ошибка:', error)
            setIsLoading(false)
         })
   }, [url])
   return { data, isLoading }
}
