import axios from 'axios'
import requared from '../../common/axiosIntercepter'
import { baseUrl } from '../../config/config'

function setLocalStorage() {
   if (localStorage.getItem('authName') === null) {
      localStorage.setItem('authName', '')
   }
}
setLocalStorage()

function returnArray() {
   let updatedData = []
   requared
      .get(baseUrl + '/favorite')
      .then((response) => {
         updatedData = response.data
      })
      .catch((error) => {
         console.error('Произошла ошибка:', error.status, error.statusText)
      })
   return updatedData
}

export default function reducer(state, action) {
   switch (action.type) {
      case 'popup_toggle':
         return { ...state, activePopup: action.payload }
      case 'popup_activeMethod':
         return { ...state, method: action.payload }
      case 'popup_mainWindow':
         return { ...state, mainWindow: action.payload }
      case 'popup_close':
         return {
            ...state,
            activePopup: false,
         }
      case 'rerender':
         return {
            ...state,
            favorite: action.payload,
         }
      case 'renew':
         return {
            ...state,
            requestRerender: !state.requestRerender,
         }
      case 'regular':
         return {
            ...state,
            callbackState: 'regular',
         }
      case 'mining_manager':
         return { ...state, callbackState: action.payload }
      case 'email_set':
         return { ...state, email: action.payload }
      case 'login':
         let data = ''
         requared
            .get(baseUrl + '/auth/me', {
               headers: {
                  Accept: 'application/json',
                  Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
               },
            })
            .then((response) => {
               data = response.data.first_name
            })
            .catch((error) => {
               console.error('Произошла ошибка:', error.status)
            })
         return { ...state, name: data }
      case 'logout':
         localStorage.setItem('auth_token', '')
         localStorage.setItem('authName', '')
         return { ...state, name: '' }
      case 'init_favorite':
         return { ...state, favorite: action.payload }
      default:
         return console.log('error in profile reducer')
   }
}
