import classes from './CatalogMenu.module.scss'
import { useState, useContext } from 'react'
import CatalogListItem from './CatalogListItem/CatalogListItem'
import CatalogIcon from '../../../accests/image/catalogIcon.svg'
import { text } from '../../../translate'
import { useNavigate } from 'react-router-dom'

// import LinkIcon from '../../../accests/image/linkIcon.svg'

import { ProfileContext } from '../../../App'
import AuthButton from './CatalogListItem/AuthButton'
import { useGetMarketInfo } from '../../hooks/useGetMarketInfo'

const lang = 'ru'

const CatalogMenu = () => {
   const context = useContext(ProfileContext)
   const checkMainRoute = window.location.pathname
   const [isOpenMenu, setIsOpenMenu] = useState(false)

   // const [openSelect, setOpenSelect] = useState(false)

   const navigate = useNavigate()

   const { data } = useGetMarketInfo()
   const brands = data?.brands

   const catalogListNameMenu = [
      {
         name: brands?.filter((item) => item.value === 'Whatsminer')[0].value,
         link: `/asic-miners?company=${
            brands?.filter((item) => item.value === 'Whatsminer')[0].id
         }`,
      },
      {
         name: brands?.filter((item) => item.value === 'Bitmain')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'Bitmain')[0].id}`,
      },
      {
         name: brands?.filter((item) => item.value === 'ElphaPex')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'ElphaPex')[0].id}`,
      },
      {
         name: brands?.filter((item) => item.value === 'Iceriver')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'Iceriver')[0].id}`,
      },
      {
         name: brands?.filter((item) => item.value === 'Goldshell')[0].value,
         link: `/asic-miners?company=${brands?.filter((item) => item.value === 'Iceriver')[0].id}`,
      },

      {
         name: 'Посмотреть все',
         link: '/asic-miners',
      },
   ]

   return (
      <div className={classes.wrapper}>
         <div className={classes.container + ' container'}>
            <div
               className={classes.catalog}
               onClick={checkMainRoute === '/' ? () => {} : () => setIsOpenMenu(!isOpenMenu)}
            >
               <img src={CatalogIcon} alt='catalog' className={classes.catalogImg} />
               {text[lang].header.catalog.title}
               {(isOpenMenu || checkMainRoute === '/') && (
                  <div className={classes.catalogList} onClick={(e) => e.stopPropagation()}>
                     <div className={classes.catalogListItemWrapper}>
                        {catalogListNameMenu.map((item) => (
                           <CatalogListItem
                              closedModal={() => setIsOpenMenu(false)}
                              link={item.link}
                              name={item.name}
                              key={item.name}
                           />
                        ))}
                     </div>
                  </div>
               )}
            </div>

            <ul className={classes.menuLink}>
               {/* <li
                  className={classes.menuLinkItem}
                  onClick={() => {
                     navigate('/')
                     setIsOpenMenu(false)
                     window.location.reload()
                  }}
               >
                  {text[lang].header.menu.payment}
               </li> */}
               <a
                  href='tg://resolve?domain=@ImineShopBot'
                  className={classes.menuLinkItem}
                  target='_blank'
                  rel='noreferrer'
               >
                  {text[lang].header.catalog.calculatePayment}
               </a>
               <li
                  className={classes.menuLinkItem}
                  onClick={() => {
                     navigate('/data-centers')
                     setIsOpenMenu(false)
                     window.location.reload()
                  }}
               >
                  {text[lang].header.catalog.dataCenter}
               </li>
               {/* //123 */}
               <li
                  className={classes.menuLinkItem}
                  onClick={() => {
                     navigate('/mining-management')
                     setIsOpenMenu(false)
                     window.location.reload()
                  }}
               >
                  {text[lang].header.catalog.miningForKey}
               </li>
               {/* //123123 */}
               {/* <li
                  className={classes.menuLinkItem}
                  onClick={() => {
                     navigate('/news')
                     setIsOpenMenu(false)
                     window.location.reload()
                  }}
               >
                  Блог
               </li>
               <li
                  className={classes.menuLinkItem}
                  onClick={() => {
                     setOpenSelect(!openSelect)
                  }}
               >
                  {text[lang].header.menu.checkGaranties}
                  <div className={`${classes.test} ${openSelect ? classes.active : ''}`}>
                     <svg
                        width='7'
                        height='10'
                        viewBox='0 0 7 10'
                        xmlns='http://www.w3.org/2000/svg'
                     >
                        <path d='M6.41665 5.37052L1.6685 9.34547C1.27419 9.67557 0.599976 9.44178 0.599976 8.97495L0.599975 1.02505C0.599975 0.558216 1.27419 0.324425 1.6685 0.654526L6.41665 4.62948C6.66108 4.83411 6.66109 5.16589 6.41665 5.37052Z' />
                     </svg>
                  </div>

                  {openSelect && (
                     <div className={classes.menuLinkOptionsWrapper}>
                        <a
                           href='https://m.bitmain.com/support/warranty'
                           rel='noreferrer'
                           target='_blank'
                           className={classes.menuLinkOption}
                           onClick={() => setOpenSelect(false)}
                        >
                           <p>Antiminer</p>
                           <div className={classes.linkIcon}>
                              <img src={LinkIcon} alt='linkIcon' />
                           </div>
                        </a>

                        <a
                           href='https://xn--whatsmner-l5a.com/support/'
                           rel='noreferrer'
                           target='_blank'
                           className={classes.menuLinkOption}
                           onClick={() => setOpenSelect(false)}
                        >
                           <p>Whatsminer</p>
                           <div className={classes.linkIcon}>
                              <img src={LinkIcon} alt='linkIcon' />
                           </div>
                        </a>
                     </div>
                  )}
                  {openSelect && (
                     <div className={classes.closeSelect} onClick={() => setOpenSelect(false)} />
                  )}
               </li> */}
            </ul>
            <AuthButton>
               <span
                  onClick={() => {
                     context.storeDispatch({ type: 'popup_toggle', payload: true })
                  }}
               >
                  {localStorage.getItem('authName') === '' ? text[lang].header.logIn : 'Профиль'}
               </span>
            </AuthButton>
         </div>
      </div>
   )
}

export default CatalogMenu
