export const text = {
   en: {},
   ru: {
      header: {
         courses: {
            text1: 'Bitcoin халвинг:',
         },
         category: {
            comparisons: 'Сравнения',
            favorites: 'Избранное',
            basket: 'Корзина',
            whatsapp: 'WhatsApp',
            tg: 'Telegram',
            call: 'Заказать звонок',
         },
         catalog: {
            title: 'Каталог ASIC майнеров',
            asicMiners: 'Asic-майнеры',
            dataCenter: 'Дата-центры',
            miningForKey: 'Майнинг под ключ',
            miningNews: 'Новости майнинга',
            calculatePayment: 'Калькулятор доходности',
         },
         menu: {
            payment: 'Оплата и доставка',
            about: 'О компании',
            contacts: 'Контакты',
            help: 'Помощь',
            news: 'Новости',
            checkGaranties: 'Проверить гарантию',
         },
         logIn: 'Войти',
         searchInpytPlaceholder: 'Поиск',
      },
      mainPage: {
         hitPayment: 'Хиты продаж',
         allItems: 'Все товары',
         inBasket: 'В корзину',
         discount: 'Скидка',
         newItems: 'Новинки',
         advantages: 'Преимущества',
         topItems: 'Самые прибыльные',
         sale: 'Распродажа',
         partners: 'Наши партнёры',
         description: {
            text1: 'Большое количество оборудования всегда в наличии',
            text2: 'Гарантия лучших условий сделки',
            text3: 'Открытый чат с клиентами',
            text4: 'Компенсация убытков за время ремонта оборудования',
         },
         catalogItem: {
            hashRate: 'Хешрейт',
            algorytm: 'Алгоритм',
            coins: 'Монеты',
            isNew: 'Новинка',
            hit: 'Хит',
            is_in_stock: 'В наличии',
         },
         aboutInfo: {
            text1: 'ASIC-майнеров продано с момента основании компании',
            text2: 'клиентов за время существования компании',
            text3: 'лет на рынке продажи оборудования для майнинга',
         },
      },
      profile: {
         headerName: 'Профиль',
         profileSwitcher: {
            profileSettings: 'Настройки профиля',
            currentOrders: 'Текущие заказы',
            orderHistory: 'История заказов',
         },
         profileData: {
            name: 'Имя',
            sndName: 'Фамилия',
            thrdName: 'Отчество',
            eMail: 'E-mail',
            contactPhone: 'Номер телефона',
         },
      },
      asicMiners: {
         title: 'Asic-майнеры',
         sorting: {
            sortBTC: 'ASIC для майнинга Bitcoin',
            sortLTC: 'ASIC для майнинга LTC / DOGE',
            sortPower: 'Мощные асики',
            sortFast: 'Быстрая окупаемость',
            sortMoney: 'Прибыльные асики',
            sortComment: 'По отзывам',
         },
         filters: {
            title: 'Фильтры',
            toPrice: 'Цены до',
            fromPrice: 'Цены от',
            hashRate: 'Хэшрейт',
            brands: 'Бренды',
            ranked: 'Рейтинг по отзывам',
            special: 'Специальные предложения',
            algorytm: 'Алгоритм',
            usePower: 'Потребление, Вт/ч',
            maningCoin: 'Добываемые монеты',
            discount: 'Скидка',
            popylar: 'Самый прибыльный',
            power: 'Самый мощный',
            have: 'Есть в наличии',
            tags: 'Теги',
            sortOrder: {
               popularity: 'По популярности',
               mostExpensive: 'Сначала дорогие',
               cheapiest: 'Сначала дешёвые',
               biggestDiscount: 'По убыванию скидки',
               smalliestDiscount: 'По увеличению скидки',
            },
         },
      },
   },
}
