import classes from './FilterItem.module.scss'

const FilterItem = ({ active, name, setActive, art }) => {
   return (
      <div
         className={`${classes.wrapper} ${active && classes.active}`}
         onClick={() => {
            active ? setActive('') : setActive(art)
         }}
      >
         {name}
      </div>
   )
}

export default FilterItem
