import { useEffect, useRef, useState } from 'react'
import classes from './ModalFeedback.module.scss'
import AcceptIcon from '../../../../accests/image/acceptWhiteIcon.svg'
import ArrowSelect from '../../../../accests/image/arrowBottomSelect.svg'
import { useGetAsics } from '../../../hooks/useGetAsics'
import { postSendFeedback } from '../../../hooks/usePostSendFeedback'
import SidePopup from '../../../common/modalsUtility/SidePopup'
import ImgSucc from '../../../../accests/image/success.svg'
import Cross from '../../../../accests/image/crossIcon.svg'
import { phoneFormating } from '../../../common/comFn'
import CyrillicToTranslit from 'cyrillic-to-translit-js'

const ModalFeedback = () => {
   const optionsArr = [
      { name: 'Телефон', value: 'phone' },
      { name: 'Telegram', value: 'telegram' },
      { name: 'WhatsApp', value: 'whatsapp' },
   ]

   const [open, setOpen] = useState(false)
   const [activeOption, setActiveOption] = useState(['phone'])
   const { data } = useGetAsics()
   const [phone, setPhone] = useState({ phone: '', messenger: 'phone' })
   const [tg, setTg] = useState({ phone: '', messenger: 'telegram' })
   const [whatsApp, setWhatsApp] = useState({ phone: '', messenger: 'whatsapp' })

   const [checkPhones, setCheckPhones] = useState([])

   const [state, setState] = useState({
      name: '',
      phones: checkPhones,
      item: '',
      item_count: 1,
   })

   useEffect(() => {
      const updatedCheckPhones = [phone, tg, whatsApp].filter((item) =>
         activeOption.includes(item.messenger),
      )
      setCheckPhones(updatedCheckPhones)
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [activeOption, phone, tg, whatsApp])

   useEffect(() => {
      setState({ ...state, phones: checkPhones })
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [checkPhones])

   const checkNumbers = (str) => {
      if (!!activeOption?.filter((item) => item === str).length) {
         setActiveOption(activeOption?.filter((tr) => tr !== str))
      } else {
         setActiveOption([...activeOption, str])
      }
   }

   const inputRef = useRef()

   const [popup, setPopup] = useState(false)

   useEffect(() => {
      return () =>
         setTimeout(() => {
            setPopup(false)
            setTextModal('')
         }, 2100)
   }, [popup])
   const [textModal, setTextModal] = useState('')

   // useEffect(() => {
   //    inputRef.current.addEventListener('keydown', (event) => {
   //       if (event.keyCode === 8 || event.keyCode === 46) {
   //          setState({ ...state, phone: '' })
   //       }
   //    })
   // }, [state])

   const sendData = () => {
      if (!state?.name && !state?.phone && !state?.item) {
         setTextModal('Введите все обязательные поля* !')
      } else if (!activeOption.length) {
         setTextModal('Выберите хотя бы один способ связи!')
      } else if (!state?.name && !state?.item) {
         setTextModal('Введите ваше имя и выберите Asic-miner!')
      } else if (!state?.name) {
         setTextModal('Введите ваше имя!')
      } else if (!state?.item) {
         setTextModal('Выберите Asic-miner!')
      } else if (
         !!activeOption.filter((item) => item === 'phone').length &&
         phone.phone.length < 18
      ) {
         setTextModal('Номер телефона слишком короткий!')
      } else if (
         !!activeOption.filter((item) => item === 'telegram').length &&
         tg.phone.length < 3
      ) {
         setTextModal('Username слишком короткий!')
      } else if (
         !!activeOption.filter((item) => item === 'whatsapp').length &&
         whatsApp.phone.length < 18
      ) {
         setTextModal('Номер WhatsApp слишком короткий!')
      } else {
         postSendFeedback({
            state,
            setTextModal,
            setPopup,
            setState,
            setWhatsApp,
            setTg,
            setPhone,
         })
      }
      setPopup(true)
   }

   const cyrillicToTranslit = new CyrillicToTranslit()

   const filteredAsics = !!state.item.length
      ? data
           .filter((item) => {
              const translitTitle = cyrillicToTranslit.transform(item?.title?.toLowerCase())
              const translitStateItem = cyrillicToTranslit.transform(state.item?.toLowerCase())

              return translitTitle.includes(translitStateItem)
           })
           .filter((check) => check.title !== state.item)
      : data

   return (
      <div className={classes.wrapper}>
         <div className={classes.title}>Заявка на размещение</div>
         <div className={classes.label}>Ваше имя*</div>
         <input
            className={classes.input}
            placeholder={'Введите ваше имя'}
            value={state.name}
            onChange={(e) => {
               setState({ ...state, name: e.target.value })
            }}
         ></input>
         <div className={classes.selectWrapper}>
            <div>
               <div className={classes.label}>Оборудование для размещения*</div>
               {/* <input className={classes.input}></input> */}
               <div className={classes.posA}>
                  <input
                     type='text'
                     value={state.item}
                     className={`${classes.inputSearch} ${classes.select}`}
                     onChange={(e) => setState({ ...state, item: e.target.value })}
                     onClick={() => setOpen(!open)}
                  />
                  <img
                     src={ArrowSelect}
                     alt='arrow'
                     className={`${classes.crossImg} ${open && classes.rotateImg}`}
                  />
                  {open && (
                     <>
                        <div className={classes.optionsWrapper}>
                           {filteredAsics.map((option) => (
                              <div
                                 key={option.id}
                                 className={classes.optionSelect}
                                 onClick={(e) => {
                                    e.stopPropagation()
                                    // setFilterValue(option.title)
                                    setState({ ...state, item: option.title })
                                    setOpen(false)
                                 }}
                              >
                                 {option.title}
                              </div>
                           ))}
                        </div>
                        <div
                           className={classes.closeFilter}
                           onClick={() => {
                              setState({ ...state, item: '' })
                              setOpen(false)
                           }}
                        />
                     </>
                  )}
               </div>
            </div>
            <div>
               <div className={classes.label}>Количество*</div>
               <input
                  type='number'
                  className={classes.input}
                  value={state.item_count}
                  onChange={(e) =>
                     setState({ ...state, item_count: +e.target.value < 1 ? 1 : +e.target.value })
                  }
               ></input>
            </div>
         </div>
         <div className={classes.optionWraper}>
            {optionsArr.map((item) => (
               <div
                  className={classes.option}
                  key={item.value}
                  onClick={() => checkNumbers(item.value)}
               >
                  <div
                     className={`${classes.optionCheckBox} ${
                        activeOption.includes(item.value) ? classes.optionActiveCheckBox : ''
                     }`}
                  >
                     {activeOption.includes(item.value) && <img src={AcceptIcon} alt='accept' />}
                  </div>
                  <div className={classes.optionText}>{item.name}</div>
               </div>
            ))}
         </div>
         {activeOption.includes('phone') && (
            <>
               <div className={classes.label}>Номер телефона*</div>
               <input
                  className={classes.input}
                  ref={inputRef}
                  placeholder='Ваш телефон'
                  maxLength={18}
                  type={'number'}
                  value={phone.phone}
                  onChange={(e) => {
                     setPhone({ ...phone, phone: phoneFormating(e.target.value) })
                  }}
               />
            </>
         )}

         {activeOption.includes('telegram') && (
            <>
               <div className={classes.label}>Телеграм Username (начиначется с @)*</div>
               <input
                  className={classes.input}
                  placeholder='Ваш телеграм @Username'
                  value={tg.phone}
                  onChange={(e) => {
                     setTg({ ...tg, phone: e.target.value })
                  }}
               />
            </>
         )}
         {activeOption.includes('whatsapp') && (
            <>
               <div className={classes.label}>Номер WhatsApp*</div>
               <input
                  className={classes.input}
                  ref={inputRef}
                  placeholder='Ваш номер WhatsApp'
                  maxLength={18}
                  type={'number'}
                  value={whatsApp.phone}
                  onChange={(e) => {
                     setWhatsApp({ ...whatsApp, phone: phoneFormating(e.target.value) })
                  }}
               />
            </>
         )}

         <div className={classes.callbackForm}>
            <button onClick={() => sendData()}>Получить консультацию</button>
            <p>
               Нажимая на кнопку, вы соглашаетесь <br /> с{' '}
               {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
               <a href='/rights'>политикой конфиденциальности</a>
            </p>
         </div>
         {popup ? (
            <SidePopup dataState={true}>
               <span
                  className={
                     !state.name ||
                     !state.item ||
                     !activeOption.length ||
                     (!!activeOption.filter((item) => item === 'phone').length &&
                        whatsApp.phone.length < 18) ||
                     (!!activeOption.filter((item) => item === 'telegram').length &&
                        whatsApp.phone.length < 2) ||
                     (!!activeOption.filter((item) => item === 'whatsapp').length &&
                        whatsApp.phone.length < 18)
                        ? classes.crossWrapper
                        : ''
                  }
               >
                  <img
                     src={
                        !state.name ||
                        !state.item ||
                        !activeOption.length ||
                        (!!activeOption.filter((item) => item === 'phone').length &&
                           whatsApp.phone.length < 18) ||
                        (!!activeOption.filter((item) => item === 'telegram').length &&
                           whatsApp.phone.length < 2) ||
                        (!!activeOption.filter((item) => item === 'whatsapp').length &&
                           whatsApp.phone.length < 18)
                           ? Cross
                           : ImgSucc
                     }
                     alt={'success'}
                  />
               </span>
               <span></span>
               <span>
                  <p>{textModal}</p>
               </span>
            </SidePopup>
         ) : null}
      </div>
   )
}

export default ModalFeedback
